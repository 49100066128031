import React from 'react'
import {
	Modal,
	Form,
	Input,
	Switch,
	Button, TreeSelect,
} from 'antd'
import {get_query} from "../../functions";


const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

const initState = {
	fio: '',
	login: '',
	email: '',
	password: '',
	is_admin: false,
	items:[],
	name: '',
	treeData: []
} 

// user dlg
export default class ActCounter extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			fio: '',
			login: '',
			email: '',
			password: '',
			is_admin: false,
			items: [],
			name: '',
			treeData: []
		}
	}

	UNSAFE_componentWillReceiveProps(np){
		if(this.props.open !== np.open && np.open){
			if(np.row){
				this.setState(Object.assign({}, this.state, np.row))
			} else {
				this.setState(initState)
			}
		}
	}

	componentDidMount() {
		this.getData()
	}

	onChange = (newValue) => {
		this.setState({items:newValue});
	};

	getData = async () => {
		let mines = await get_query('/mine')
		let horizons = await get_query('/horizon')
		let acs = await get_query('/ac')
		let treeData_tmp = []
		mines.data.map(mine=>{
			let mine_horizons = horizons.data.filter(x => x.mine_id == mine.id)
			let mine_tmp = {
				title: mine.name,
				value: mine.id,
				key: mine.id,
				checkable: false,
				children: []
			}
			mine_horizons.map(horizon=> {
					let horizon_acs = acs.data.filter(x => x.horizon_id == horizon.id)
					let horizon_tmp = {
						title: horizon.name,
						value: -horizon.id,
						key: -horizon.id,
						children: []
					}
					horizon_acs.map(y=>{
						y.title=y.identifier;
						y.value=y.id;
						y.key=y.id
						horizon_tmp.children.push(y)
					})
					mine_tmp.children.push(horizon_tmp)
				}
			)
			treeData_tmp.push(mine_tmp)
		})
		this.setState({treeData:treeData_tmp})
	}
	render(){
		return(
			<Modal
				title={<b>{this.props.title}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={[
					<Button 
						key="back" 
						onClick={() => this.props.close()}
					>
						Отменить
					</Button>,
					<Button 
						key="submit" 
						type="primary" 
						loading={this.props.save_loader} 
						onClick={() => this.props.save(this.state)}
					>
						Сохранить
					</Button>
				]}
				width={440}
			>
				<div className='modal-body'>
					<Form
						{...layout}
					>
						<Form.Item
							label="Наименование счетчика"
						>
							<Input
								type='text'
								value={this.state.name}
								onChange={(ev) => this.setState({ name: ev.target.value })}
								placeholder='Наименование счетчика'
							/>
						</Form.Item>
						<Form.Item
							label="Точки"
						>
							<TreeSelect
								treeData= {this.state.treeData}
								value={this.state.items}
								onChange={this.onChange}
								treeCheckable={true}
								showCheckedStrategy= 'SHOW_PARENT'
								placeholder = 'Выберите точки'
								style={{width:'100%'}}
							/>
						</Form.Item>
					</Form>	
				</div>
			</Modal>
		)
	}
}


