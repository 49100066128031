import React from 'react'
import { 
	withRouter, 
	Switch, 
	Route 
} from 'react-router-dom'
import PrivateRoute from '../../containers/routes/PrivateRoute'
import PrivateAdminRoute from "../../containers/routes/PrivateAdminRoute";
import Login from './../single/Login'
import Configurator from './../configurator/Configurator'
import Scheme from './../scheme/Scheme'
import Notice from './../notice/Notice'
import Reports from "../reports/Reports";
import Settings from './../settings/Settings'
import Exception from './../single/Exception'
import InfoPage from './../info/info'
import { sso_query } from './../../functions'
import LogPage from "../log/log";
import Dash from "../dash/Dash";
import {setIsAdmin, setUser} from "../../actions/user_act";


// Routes component 
class Routes extends React.Component {

	componentDidMount(){
		sso_query('/session/check')
		.then((res) => {
			setIsAdmin(res.user.is_admin)
			setUser(res.user)
		})
		.catch((err) => [
		console.log(err)
		])
	}

	render(){
		return(
			<Switch>
				<PrivateRoute exact path='/scheme'>
					<Scheme />
				</PrivateRoute>
				<PrivateRoute exact path='/'>
					<Dash />
				</PrivateRoute>
				<PrivateAdminRoute exact path='/configurator'>
					<Configurator />
				</PrivateAdminRoute>
				<PrivateRoute exact path='/reports'>
					<Reports />
				</PrivateRoute>
				<PrivateRoute exact path='/notice'>
					<Notice />
				</PrivateRoute>
				<PrivateAdminRoute exact path='/settings'>
					<Settings />
				</PrivateAdminRoute>
				<PrivateAdminRoute exact path='/info'>
					<InfoPage />
				</PrivateAdminRoute>
				<PrivateAdminRoute exact path='/log'>
					<LogPage />
				</PrivateAdminRoute>
				<Route path='/login'>
					<Login/>
				</Route>
				<Route path='/exception'>
					<Exception exception={500}/>
				</Route>
				<Route >
					<Exception exception={404}/>
				</Route>
			</Switch>
		)
	}
}

export default withRouter(Routes)


