import React, {
	forwardRef,
	// useState, 
	useRef,
	// useMemo 
} from 'react'
import ReactDOM from 'react-dom'
import moment from 'moment'
import L from "leaflet"
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {bounds, divIcon, DivIcon} from "leaflet/dist/leaflet-src.esm";
import {
	MapContainer,
	ImageOverlay,
	useMapEvents,
	Marker,
	Tooltip,
	Polyline, useMap, ZoomControl, FeatureGroup, LayerGroup
} from 'react-leaflet'
import {MineSelect, MapSelect} from '../../containers/modules/elems'
import { 
	delete_query, 
	get_query, 
	put_query,
	post_query,
	unique_chains
} from '../../functions'
import WifiImg from './../../styles/icons/icon_wifi.svg'
import Do1Img from './../../styles/icons/icon_camera.png'
import {
	wifiMarkerIcon,
	workerMarkerIcon,
	transportMarkerIcon,
	do1MarkerIcon
} from './../../styles/icons'
import ReaderModal from './../../containers/modules/ReaderModal'
import CounterModal from "./../../containers/modules/CounterModal";
import { message } from 'antd'
import { del_memory, findMap, set_memory } from '../../functions/memory'
import {Icon} from "leaflet/dist/leaflet-src.esm";
import GeometryUtil from "leaflet-geometryutil";
import {connect} from "react-redux";
import {worker_reducer} from "../../reducers/worker_reducer";
import {setWorkers} from "../../actions/worker_act";
import DoModal from "./DoModal";
import {renderToStaticMarkup} from "react-dom/server";

const red_c = new Icon({
	iconUrl: "/icons/red_circle.png",
	iconSize: [25, 25]
});

// map

class GeoPlane extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			zones: [],		// зоны
			current: false, // текущая карта -убрать на уровень выше
			current_mine: false, // - убрать на уровень выше
			mines: [],		// шахты - убрать на уровень выше
			horizons_all: [],// все горизонты
			horizonts: [],	// карты - убрать на уровень выше
			ac: [],			// все точки
			counters: [],	// все счетчики
			worker: [],		// работники
			offline: [],	// отключенные точки
			equipment: [],
			reader: false,
			reader_dlg: false,
			counter: false,
			counter_dlg: false,
			do_1: false,
			do_1_dlg: false,
			do_2: false,
			id: false,
			change_loader: false,
			iconWifi: false,
			iconWorker: false,
			iconTransport: false,
			iconDo1: false,
			iconDo2: false,
			tmp_dots:[],
			workerjobtitles: []
		}
		this.imageOverlay = React.createRef()
		this.mapRef = React.createRef()
	}

	// монтирование
	componentDidMount() {
		clearTimeout(this.timeout)
		this.timeout=''
		document.addEventListener('click', this.handleClickOutside, true)
		let div_elem = document.getElementById('div-geo-plane')
		div_elem.addEventListener("contextmenu", function (e) {
			e.preventDefault();
		}, false);
		this.getData(true, true)
		this.getJobTitles()
	}

	// размонтирование
	componentWillUnmount() {
		clearTimeout(this.timeout)
		document.removeEventListener('click', this.handleClickOutside, true)
		this.clearWorkers()
	}


	componentDidUpdate(prevProps) {
		if(this.props.current!=prevProps.current) {
			this.changeMap(this.props.current)
		}
	}

	check_workers(){
		return this.state.worker
	}

	getJobTitles(){
		get_query('/workerjobtitle').then((res)=>{
			this.setState({workerjobtitles: res.data}
			)
		})
	}

	// клик за областью
	handleClickOutside = event => {
		const domNode = ReactDOM.findDOMNode(this);
		if (!domNode || !domNode.contains(event.target)) {
			if(this.props.set_reader){
				this.props.set_reader()
			}
			if(this.props.set_do_1){
				this.props.set_do_1()
			}
			if(this.props.set_do_2){
				this.props.set_do_2()
			}
			if(this.props.set_counter){
				this.props.set_counter()
			}
		}
	}

	// получить данные по шахтам и горизонтам
	getData(change, change_mine){
		this.setState({ 
			loader: true,
			ac: [],
			worker: [],
			equipment: []
		})
		let dt = {}
		Promise.all([
			this.getIcons(1)
			.then((res) => {
				dt.iconWifi = res
			}),
			this.getIcons(2)
			.then((res) => {
				dt.iconWorker = res
			}),
			this.getIcons(3)
				.then((res) => {
					dt.iconTransport = res
				}),
			this.getIconsDo(1)
				.then((res) => {
					dt.iconDo1= res
				}),
			this.getIconsDo(2)
				.then((res) => {
					dt.iconDo2= res
				}),

			get_query('/mine', {}).then((res)=>{
				this.setState({
					mines: res.data
				},() => {
					if(typeof change_mine === 'object'){
						// change.height = 600
						// change.width = 1000
						this.changeMine(change_mine)
					} else if(typeof change_mine === 'boolean' && change_mine){
						var mine = findMap(res.data, 'mine_id')
						this.changeMine(mine ? mine : false)
						// this.changeMap(res.data[0] ? res.data[0] : false)
					}

				})
			}),
			get_query('/horizon', {}, true) // - вынести на уровень выше, или в Redux или использовать localstorage на уровне выше и тут
			.then((res) => {
				this.setState({
					horizons_all: res.data,
					horizonts: res.data.filter(x=>x.mine_id==this.state.current_mine.id),
					loader: false,
					iconWifi: dt.iconWifi,
					iconWorker: dt.iconWorker,
					iconTransport: dt.iconTransport,
					iconDo1: dt.iconDo1,
					iconDo2: dt.iconDo2,
				}, () => {
					if(typeof change === 'object'){
						// change.height = 600
						// change.width = 1000
						this.changeMap(change)
					} else if(typeof change === 'boolean' && change){
						var map = findMap(res.data, 'map_id')
						this.changeMap(map ? map : false)
						// this.changeMap(res.data[0] ? res.data[0] : false)
					}else if(typeof change === 'boolean' && !change){
						var map2 = res.data.filter(x=>x.mine_id==this.state.current_mine.id)[0]
						this.changeMap(map2 ? map2 : false)
					}

				})
			})
			.catch(() => {
				this.setState({ 
					horizonts: [], 
					loader: false,
					current: false,
					change_mine: false
				})
			})
		])
	}
	// получить иконки
	getIcons(id){
		return new Promise((resolve, reject) => {
			get_query(`/icon/${id}`, {}, false)
			.then((res) => {
				let icon_url = false
				if(res.data){
					if(res.data.filename !== 'default-worker.png' && res.data.filename !== 'default-reader.png' && res.data.filename !== 'default-truck.png' && res.data.filename !== 'default-do-1.png' && res.data.filename !== 'default-do-2.png'){
						icon_url = process.env.REACT_APP_RADIUS_API + res.data.filename 
					}
				}
				resolve(icon_url)
			})
			.catch((err) => {
				// reject()
				resolve(false)
			})
		})
	}
	//получить иконки ДО
	getIconsDo(id){
		return new Promise((resolve, reject) => {
			get_query(`/equipmenttype/${id}`, {}, false)
				.then((res) => {
					let icon_url = false
					if(res.data){
						if(res.data.icon !== 'default-do-1.png' && res.data.icon !== 'default-do-2.png'){
							icon_url = process.env.REACT_APP_RADIUS_API + res.data.icon
						}
					}
					resolve(icon_url)
				})
				.catch((err) => {
					// reject()
					resolve(false)
				})
		})
	}

	// получить считыватели
	getAc(current){
		if(current){
			get_query('/ac/filter/horizon_id=' + current.id, {}, true)
			.then((res) => {
				this.setState({ ac: res.data })
			})
			.catch((err) => {
				this.setState({ ac: [] })
			})
		}
	}
	//получить счетчики
	getCounters(current){
		if(current){
			get_query(`/counter/filter/horizon_id=${current.id}`, {}, true)
				.then((res) => {
					let counters_tmp = []
					let counters_array=[]
					if(!Array.isArray(res.data)){
						counters_array=[res.data]
					}else {
						counters_array=res.data
					}
					/*let all_counter_workers_tmp = []
					counters_array.map(counter=>{
						all_counter_workers_tmp.push(counter.workers)
					})
					let all_counter_workers = [].concat.apply([], all_counter_workers_tmp)
					console.log(all_counter_workers)*/
/*					all_counter_workers= all_counter_workers.filter((value, index, self) =>
							index === self.findIndex((t) => (
								t.worker_id === value.worker_id && t.last_connect === value.last_connect
							))
					)*/

/*					let uniqueWorkers = [];
					all_counter_workers.forEach((element) => {
						if (!uniqueWorkers.find(worker=>worker.worker_id==element.worker_id)) {
							uniqueWorkers.push(element);
							console.log(new Date(element.last_connect).getTime())
						}else{
							let tmp_worker = uniqueWorkers.find(worker=>worker.worker_id==element.worker_id)
							if(new Date(tmp_worker.last_connect).getTime()<new Date(element.last_connect).getTime()){
								uniqueWorkers.splice(uniqueWorkers.findIndex(v=>v.worker_id==tmp_worker.worker_id), 1)
								uniqueWorkers.push(element)
							}
						}
					});*/
/*					console.log(uniqueWorkers)*/
					counters_array.map(counter=>{
						if(counter.coordinates) {
							counter.coordinates.map(coord => {
								let workers_array = []
								if(!Array.isArray(counter.workers)){
									workers_array=[counter.workers]
								}else {
									workers_array=counter.workers
								}
								workers_array=workers_array
									if (coord.horizon_id == current.id) {
										let counter_tmp = {
											id: counter.id,
											uuid: coord.uid,
											name: counter.name,
											horizon_id: coord.horizon_id,
											coord_x: coord.coord_x,
											coord_y: coord.coord_y,
											workers: workers_array,
											items: counter.items
										}
										counters_tmp.push(counter_tmp)
									}
								}
							)
						}
					})
					this.setState({ counters: counters_tmp })
				})
				.catch((err) => {
					this.setState({ counters: [] })
				})
		}
	}
	// получить ДО1
	getDo1(current){
		if(current){
			get_query('/equipment/filter/horizon_id=' + current.id, {}, true)
				.then((res) => {
					this.setState({ equipment: res.data })
				})
				.catch((err) => {
					this.setState({ equipment: [] })
				})
		}
	}
	// получить зоны
	getAlertLines(current){
		if(current){
			this.state.tmp_dots=[]
			get_query(`/chain/filter/horizon_id=${current.id}`, {}, true)
			.then((res) => {
				let data = res.data.filter( x => !x.is_deleted )
				let tmp_arr = []
				for(let i=0; i<data.length; i++) {

					if (data[i].line != null && data[i].line != '') {
						let n_mass = data[i].line.split('|')

						for (let ik = 0; ik < n_mass.length; ik++) {
							let let1 = n_mass[ik].split(',')[0]
							let let2 = n_mass[ik].split(',')[1]
							tmp_arr.push({chain_id: data[i].id, dot: [let1, let2]})
						}
					}
					this.setState({tmp_dots: tmp_arr})
				}
				this.setState({ zones: data })
			})
			.catch((err) => {
				this.setState({ zones: [] })
			})
		}
	}
	// получить работников
	getWorkers(){
		if(this.props.view_workers){
			if(this.props.is_report){
						return new Promise((resolve, reject)=>{resolve(this.setState({
							worker: this.props.workers_data,
							offline: []
						}))})
						return
			}else{
				this.getCounters(this.state.current)
			return get_query(`/worker/filter/horizon_id=${this.state.current.id}`, {}, false)
			.then((res) => {
				// получаем работников офлайн точки
/*				let dist=0
				this.setState({
					worker:[{horizon:3,
								chain: 14,
								distance: dist,
						worker_id: "3144",
								fio: 'Ivan'},
						{horizon:3,
							chain: 14,
							distance: dist,
							worker_id: "3146",
							fio: 'Ivan 2'},
						{horizon:3,
							chain: 14,
							distance: dist,
							worker_id: "3147",
							fio: 'Ivan 2'},
						{horizon:3,
							chain: 15,
							distance: dist,
							worker_id: "3148",
							fio: 'Ivan 2'},
						{horizon:3,
							chain: 15,
							distance: dist,
							worker_id: "3149",
							fio: 'Ivan 2'}]
				})*/
				this.setState({
					worker: res.data.workers ? res.data.workers : [],
					offline: res.data.offline ? res.data.offline : []
				})
				this.props.setWorkers(res.data.workers)
				return
			})
			.catch((err) => {
				return
			})
			}
		} else {

		}
	}

	pauseGetWorkers=(e)=>{
		clearInterval(this.state.id)
		clearTimeout(this.timeout)
		let timerId = 19
		timerId=setTimeout(this.intervalWorkers, 30000)
		this.timeout=timerId
		let child_markers=e.layer.getAllChildMarkers()
		let child_workers_tmp={data:{workers:[]}}
		for(let i=0; i<child_markers.length; i++){
			let marker_id = child_markers[i].options.id.slice(7) //удаляем worker- из id маркера
			let worker_tmp = this.state.worker.find(worker=>worker.worker_id==marker_id)
			child_workers_tmp.data.workers.push(worker_tmp)
		}
		/*this.props.setWorkersOnlineCount(child_workers_tmp)*/
			this.props.setWorkers(child_workers_tmp.data.workers)
			this.props.setActiveTab(2, child_workers_tmp.data.workers)
	}

	pauseGetWorkersCounter=(e)=>{
/*		clearInterval(this.state.id)
		clearTimeout(this.timeout)*/
		console.log(e)
		window.open(`/scheme?horizon=${Math.abs(e.items[0])}`, '_blank')
/*		let timerId = 19
		timerId=setTimeout(this.intervalWorkers, 30000)
		this.timeout=timerId
		let child_workers_tmp={data:{workers:[]}}
		for(let i=0; i<e.workers.length; i++){
			let worker_tmp = this.state.worker.find(worker=>worker.worker_id==e.workers[i].worker_id)
			child_workers_tmp.data.workers.push(worker_tmp)
		}
		/!*this.props.setWorkersOnlineCount(child_workers_tmp)*!/
		//console.log(this.state.timeout)
		this.props.setCounterName(e.name)
		this.props.setWorkers(e.workers)*/
	}

	startGetWorkers=()=>{
		clearTimeout(this.timeout)
		this.timeout=''
		this.intervalWorkers()
		if(this.props.setCounterName) {
			this.props.setCounterName('')
		}
		this.getWorkers()
	}


	// периодический опрос воркеров
	intervalWorkers=()=>{
		if(!this.props.configuration) {
			if (this.state.id) {
				clearInterval(this.state.id)
			}
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			let t = this
			let interval = 5000
			if (this.props.interval) {
				interval = this.props.interval
			}
			let id = setTimeout(function getW() {
				t.getWorkers().then(() => {
					id = setTimeout(getW, interval)
					t.setState({'id': id})
				})
			}, interval)
			this.setState({'id': id})
		}
	}
	// очистить воркеров
	clearWorkers(){
		clearInterval(this.state.id)
		clearTimeout(this.timeout)
		this.timeout=''
		this.setState({
			id: false,
			worker: []
		})
	}

	// перемещение
	goToCoords(h, w, z){
		this.imageOverlay.current._map.setView([h, w], z ? z : this.imageOverlay.current._map.z)
	}

	// поиск координат воркера
	findWorkerCoords(worker){
		//alert(this.state.current.id)
		if(worker.horizon_id!=this.state.current.id){
			let tmp_current = this.state.horizons_all.find(x=>x.id===worker.horizon_id)

				if (this.state.current_mine.id != tmp_current.mine_id) {
					let tmp_current_mine = this.state.mines.find(x=>x.id==tmp_current.mine_id)
					this.changeMine(tmp_current_mine)
				}
					this.changeMap(tmp_current)
		}
	}

	// изменить шахту
	changeMine(current_mine, refreshHorizon){
		if(current_mine){
			set_memory(current_mine.id, 'mine_id')
			this.setState({
				current_mine: current_mine
			},()=>{
				if(refreshHorizon){
					this.getData(false, current_mine)
				}
			})
		} else {
			del_memory('mine_id')
		}
	}

	// изменить карту
	changeMap(current){
		if(current){
			set_memory(current.id, 'map_id')
			this.setState({ 
				current: current,
				ac: [],
				worker: [],
				tmp_dots: [],
				equipment: [],
			}, () => {
				let height = current.height
				let width = current.width
				this.imageOverlay.current.setBounds([
					[0,0],
					[height,width]
				])
				this.mapRef.current.fitBounds([[0,0], [height, width]])
				this.goToCoords(parseInt(height/2), parseInt(width/2))
				// получить считыватели
				this.getAc(current)
				this.getAlertLines(current)
				this.getDo1(current)
				this.getCounters(current)

				// получить воркеров
				this.clearWorkers()
				this.getWorkers(this.state.current.id)
				this.intervalWorkers(this.state.current.id)
			})
		} else {
			del_memory('map_id')
		}
	}

	// поиск горизонтов 
	getImagePath(){
		let horizonts = this.state.horizonts
		let obj = horizonts.find( x => x.id === this.state.current)
		if(obj){
			let row = Object.assign({}, obj)
			row.map = process.env.REACT_APP_RADIUS_API + obj.map
			return row
		} else {
			return false
		}
	}

	// удалить горизонт
	removeData(){
		this.setState({ loader: true })
		delete_query(`/horizon/${this.state.current.id}`, true)
		.then((res) => {
			this.getData(true, true)
		})
		.catch((err) => {
			this.setState({ loader: true })
		})
	}

	// управление установкой считывателей
	disableClick = (e) => {
		e.preventDefault()
		if(this.props.set_reader){
			this.props.set_reader()
		}
		if(this.props.set_do_1){
			this.props.set_do_1()
		}
		if(this.props.set_do_2){
			this.props.set_do_2()
		}
		if(this.props.set_counter){
			this.props.set_counter()
		}
	}

	// создать считыватель
	createReader = (v) => {
		// check coordinates
		if(v.coord_x < 0 || v.coord_x > this.state.current.width){ 
			message.error('Точка вне карты по ширине')
			return
		}
		if(v.coord_y < 0 || v.coord_y > this.state.current.height){ 
			message.error('Точка вне карты по высоте')
			return
		}

		this.setState({ change_loader: true })
		if(this.props.reader){
			put_query('/ac', {
				horizon_id: v.horizon_id,
				coord_x: v.coord_x,
				coord_y: v.coord_y,
				max_rssi: -30
			})
			.then((res) => {
				this.getAc(this.state.current)
				this.setState({ change_loader: false })
			})
			.catch((err) => {
				this.setState({ change_loader: false })
			})
		}
	}

	// сохранить считыватель
	saveReader = (v) => {
		this.setState({ change_loader: true })
		post_query(`/ac/${v.id}`, {
			id: v.id,
			horizon_id: v.horizon_id,
			identifier: v.identifier,
			name: v.name,
			host: v.host,
			login: v.login,
			password: v.password,
			max_rssi: v.max_rssi,
			place: v.place,
			is_lamp: v.is_lamp,
			interfaces_id: v.interfaces_id
		}, true)
		.then((res) => {
			this.getAc(this.state.current)
			this.setState({ change_loader: false, reader_dlg: false })
		})
		.catch((err) => {
			this.setState({ change_loader: false })
		})
	}


	// создать счетчик
	createCounter = (v, counter) => {
		// check coordinates
		if(v.coord_x < 0 || v.coord_x > this.state.current.width){
			message.error('Точка вне карты по ширине')
			return
		}
		if(v.coord_y < 0 || v.coord_y > this.state.current.height){
			message.error('Точка вне карты по высоте')
			return
		}
		let r={
			name: 'Добавление счетчика',
			horizon_id: v.horizon_id,
			coord_x: v.coord_x,
			coord_y: v.coord_y
		}
		this.setState({counter_dlg:r})
	}

	// сохранить счетчик
	saveCounter = (v) => {
			put_query(`/counter/coord/${v.counter_id}`, {
				horizon_id: v.horizon_id,
				coord_x: v.coord_x,
				coord_y: v.coord_y,
			})
				.then((res) => {
					this.setState({counter_dlg:false})
					this.getCounters(this.state.current)
					this.setState({ change_loader: false })
				})
				.catch((err) => {
					this.setState({ change_loader: false })
				})
	}


	// создать ДО1
	createDo1 = (v, do_type) => {
		// check coordinates
		if(v.coord_x < 0 || v.coord_x > this.state.current.width){
			message.error('Точка вне карты по ширине')
			return
		}
		if(v.coord_y < 0 || v.coord_y > this.state.current.height){
			message.error('Точка вне карты по высоте')
			return
		}

		this.setState({ change_loader: true })
		if(this.props.do_1||this.props.do_2){
			put_query('/equipment', {
				horizon_id: v.horizon_id,
				coord_x: v.coord_x,
				coord_y: v.coord_y,
				equipment_type_id: do_type
			})
				.then((res) => {
					this.getDo1(this.state.current)
					this.setState({ change_loader: false })
				})
				.catch((err) => {
					this.setState({ change_loader: false })
				})
		}
	}

	// сохранить ДО1
	saveDo1 = (v) => {
		this.setState({ change_loader: true })
		post_query(`/equipment/${v.id}`, {
			id: v.id,
			horizon_id: v.horizon_id,
			identifier: v.identifier,
			name: v.name,
			login: v.login,
			password: v.password,
			place: v.place,
			equipment_type_id: v.equipment_type_id,
			url: v.url
		}, true)
			.then((res) => {
				this.getDo1(this.state.current)
				this.setState({ change_loader: false, do_1_dlg: false })
			})
			.catch((err) => {
				this.setState({ change_loader: false })
			})
	}
	//переместить ДО1

	moveDo1 = (v) => {
		this.setState({ change_loader: true })
		post_query(`/equipment/${v.id}`, {
			id: v.id,
			coord_x: v.coord_x,
			coord_y: v.coord_y,
			horizon_id: v.horizon_id,
			identifier: v.identifier,
			name: v.name,
			host: v.host,
			login: v.login,
			password: v.password,
			place: v.place,
			equipment_type_id: v.equipment_type_id
		}, true)
			.then((res) => {
				this.getDo1(this.state.current)
				this.setState({ change_loader: false })
			})
			.catch((err) => {
				this.getDo1(this.state.current)
				this.setState({ change_loader: false })
			})
	}

	//удалить ДО1

	deleteDo1 = (v) => {
		this.setState({ change_loader: true })
		delete_query(`/equipment/${v}`, true)
			.then(() => {
				this.getDo1(this.state.current)
				this.setState({ change_loader: false, do_1_dlg: false })
			})
			.catch((err) => {
				this.setState({ change_loader: false })
			})
	}

	addDot = (v, l, closest) => {
			this.state.tmp_dots.splice(closest, 0, {chain_id:l.chain_id, dot:[v.coord_x, v.coord_y]})
			let mass=[]
			for(let i=0; i<this.state.tmp_dots.length; i++){
				if(this.state.tmp_dots[i].chain_id==l.chain_id){
					mass.push(this.state.tmp_dots[i].dot)
				}
			}
		post_query(`/chain/${l.chain_id}`, {
			id: l.chain_id,
			ac1_id: l.ac1_id,
			ac2_id: l.ac2_id,
			distance: l.distance,
			is_danger: l.danger,
			line: mass.join('|')})
		this.getAc(this.state.current)
	}

	moveDot = async (v, id_dot, chain_id) => {
		let chain_info = ''
		await get_query(`/chain/${chain_id}`, {}, true)
			.then((res) => {
				chain_info = res.data
			})
		this.setState({ change_loader: true })
		this.state.tmp_dots.splice(id_dot, 1, {chain_id:chain_id, dot:[v.coord_x, v.coord_y]})
		let mass=[]
		for(let i=0; i<this.state.tmp_dots.length; i++){
			if(this.state.tmp_dots[i].chain_id==chain_id){
				mass.push(this.state.tmp_dots[i].dot)
			}
		}
		post_query(`/chain/${chain_id}`, {
			id: chain_info.chain_id,
			ac1_id: chain_info.ac1_id,
			ac2_id: chain_info.ac2_id,
			distance: chain_info.distance,
			is_danger: chain_info.danger,
			line: mass.join('|')})
		this.getAc(this.state.current)
		this.setState({ change_loader: false })
	}

	// переместить считыватель
	moveReader = (v) => {
		this.setState({ change_loader: true })
		post_query(`/ac/${v.id}`, {
			id: v.id,
			coord_x: v.coord_x,
			coord_y: v.coord_y,
			horizon_id: v.horizon_id,
			identifier: v.identifier,
			name: v.name,
			host: v.host,
			login: v.login,
			password: v.password,
			max_rssi:v.max_rssi,
			place: v.place
		}, true)
		.then((res) => {
			this.getAc(this.state.current)
			this.setState({ change_loader: false })
		})
		.catch((err) => {
			this.getAc(this.state.current)
			this.setState({ change_loader: false })
		})
	}

	// удалить считыватель
	deleteReader = (v) => {
		this.setState({ change_loader: true })
		delete_query(`/ac/${v}`, true)
		.then(() => {
			this.getAc(this.state.current)
			this.setState({ change_loader: false, reader_dlg: false })
		})
		.catch((err) => {
			this.setState({ change_loader: false })
		})
	}


	moveCounter = (v) => {
		this.setState({ change_loader: true })
		post_query(`/counter/coord/${v.id}`, {
			uid: v.uuid,
			coord_x: v.coord_x,
			coord_y: v.coord_y,
			horizon_id: v.horizon_id
		}, true)
			.then((res) => {
				this.getCounters(this.state.current)
				this.setState({ change_loader: false })
			})
			.catch((err) => {
				this.getCounters(this.state.current)
				this.setState({ change_loader: false })
			})
	}

	// удалить счетчик
	deleteCounter = (v, uid) => {
		this.setState({ change_loader: true })
		delete_query(`/counter/coord/${v}/?uid=${uid}`, true)
			.then(() => {
				this.getCounters(this.state.current)
				this.setState({ change_loader: false, counter_dlg: false })
			})
			.catch((err) => {
				this.setState({ change_loader: false })
			})
	}

	fit=(e)=>{
		e.target.fitBounds([[0,0], [3000, 3000]])
	}

	render(){
		let image = this.state.current ? process.env.REACT_APP_RADIUS_API + this.state.current.map : ''
		let iconWifi = wifiMarkerIcon(this.state.iconWifi)
		let iconWorker = workerMarkerIcon(this.state.iconWorker)
		let iconTransport = transportMarkerIcon(this.state.iconTransport)
		let iconDo1 = do1MarkerIcon(this.state.iconDo1)
		let iconDo2 = do1MarkerIcon(this.state.iconDo2)
		return(
			<div id='div-geo-plane' className='div-geo-plane'>
				{!this.props.hide_select?
					(<><MineSelect
					horizonts={this.state.mines}
					current={this.props.current_mine ? this.props.current_mine.name : false}
					change={(v) => {this.changeMine(v, true)}}
					loading={this.state.loader}
				/>
				<MapSelect 
					horizonts={this.state.horizonts}
					current={this.props.current ? this.props.current.name : false}
					change={(v) => this.changeMap(v)}
					loading={this.state.loader}
				/></>)
				:('')}
				<MapContainer
					ref={this.mapRef}
					id="map_svg"
					crs={L.CRS.Simple}
					zoom={0}
					minZoom={-4}
					maxZoom={20}
					zoomControl={false}
					zoomDelta={0.5}
					zoomSnap={0.25}
					center={[0,0]} // центр [y, x]
					whenCreated={ mapInstance => { this.mapRef.current = mapInstance } }
					whenReady={(e)=>this.fit(e)}
					refreshmap={(e)=>this.fit(e)}
				>
					<ZoomControl zoomDelta={0.5} zoomSnap={0.25} />
					<CurrentOverlay 
						imageRef={this.imageOverlay} 
						image={image}
						bounds={[[0,0], [0,0]]}
						reader={this.props.reader}
						do_1={this.props.do_1}
						do_2={this.props.do_2}
						counter={this.props.counter}
						horizon_id={this.state.current ? this.state.current.id : false}
						create_reader={(v) => this.createReader(v)}
						create_do_1={(v)=> this.createDo1(v, 1)}
						create_do_2={(v)=> this.createDo1(v, 2)}
						create_counter={(v) => this.createCounter(v)}
						startGetWorkers = {this.startGetWorkers}
					/>
					{
							<>
								<div 
									id='AC'
									style={{
										width: '100%', 
										height: '100%',
										cursor: this.props.reader ? `url(${WifiImg}), pointer` : (this.props.do_1 ? `url(${this.state.iconDo1}), pointer` : (this.props.do_2 ? `url(${this.state.iconDo2}), pointer` : undefined))
									}}
									onContextMenu={this.disableClick}
								>
									{
										this.props.view_tmp_dots?(
										this.state.tmp_dots.map((r, id) => {
												return (
													<Tmpcircle
														key={'tmpc-' + id}
														pos={r.dot}
														chain_id={r.chain_id}
														move_dot={(v, chain_id) => this.moveDot(v, id, chain_id)}
													/>
												)
											})):false
									}

									{this.props.view_do_1 ?(
										this.state.equipment.filter(x=>x.equipment_type_id==1).map((r, id) => {
											return(
												<Do1Marker
													key={'do1-' + id}
													draggable={this.props.draggable}
													configuration={this.props.configuration}
													equipment={r}
													do1={this.props.do_1}
													open_do_1_dlg={() => this.setState({ do_1_dlg: r })}
													move_do_1={(v) => this.moveDo1(v)}
													icon={iconDo1}
													offline={this.state.offline}
												/>
											)
										})):false
									}

									{this.props.view_do_2 ?(
										this.state.equipment.filter(x=>x.equipment_type_id==2).map((r, id) => {
											return(
												<Do1Marker
													key={'do2-' + id}
													draggable={this.props.draggable}
													configuration={this.props.configuration}
													equipment={r}
													do1={this.props.do_1}
													open_do_1_dlg={() => this.setState({ do_1_dlg: r })}
													move_do_1={(v) => this.moveDo1(v)}
													icon={iconDo2}
													offline={this.state.offline}
												/>
											)
										})):false
									}

									{this.props.view_readers ? (
										this.state.ac.map((r, id) => {
											return(
												<AcMarker
													key={'acm-' + id}
													draggable={this.props.draggable}
													configuration={this.props.configuration}
													ac={r}
													reader={this.props.reader}
													open_reader_dlg={() => this.setState({ reader_dlg: r })}
													move_reader={(v) => this.moveReader(v)}
													icon={iconWifi}
													offline={this.state.offline}
												/>
											)
										})):false
									}

									{this.props.view_readers ? (
										this.state.counters.map((r, id) => {
											return(
												<CounterMarker
													key={'counter-' + id}
													draggable={this.props.draggable}
													configuration={this.props.configuration}
													counter={r}
													reader={this.props.reader}
													open_counter_dlg={() => this.setState({ counter_dlg: r })}
													move_counter={(v) => this.moveCounter(v)}
													icon={iconWifi}
													offline={this.state.offline}
													pauseGetWorkersCounter={()=>this.pauseGetWorkersCounter(r)}
												/>
											)
										})):false
									}
								</div>
								<ReaderModal
									open={this.state.reader_dlg}
									close={() => this.setState({ reader_dlg: false })}
									save={(v) => this.saveReader(v)}
									delete={(v) => this.deleteReader(v)}
									loader={this.state.change_loader}
								/>
								<DoModal
									open={this.state.do_1_dlg}
									close={() => this.setState({ do_1_dlg: false })}
									save={(v) => this.saveDo1(v)}
									delete={(v) => this.deleteDo1(v)}
									loader={this.state.change_loader}
								/>
								<CounterModal
									open={this.state.counter_dlg}
									close={() => this.setState({ counter_dlg: false })}
									save={(v) => this.saveCounter(v)}
									delete={(v, uid) => this.deleteCounter(v, uid)}
									loader={this.state.change_loader}
								/>
							</>
					}
{/*					{
						this.props.view_do_1 ? (
							<>
								<div
									id='DO1'
									style={{
										width: '100%',
										height: '100%',
										cursor: this.props.do_1 ? `url(${Do1Img}), pointer` : undefined
									}}
									onContextMenu={this.disableClick}
								>
									{
										this.state.equipment.map((r, id) => {
											return(
												<Do1Marker
													key={'do1-' + id}
													draggable={this.props.draggable}
													configuration={this.props.configuration}
													equipment={r}
													do1={this.props.do_1}
													open_do_1_dlg={() => this.setState({ do_1_dlg: r })}
													move_do_1={(v) => this.moveDo1(v)}
													icon={iconDo1}
													offline={this.state.offline}
												/>
											)
										})
									}
								</div>
								<DoModal
									open={this.state.do_1_dlg}
									close={() => this.setState({ do_1_dlg: false })}
									save={(v) => this.saveDo1(v)}
									delete={(v) => this.deleteDo1(v)}
									loader={this.state.change_loader}
								/>
							</>
						) : false
					}*/}
					<AlertLines
						interactive={this.props.draggable}
						ac={this.state.ac}
						zones={this.state.zones}
						view_zones={this.props.view_zones}
						view_alertzones={this.props.view_alertzones}
						dots={this.state.tmp_dots}
						add_dot={(v, l, closest) => this.addDot(v, l, closest)}
					/>
					{
						this.props.view_workers ? (
							<WorkerLayer
								worker={this.state.worker}
								ac={this.state.ac}
								zones={this.state.zones}
								icon={iconWorker}
								icon_transport={iconTransport}
								search_act={this.props.search_act}
								dots={this.state.tmp_dots}
								goTo={(h, w) => this.goToCoords(h, w)}
								pauseGetWorkers = {this.pauseGetWorkers}
								startGetWorkers = {this.startGetWorkers}
								workerjobtitles={this.state.workerjobtitles}
							/>
						) : false
					}
				</MapContainer>
			</div>
		)
	}
}

// image overlay
function CurrentOverlay(props){
	const map = useMapEvents({
			click: (e) => {
				if(props.reader && props.horizon_id){
					let r = {
						horizon_id: props.horizon_id,
						coord_x: e.latlng.lng,
						coord_y: e.latlng.lat
					}
					props.create_reader(r)
				}else if(props.do_1 && props.horizon_id){
					let r = {
						horizon_id: props.horizon_id,
						coord_x: e.latlng.lng,
						coord_y: e.latlng.lat
					}
					props.create_do_1(r)
				}else if(props.do_2 && props.horizon_id){
					let r = {
						horizon_id: props.horizon_id,
						coord_x: e.latlng.lng,
						coord_y: e.latlng.lat
					}
					props.create_do_2(r)
				}else if(props.counter && props.horizon_id){
					let r = {
						horizon_id: props.horizon_id,
						coord_x: e.latlng.lng,
						coord_y: e.latlng.lat
					}
					props.create_counter(r)
				}else{
					props.startGetWorkers()
				}
			}
	})
	return(
		<ImageOverlay 
			ref={props.imageRef} 
			url={props.image}
			bounds={[[0,0], [0,0]]}
		/>
	)
}

// alert zones
function AlertLines(props){
	const map = useMap()
	function getSegment(latlng, polyline) {
		const latlngs = polyline._latlngs;
		let segments = [];
		for (let i = 0; i < latlngs.length - 1; i++) {
			const pointToLineDistance = GeometryUtil.distanceSegment(
				polyline._map,
				latlng,
				latlngs[i],
				latlngs[i + 1]
			);

			segments.push({
				index: i,
				pointToLineDistance,
				segment: [latlngs[i], latlngs[i + 1]]
			});
		}
		segments.sort((a, b) =>
			a.pointToLineDistance < b.pointToLineDistance ? -1 : 1
		);
		const shortestSegment = segments[0];
		if(props.interactive) {
			return shortestSegment;
		}else{
			return null
		}
	}
	const listLines = () => {
		let res = []
		let zones=[]
		if(props.view_zones) {
			zones = props.zones
		}
		if(!props.view_alertzones){
			zones = props.zones.filter( x => !x.is_danger)
		}
		zones.map((z) => {
			let ac1 = props.ac.find( x => x.id === z.ac1_id )
			let ac2 = props.ac.find( x => x.id === z.ac2_id )
			if(ac1 && ac2){
				let tmp_mass = []
				 props.dots.map((r)=>{
				 	if(r.chain_id==z.id) {
				 		tmp_mass.push(r.dot)
				 	}
				 })
				res.push({
					ll: [[ac1.coord_y, ac1.coord_x], ...tmp_mass, [ac2.coord_y, ac2.coord_x]],
					danger: z.is_danger,
					distance: z.distance,
					ac1_id: z.ac1_id,
					ac2_id: z.ac2_id,
					chain_id: z.id,
				})
			}
		})
		return res
	}
	const lines = listLines()
	return(
		<>
			{
				lines.map((l, id) => {
					return(
						<Polyline
							className={'test_class_'+l.chain_id}
							key={id}
							positions={l.ll}
							pathOptions={{ color: l.danger ? 'red' : 'black' }}
							eventHandlers={{
								click: (e) => {
									const closest = getSegment(e.latlng, e.sourceTarget);
									let r = {
										coord_x: e.latlng.lat,
										coord_y: e.latlng.lng
									}
									if(closest!=null) {
										props.add_dot(r, l, closest.index)
									}
								}
							}}
						>
{/*							<Marker
								key={'worker-' + 2}
								id={'worker-' + 2}
								position={rr.latLng}
								icon={red_c}
							/>*/}
							<Tooltip>
								Дистанция: <b>{l.distance}</b><br/>
								Опастность: <b>{ l.danger ? 'Да' : 'Нет' }</b>
							</Tooltip>
						</Polyline>
					)
				})}
		</>
	)
}

function Tmpcircle(props){
	const markerCircleRef = useRef(null)
	const position = [props.pos[0], props.pos[1]]
	const chain_id=props.chain_id
	return(
		<Marker
			style={'width: 5px; height: 5px'}
			ref={markerCircleRef}
			draggable={true}
			position={position}
			icon={red_c}
			eventHandlers={{
				dragend: (e) => {
					const marker = markerCircleRef.current
					if (marker != null) {
						let coord = marker.getLatLng()
						let mr = Object.assign({})
						mr.coord_x = coord.lat
						mr.coord_y = coord.lng
						props.move_dot(mr, chain_id)
					}
				}
			}}
		></Marker>
	)
}

// считыватели
function AcMarker(props){
	const markerRef = useRef(null)
	const position = [props.ac.coord_y, props.ac.coord_x]
	const wifiIcon = () => {
		if(props.offline.indexOf(props.ac.host) !== -1){
			return props.icon.alert
		} else {
			return props.ac.host ? props.icon.normal : props.icon.nodata
		}
	}
	return(
		<Marker 
			ref={markerRef}
			zIndex={5}
			zIndexOffset={-500}
			draggable={props.draggable}
			position={position}
			icon={wifiIcon()}
			eventHandlers={{
				click: (e) => {
					if(props.configuration){ props.open_reader_dlg() }
				},
				dragend: (e) => {
					const marker = markerRef.current
					if (marker != null) {
						let coord = marker.getLatLng()
						let mr = Object.assign({}, props.ac)
						mr.coord_x = coord.lng
						mr.coord_y = coord.lat
						props.move_reader(mr)
					}
				}
			}}
		>
			{
				!props.configuration && (
					<Tooltip>
						ID:  <b>{props.ac.identifier ? props.ac.identifier : '?'}</b><br/>
						Host: <b>{props.ac.host ? props.ac.host : '?'}</b><br/>
						Расположен: <b>{props.ac.place ? props.ac.place : '?'}</b>
					</Tooltip>
				)
			}
		</Marker>
	)
}

function CounterMarker(props){
	const counterMarkerRef = useRef(null)
	const position = [props.counter.coord_y, props.counter.coord_x]
	const iconMarkup = renderToStaticMarkup(<div style={{backgroundColor: 'lightgray', width:'100%', height: '100%', border: '2px solid black', padding: '0 2px 2px'}}>
		<div style={{backgroundColor: "#9fcf62", color: "white"}}>{props.counter.name}:</div>
		<div style={{backgroundColor: "#eef4ed"}}>Персонал: {props.counter.workers.filter((x)=>x.worker_type_id!=1).length}</div>
		<div style={{backgroundColor: "#eef4ed"}}>Техника: {props.counter.workers.filter((x)=>x.worker_type_id==1).length}</div>
	</div>);
	const customMarkerIcon = divIcon({
		html: iconMarkup,
		iconSize: [120]
	});
	return(
		<Marker
			ref={counterMarkerRef}
			zIndex={5}
			zIndexOffset={-500}
			draggable={props.draggable}
			position={position}
			icon={customMarkerIcon}
			width={'200px'}
			eventHandlers={{
				click: (e) => {
					if(props.configuration){
						props.open_counter_dlg()
					}else{
						props.pauseGetWorkersCounter()
					}
				},
				dragend: (e) => {
					const marker = counterMarkerRef.current
					if (marker != null) {
						let coord = marker.getLatLng()
						let mr = Object.assign({}, props.counter)
						mr.coord_x = coord.lng
						mr.coord_y = coord.lat
						props.move_counter(mr)
					}
				}
			}}
		>
			{
				!props.configuration && (
					<Tooltip>
						счетчик
					</Tooltip>
				)
			}
		</Marker>
	)
}

function IdWorkerMarker(props){
	const idWorkerMarkerRef = useRef(null)
	const position = props.position
	const iconMarkup = renderToStaticMarkup(<div>
		<div style={{border: props.offline?'2px solid red':'none'}}>
			<img src={props.icon.options.iconUrl} style={{width:props.offline?'26px':'30px'}} />
		</div>
		<div style={{textAlign: 'center'}}>{props.show_id}</div>
	</div>);
	const customMarkerIcon = divIcon({
		html: iconMarkup,
		iconSize: [30, 30]
	});
	return(
		<Marker
			id={props.id}
			ref={idWorkerMarkerRef}
			zIndex={5}
			zIndexOffset={-500}
			position={position}
			icon={customMarkerIcon}
			width={'200px'}
		>
			{
				!props.configuration && (
					<Tooltip style={{border: '2px solid black'}}>
						<div >
							<div style={{backgroundColor: 'lightgray', textAlign: 'center'}}><b>{props.fio}</b></div>
							<b>ID</b> {props.show_id}<br/>
							<b>Должность:</b><br/>{props.worker_type_id==1?'':props.job_title}<br/>
							<b>Соединение:</b><br/> {props.last_connect}<br/>
							<b>Телефон:</b> {props.phone}<br/>
						</div>
					</Tooltip>
				)
			}
		</Marker>
	)
}

// ДО1
function Do1Marker(props){
	const do1markerRef = useRef(null)
	const position = [props.equipment.coord_y, props.equipment.coord_x]
	const do1Icon = () => {
			return props.equipment.url ? props.icon.normal : props.icon.nodata
	}
	return(
		<Marker
			ref={do1markerRef}
			zIndex={5}
			zIndexOffset={-500}
			draggable={props.draggable}
			position={position}
			icon={do1Icon()}
			eventHandlers={{
				click: (e) => {
					if(props.configuration){ props.open_do_1_dlg() }else {
						if(props.equipment.url) {
							var win = window.open(props.equipment.url, '_blank');
							win.focus();
						}
					}
				},
				dragend: (e) => {
					const marker = do1markerRef.current
					if (marker != null) {
						let coord = marker.getLatLng()
						let mr = Object.assign({}, props.equipment)
						mr.coord_x = coord.lng
						mr.coord_y = coord.lat
						props.move_do_1(mr)
					}
				}
			}}
		>
			{
				!props.configuration && (
					<Tooltip>
						ID:  <b>{props.equipment.identifier ? props.equipment.identifier : '?'}</b><br/>
						URL: <b>{props.equipment.url ? props.equipment.url : '?'}</b><br/>
						Расположен: <b>{props.equipment.place ? props.equipment.place : '?'}</b>
					</Tooltip>
				)
			}
		</Marker>
	)
}

// Работники
function WorkerLayer(props){
	let chains = unique_chains(props.worker)
	const map = useMap()
	let tmpw = []
	let tmpw_uncluster = []
	return(
		<>
			{
				chains.map((chain, chain_id) => {
					let wc = props.worker.filter( x => x.chain === chain)
					// filter workers
					wc.map((w, id) => {
						let new_coord_x = false
						let new_coord_y = false
						let last_connect = false
						let job_title = ''
						if(props.workerjobtitles&&props.workerjobtitles.find(x=> x.id==w.job_title_id)){
							job_title = props.workerjobtitles.find(x=> x.id==w.job_title_id).name
						}
						let rr={}
						let chain = props.zones.find( x => x.id === w.chain )
						if(chain){
							let ac_s = props.ac.find( x => x.id === chain.ac1_id )
							let ac_e = props.ac.find( x => x.id === chain.ac2_id )
							if(ac_s && ac_e){
								let tmp_mass = []
								props.dots.map((r)=>{
									if(r.chain_id==w.chain) {
										tmp_mass.push(r.dot)
									}
								})
								let line=L.polyline([[ac_s.coord_y, ac_s.coord_x],...tmp_mass,[ac_e.coord_y, ac_e.coord_x]]);
								let total1=GeometryUtil.accumulatedLengths(line.getLatLngs())
								const totalLength = total1.reduce((a, b) => a + b, 0);
								let ratio=w.distance/chain.distance;
								rr=GeometryUtil.interpolateOnLine(map, line, ratio)
								new_coord_x = rr.latLng.lng//ac_s.coord_x + w.distance
								new_coord_y = rr.latLng.lat//ac_s.coord_y + (diff_y * w.distance)
								last_connect = moment(w.last_connect).format('DD-MM-YYYY HH:mm:ss')
							}else if(ac_s){
								new_coord_x = ac_s.coord_x
								new_coord_y = ac_s.coord_y
								last_connect = moment(w.last_connect).format('DD-MM-YYYY HH:mm:ss')
							}else if(ac_e){
								new_coord_x = ac_e.coord_x
								new_coord_y = ac_e.coord_y
								last_connect = moment(w.last_connect).format('DD-MM-YYYY HH:mm:ss')
							}
						}
						if(new_coord_x && new_coord_y){
							// следилка
							if (props.search_act === w.worker_id){
								props.goTo(new_coord_y, new_coord_x)
/*								tmpw_uncluster.push(
									<Marker
										key={'worker-' + w.worker_id}
										id={'worker-' + w.worker_id}
										position={[new_coord_y,new_coord_x]}
										icon={w.worker_type_id!=2?props.icon.search:props.icon_transport.search}
										zIndexOffset={1000}
									>
										<Tooltip>
											{w.worker_type_id==2?'Наименование транспорта':'ФИО Работника'} <b>{w.fio}</b><br/>
											{w.worker_type_id==2?'Номер транспорта:':'ID Работника:'} <b>{w.worker_id}</b><br/>
											Соединение: <b>{last_connect}</b><br/>
											Дистанция: <b>{w.distance}</b><br/>
											{w.speed?'Скорость:'+<b>{w.speed}</b>+<br />:''}
										</Tooltip>
									</Marker>
								)*/
							}
							// не кластеризовать потерянных
							if(!w.is_online) {
								tmpw.push(
									<IdWorkerMarker
										key={'worker-' + w.worker_id}
										id={'worker-' + w.worker_id}
										position={[new_coord_y,new_coord_x]}
										icon={w.worker_type_id!=1?props.icon.normal:props.icon_transport.normal}
										zIndexOffset={1000}
										worker_type_id={w.worker_type_id}
										show_id = {w.worker_id}
										fio={w.fio}
										distance={w.distance}
										speed={w.speed}
										last_connect={last_connect}
										phone={w.phone}
										job_title={job_title}
										offline={true}
									>
										<Tooltip>
											{w.worker_type_id==1?'Наименование транспорта':'ФИО Работника'} <b>{w.fio}</b><br/>
											{w.worker_type_id==1?'Номер транспорта:':'ID Работника:'} <b>{w.worker_id}</b><br/>
											Соединение: <b>{last_connect}</b><br/>
											Дистанция: <b>{w.distance}</b><br/>
											{w.speed?'Скорость:'+<b>{w.speed}</b>+<br/>:''}
										</Tooltip>
									</IdWorkerMarker>
								)
							} else {
								tmpw.push(
									<IdWorkerMarker
										key={'worker-' + w.worker_id}
										id={'worker-' + w.worker_id}
										position={[new_coord_y,new_coord_x]}
										icon={w.worker_type_id!=1?props.icon.normal:props.icon_transport.normal}
										zIndexOffset={1000}
										worker_type_id={w.worker_type_id}
										show_id = {w.worker_id}
										fio={w.fio}
										distance={w.distance}
										speed={w.speed}
										last_connect={last_connect}
										phone={w.phone}
										job_title={job_title}
										offline={false}
									>
										<Tooltip>
											{w.worker_type_id==1?'Наименование транспорта':'ФИО Работника'} <b>{w.fio}</b><br/>
											{w.worker_type_id==1?'Номер транспорта:':'ID Работника:'} <b>{w.worker_id}</b><br/>
											Соединение: <b>{last_connect}</b><br/>
											Дистанция: <b>{w.distance}</b><br/>
											{w.speed?'Скорость:'+<b>{w.speed}</b>+<br/>:''}

										</Tooltip>
									</IdWorkerMarker>
								)
							}
						}
					})
				})
			}
				<MarkerClusterGroup
					maxZoom={0}
					zIndex={10000}
					// showCoverageOnHover={false}
					// spiderfyOnMaxZoom={true}
					// zoomToBoundsOnClick={true}
					showCoverageOnHover={true}
					maxClusterRadius={16}
					eventHandlers={{
						clusterclick: (e) => {
							props.pauseGetWorkers(e)
						},
/*						unspiderfied: (e) => {
							props.startGetWorkers()
						}*/
					}
					}
				>
					{ tmpw }
				</MarkerClusterGroup>
				{ tmpw_uncluster }
		</>
	)
}
export default connect(null, {setWorkers}, null, {forwardRef:true}) (GeoPlane)
