import React, {createRef} from 'react'
import {
	Button,
	Table
} from 'antd'
import ActShift from '../../containers/modules/ActShift';
import {TableShiftAction, TableUserAction} from './../../containers/ui/tables'
import {
	get_query,
	delete_query,
	put_query,
	post_query
} from '../../functions'
import NewCounter from "../../containers/modules/NewCounter";
import ActCounter from "../../containers/modules/ActCounter";


// mines
export default class Counters extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			row: false,
			data: []
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		get_query('/counter', {}, true)
			.then((res) => {
				if(res.data!='null') {
					this.setState({data: res.data, loader: false})
				}else{
					this.setState({data: '', loader: false})
				}
			})
			.catch((err) => {
				console.log(err)
				this.setState({ data: [], loader: false })
			})
	}

	confCol(){
		let columns = [{
			title: 'Название',
			dataIndex: 'name',
			key: 'name'
		},{
			title: 'Действия',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<TableUserAction
						edit={() => this.setState({
							open: 'Редактировать счетчик',
							row: row
						})}
						remove={() => this.removeAction(row.id)}
					/>
				)
			}
		}]
		return columns
	}

	removeAction(id){
		this.setState({ loader: true })
		delete_query(`/counter/${id}`, true)
			.then((res) => {
				this.getData()
			})
			.catch((err) => {
				this.setState({ loader: false })
			})
	}

	saveCounter(v){
		this.setState({ save_loader: true })
		post_query(`/counter/${v.id}`, {
			name: v.name,
			items: v.items
		}, true)
			.then((res) => {
				this.setState({
					save_loader: false,
					open: false,
				}, () => {
					this.getData()
				})
			})
			.catch((err) => {
				this.setState({ save_loader: false })
			})
	}

	render(){
		return(
			<>
				<div className='settings-title'>
					<p>Счетчики</p>
				</div>
				<div  className='settings-body'>
					<Table
						columns={this.confCol()}
						dataSource={this.state.data}
						loading={this.state.loader}
						pagination={false}
						rowKey='id'
					/>
				</div>
				<div className='settings-btn'>
					<NewCounter
					getData={()=>{this.getData()}}
					/>
				</div>
				<ActCounter
					save_loader={this.state.save_loader}
					open={Boolean(this.state.open)}
					title={this.state.open}
					row={this.state.row}
					close={() => this.setState({ open: false })}
					save={(v) => this.saveCounter(v)}
				/>
			</>
		)
	}
}


