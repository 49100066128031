// tmp data files

export const worker_data = [{
    mac: '00:00:AD:12:33:22',
    worker: 'Шахтин Алексей Владимирович',
    worker_id: 'we-3896561'
},{
    mac: '00:00:AD:12:33:21',
    worker: 'Гурьев Дмитрий Владимирович',
    worker_id: 'zd-3116561'
},{
    mac: '00:00:AD:12:33:25',
    worker: 'Пересыпкин Виктор Сергеевич',
    worker_id: 'aa-3896262'
},{
    mac: '00:00:AD:12:33:12',
    worker: 'Проскурин Дмитрий Александрович',
    worker_id: 'qw-3896561'
},{
    mac: '00:00:AD:12:33:22',
    worker: 'Шахтин Алексей Владимирович',
    worker_id: 'sd-3896561'
},{
    mac: '00:00:AD:12:33:22',
    worker: 'Шахтин Алексей Владимирович',
    worker_id: 'ss-3896561'
},{
    mac: '00:00:AD:12:33:22',
    worker: 'Шахтин Алексей Владимирович',
    worker_id: 'sd-3896561'
},{
    mac: '00:00:AD:12:33:22',
    worker: 'Шахтин Алексей Владимирович',
    worker_id: 'lk-3896561'
}]

export const user_data = [{
    user: 'Первышин Игнат Алексеевич',
    login: 'mylogin',
    email: 'mulogin@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Соболев Сергей Андреевич',
    login: 'sobolev',
    email: 'sobolev@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Красин Василий Викторович',
    login: 'krasin',
    email: 'krasin@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Сотников Андрей Сергеевич',
    login: 'sotnikov',
    email: 'sotnikov@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Первышин Игнат Алексеевич',
    login: 'mylogin',
    email: 'mulogin@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Первышин Игнат Алексеевич',
    login: 'mylogin',
    email: 'mulogin@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Первышин Игнат Алексеевич',
    login: 'mylogin',
    email: 'mulogin@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Первышин Игнат Алексеевич',
    login: 'mylogin',
    email: 'mulogin@mail.ru',
    password: 'alskjdfhalsk'
},{
    user: 'Первышин Игнат Алексеевич',
    login: 'mylogin',
    email: 'mulogin@mail.ru',
    password: 'alskjdfhalsk'
}]

export const fontsw = [
    'Roboto',
    'Arial, sans-serif',
    'Comic Sans MS, cursive',
    'Courier New, monospace',
    'Franklin Gothic Medium, sans-serif',
    'Georgia, serif',
    'Impact, sans-serif',
    'Lucida Console, monospace',
    'Lucida Sans Unicode, sans-serif',
    'Microsoft Sans Serif, sans-serif',
    'Palatino Linotype, serif',
    'Sylfaen, serif',
    'Tahoma, sans-serif',
    'Times New Roman, serif',
    'Trebuchet MS, sans-serif',
    'Verdana, sans-serif'
]