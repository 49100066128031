import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import 'antd/dist/antd.css'
import App from './components/main/App'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
    <App />,
	document.getElementById('root')
)

reportWebVitals();
