import React from 'react'
import {
	Button,
	Table
} from 'antd'
import ActAcInterface from '../../containers/modules/ActAcInterface'
import { TableShiftAction } from './../../containers/ui/tables'
import {
	get_query,
	delete_query,
	put_query,
	post_query
} from '../../functions'



// workers
export default class AcInterfaces extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			row: false,
			data: []
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		get_query('/interfaces', {}, true)
			.then((res) => {
				if(res.data!='null') {
					this.setState({data: res.data, loader: false})
				}else{
					this.setState({data: '', loader: false})
				}
			})
			.catch((err) => {
				console.log(err)
				this.setState({ data: [], loader: false })
			})
	}

	confCol(){
		let columns = [{
			title: 'Название',
			dataIndex: 'name',
			key: 'name'
		},{
			title: 'Действия',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<TableShiftAction
						edit={() => this.setState({
							open: 'Редактировать',
							row: row
						})}
						remove={() => this.removeAction(row.id)}
					/>
				)
			}
		}]
		return columns
	}

	removeAction(id){
		this.setState({ loader: true })
		delete_query(`/interfaces/${id}`, true)
			.then((res) => {
				this.getData()
			})
			.catch((err) => {
				this.setState({ loader: false })
			})
	}

	shiftAction(row){
		this.setState({ save_loader: true })
		row = Object.assign({}, row)
		if(row.id){
			post_query(`/interfaces`, row, true)
				.then((res) => {
					this.setState({ open: false, row: false, save_loader: false })
					this.getData()
				})
				.catch((err) => {
					this.setState({ save_loader: false })
				})
		} else {
			put_query('/interfaces', row, true)
				.then((res) => {
					this.setState({ open: false, row: false, save_loader:false })
					this.getData()
				})
				.catch((err) => {
					this.setState({ save_loader: false })
				})
		}
	}

	render(){
		return(
			<>
				<div className='settings-title'>
					<p>Интерфейсы точек</p>
				</div>
				<div  className='settings-body'>
					<Table
						columns={this.confCol()}
						dataSource={this.state.data}
						loading={this.state.loader}
						pagination={false}
						rowKey='id'
					/>
				</div>
				<div className='settings-btn'>
					<Button
						style={{ width: '100%' }}
						type='primary'
						onClick={() => this.setState({
							open: 'Добавить интерфейс',
							row: false
						})}
					>
						Добавить интерфейс
					</Button>
				</div>
				<ActAcInterface
					save_loader={this.state.save_loader}
					open={Boolean(this.state.open)}
					title={this.state.open}
					row={this.state.row}
					close={() => this.setState({ open: false })}
					save={(v) => this.shiftAction(v)}
				/>
			</>
		)
	}
}


