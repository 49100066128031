import React from 'react';
import { Button, Table } from 'antd';
import { get_query, delete_query, put_query, post_query } from '../../functions';
import { TableUserAction } from './../../containers/ui/tables';
import ActWorker from '../../containers/modules/ActWorker';
import { useSelector } from "react-redux";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from "moment";

export default class WorkersForScheme extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			row: false,
			data: [],
			data_filter: [],
			filteredInfo: {},
			job_titles: [],
			departments: [],
			mines: [],
			worker_types: [],
			online: [],
			technic: []
		};
	}

	componentDidMount() {
		this.getData();
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.search_in_table !== prevProps.search_in_table ||
			this.props.selected_worker_type !== prevProps.selected_worker_type ||
			this.props.selected_job_title !== prevProps.selected_job_title ||
			this.props.selected_status !== prevProps.selected_status ||
			this.props.selected_horizon !== prevProps.selected_horizon
		) {
			this.getData();
		}

		// Сброс данных при переключении табов
		if (this.props.activeTab !== prevProps.activeTab) {
			this.setState({ data: [], data_filter: [] });
			this.getData();
		}
	}

	async getData() {
		try {
			await Promise.all([
				this.getOnline(),
				this.getJobTitle(),
				this.getDepartments(),
				this.getMines(),
				this.getTypes()
			]);

			this.setState({ loader: true });

			const res = await get_query('/worker', {}, true);
			let mine_data = '';
			if (this.props.activeTab == 2) {
				mine_data = await this.processTabData(res);
			} else {
				mine_data = await this.processNonTabData(res);
			}

			mine_data = this.enrichMineData(mine_data);

			this.applyFilters(mine_data);
		} catch (err) {
			console.log(err);
			this.setState({ data: [], loader: false, data_filter: [] });
		}
	}

	async processTabData(res) {
		let mine_data = '';
		if (this.props.workersForTab && this.props.workersForTab.length > 0) {
			mine_data = this.props.workersForTab.map(x => { x.id = x.worker_id; return x });
		} else {
			if (this.props.horizon.is_aggregate == true) {
				mine_data = res.data.filter(x => x.mine_id == this.props.mine.id)
					.filter(x => this.state.technic.find(y => y.id != x.worker_type_id));
			} else {
				mine_data = await get_query(`/worker/filter/horizon_id=${this.props.horizon.id}`, {}, false).then(res2 => {
					return res2.data.workers.filter(x => this.state.technic.find(y => y.id != x.worker_type_id));
				});
			}
		}
		return mine_data;
	}

	async processNonTabData(res) {
		let mine_data = '';
		if (this.props.workersForTab && this.props.workersForTab.length > 0) {
			mine_data = this.props.workersForTab.map(x => { x.id = x.worker_id; return x })
				.filter(x => this.state.technic.find(y => y.id == x.worker_type_id));
		} else {
			if (this.props.horizon.is_aggregate == true) {
				mine_data = res.data.filter(x => x.mine_id == this.props.mine.id)
					.filter(x => this.state.technic.find(y => y.id == x.worker_type_id));
			} else {
				mine_data = await get_query(`/worker/filter/horizon_id=${this.props.horizon.id}`, {}, false).then(res2 => {
					return res2.data.workers.filter(x => this.state.technic.find(y => y.id == x.worker_type_id));
				});
			}
		}
		return mine_data;
	}

	enrichMineData(mine_data) {
		mine_data.map(x => x.id = x.worker_id);
		if (mine_data.length > 0) {
			mine_data.map(x => {
				x.job_title = this.state.job_titles.find(y => y.id == x.job_title_id)?.name || '';
				x.department = this.state.departments.find(y => y.id == x.department_id)?.name || '';
				x.category = this.state.worker_types.find(y => y.id == x.worker_type_id)?.name || '';
				x.status = 'Не на смене';
				x.horizon = '';
				x.horizon_id = '';

				const onlineWorker = this.state.online.find(y => y.worker_id == x.id);
				if (onlineWorker) {
					x.status = onlineWorker.is_online ? 'Онлайн' : 'Оффлайн';
					x.horizon = this.props.horizons.find(m => m.id == onlineWorker.horizon)?.name || '';
					x.horizon_id = onlineWorker.horizon || '';
				}
			});
		}
		return mine_data;
	}

	applyFilters(mine_data) {
		if (this.props.selected_worker_type != 0) {
			mine_data = mine_data.filter(x => x.worker_type_id == this.props.selected_worker_type);
		}
		if (this.props.selected_job_title != 0) {
			mine_data = mine_data.filter(x => x.job_title_id == this.props.selected_job_title);
		}
		if (this.props.selected_status != 0) {
			mine_data = mine_data.filter(x => x.status == this.props.selected_status);
		}
		if (this.props.selected_horizon != 0) {
			mine_data = mine_data.filter(x => x.horizon_id == this.props.selected_horizon);
		}
		if (this.props.search_in_table != '') {
			let mine_data_tmp = [];
			let mine_data_tmp_fio = mine_data.filter(x => x.fio.toLowerCase().indexOf(this.props.search_in_table.toLowerCase()) >= 0);
			let mine_data_tmp_id = mine_data.filter(x => x.id.toString().indexOf(this.props.search_in_table) >= 0);
			mine_data_tmp.push(...mine_data_tmp_fio);
			mine_data_tmp.push(...mine_data_tmp_id);
			mine_data = mine_data_tmp;
		}
		this.setState({ data: mine_data, loader: false, data_filter: mine_data });
		this.props.setCount(mine_data.length);
	}

	exportWorkersForTab(fileName) {
		this.setState({ 'isExportLoading': true });
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';
		let dataHeader = [{
			'Персонал': '',
			'Шахта': this.props.mine.name,
			'Диспетчер': localStorage.getItem('username'),
			'Время': new Date()
		}];
		let data2 = this.state.data.map((worker, i) => ({
			'№': i + 1,
			'Номер лампы': worker.id,
			'ФИО/Наименование транспорта': worker.fio,
			'Категория': worker.category,
			'Должность': worker.job_title,
			'Участок/отдел': worker.department,
			'Стаж': worker.experience,
			'Статус': worker.status,
			'Телефон': worker.phone,
			'Горизонт': worker.horizon
		}));
		const ws = XLSX.utils.json_to_sheet(dataHeader);
		XLSX.utils.sheet_add_json(ws, data2, { origin: "A4" });
		const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
		this.setState({ 'isExportLoading': false });
	}

	getOnline() {
		return get_query(`/stat/online/${this.props.mine.id}`, {}, false).then((res) => {
			if (res.workers) {
				let onlineData = this.props.activeTab == 2 ? res.workers.workers.concat(res.workers.vehicle) : res.workers.vehicle;
				this.setState({ online: onlineData });
				return res.workers;
			}
			return false;
		});
	}

	getJobTitle() {
		return get_query(`/workerjobtitle/`, {}, false).then((res) => {
			if (res.data) {
				this.setState({ job_titles: res.data });
			}
			return res.data;
		});
	}

	getTypes() {
		return get_query(`/workertype/`, {}, false).then((res) => {
			if (res.data) {
				this.setState({ worker_types: res.data, technic: res.data.filter(x => x.is_vehicle) });
			}
			return res.data;
		});
	}

	getDepartments() {
		return get_query(`/department/`, {}, false).then((res) => {
			if (res.data) {
				this.setState({ departments: res.data });
			}
			return res.data;
		});
	}

	getMines() {
		return get_query(`/mine/`, {}, false).then((res) => {
			if (res.data) {
				this.setState({ mines: res.data });
			}
			return res.data;
		});
	}

	clearFilter = () => {
		this.setState({ filteredInfo: {}, data_filter: this.state.data });
	}

	handleChange = (pagination, filters, sorter, extra) => {
		this.setState({ filteredInfo: filters, data_filter: extra.currentDataSource });
	}

	exportWorkersJson = () => {
		let export_data = this.state.data.map(worker => ({
			'Mac': worker.mac ? worker.mac.toString().toUpperCase() : '',
			'BeaconType': worker.worker_type_id,
			'PersonalNumber': worker.id,
			'Name': worker.fio
		}));
		let exstring = JSON.stringify(export_data);
		let blob = new Blob([exstring], { type: 'application/json' });
		let link = document.createElement('a');
		link.download = 'BeaconList.json';
		link.href = URL.createObjectURL(blob);
		link.click();
		URL.revokeObjectURL(link.href);
	}

	render() {
		let columns = [
			{
				title: '№',
				render: (text, record) => (this.state.data.indexOf(record) + 1)
			},
			{
				title: 'Номер лампы',
				dataIndex: 'id',
				width: 140,
				className: 'txt-center',
				sorter: (a, b) => a.id - b.id
			},
			{
				title: 'ФИО/Наименование',
				dataIndex: 'fio',
				sorter: (a, b) => a.fio.localeCompare(b.fio)
			},
			{
				title: 'Категория',
				dataIndex: 'category',
				sorter: (a, b) => a.category.localeCompare(b.category)
			},
			{
				title: 'Должность',
				dataIndex: 'job_title',
				sorter: (a, b) => a.job_title.localeCompare(b.job_title)
			},
			{
				title: 'Участок/отдел',
				dataIndex: 'department',
				sorter: (a, b) => a.department.localeCompare(b.department)
			},
			{
				title: 'Стаж',
				dataIndex: 'experience',
				sorter: (a, b) => a.experience - b.experience
			},
			{
				title: 'Статус',
				dataIndex: 'status',
				sorter: (a, b) => a.status.localeCompare(b.status),
				render: (record) => (
					<div style={{ color: record === 'Оффлайн' ? 'red' : record === 'Онлайн' ? 'green' : 'black' }}>
						{record}
					</div>
				)
			},
			{
				title: 'Последнее соединение',
				dataIndex: 'last_connect',
				sorter: (a, b) => moment(a.last_connect).unix() - moment(b.last_connect).unix(),
				render: (record) => moment(record).format('DD-MM-YYYY HH:mm:ss')
			},
			{
				title: 'Телефон',
				dataIndex: 'phone',
				sorter: (a, b) => a.phone.localeCompare(b.phone)
			},
			{
				title: 'Горизонт',
				dataIndex: 'horizon',
				sorter: (a, b) => a.horizon.localeCompare(b.horizon)
			}
		];

		return (
			<>
				<div className='div-flex-row ant-row-space-between settings-title'>
					<p>Сотрудники/Транспорт</p>
					<div className='div-flex-row' style={{ flexDirection: 'column', justifyContent: 'center' }}>
					</div>
				</div>
				<div className='settings-body'>
					<Table
						onRow={(record, rowIndex) => {
							return {
								onClick: event => window.open(`/scheme?horizon=${record.horizon_id}`, '_blank')
							};
						}}
						columns={columns}
						dataSource={this.state.data}
						loading={this.state.loader}
						pagination={false}
						rowKey='id'
						onChange={this.handleChange}
					/>
				</div>
			</>
		);
	}
}
