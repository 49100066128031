import React from 'react'
import {
	Button,
	message,
	Modal,
	Table,
	Tooltip
} from 'antd'
import {
	WarningOutlined,
	FileAddOutlined
} from '@ant-design/icons'
import { check_chains, delete_query, get_query, post_query, put_query } from './../../functions'
import { TblDistance } from '../ui/tables'
import AcSelect from './../modules/AcSelect'
import DistanceField from './../modules/DistanceField'
import AlertField from './../modules/AlertField'


// Alert zones
export default class AlertZones extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			open: false,
			loader: false,
			change_loader: false,
			data: []
		}
	}

	// добавить правило
	addCompair(){
		let data = this.state.data
		let res = [{
			ac1_id: null,
			ac2_id: null,
			distance: 0,
			is_danger: false,
			unsaved: true
		}].concat(data)
		this.setState({ data: res })
	}

	// сохранить правило
	saveCompair(idx){
		let data = Object.assign([], this.state.data)
		this.setState({ change_loader: true })
		const { status, msg } = check_chains(idx, this.state.data)
		if(status){
			if(data[idx].id){
				post_query('/chain/' + data[idx].id, {
					ac1_id: data[idx].ac1_id,
					ac2_id: data[idx].ac2_id,
					distance: data[idx].distance,
					is_danger: data[idx].is_danger.toString()
				}, true)
				.then((res) => {
					let newobj = res.data
					newobj.is_danger = newobj.is_danger === 'true' ? true : false
					data[idx] = newobj
					this.setState({ change_loader: false, data: data })
				})
				.catch((err) => {
					console.log(err)
					this.setState({ change_loader: false })
				})
			} else {
				put_query('/chain', {
					ac1_id: data[idx].ac1_id,
					ac2_id: data[idx].ac2_id,
					distance: data[idx].distance,
					is_danger: data[idx].is_danger.toString()
				}, true)
				.then((res) => {
					let newobj = res.data
					newobj.is_danger = newobj.is_danger === 'true' ? true : false
					data[idx] = newobj
					this.setState({ change_loader: false, data: data })
				})
				.catch((err) => {
					console.log(err)
					this.setState({ change_loader: false })
				})
			}
		} else {
			message.error(msg)
			this.setState({ change_loader: false })
		}
	}

	deleteCompair(idx){
		let data = Object.assign([], this.state.data)
		if(data[idx].id){
			this.setState({ change_loader: false })
			delete_query('/chain/' + data[idx].id, true)
			.then((res) => {
				let new_data = data.filter( x => x.id !== data[idx].id)
				this.setState({ change_loader: false, data: new_data })
			})
			.catch((err) => {
				this.setState({ change_loader: false })
			})
		} else {
			let new_data = data.filter( (x, x_id) => x_id !== idx)
			this.setState({ change_loader: false, data: new_data })
		}
	}

	// изменить правило
	changeComair(idx, field, val){
		let data = this.state.data
		data[idx][field] = val
		data[idx].unsaved = true
		this.setState({ data: data })
	}

	getData(){
		let horizont = this.props.horizont()
		if(horizont.id){
			this.setState({ loader: true })
			let tmp_ac_fake = this.props.ac().map(x=>{
				return x.id
			})
			get_query(`/chain/filter/horizon_id=${horizont.id}`, {}, true)
			.then((res) => {
				let data = res.data.filter( x => !x.is_deleted ).filter(x=>tmp_ac_fake.indexOf(x.ac2_id)>=0&&tmp_ac_fake.indexOf(x.ac1_id)>=0)
				this.setState({ loader: false, data: data })
			})
			.catch((err) => {
				this.setState({ loader: false, data: [] })
			})
		}
	}

	columns(){
		let cols = [{
			title: 'Начальная точка',
			dataIndex: 'ac1_id',
			width: 180,
			className: 'txt-center',
			render: (val, row, idx) => {
				return(
					<AcSelect
						loader={this.state.change_loader} 
						body={() => this.props.ac()}
						value={val}
						change={(v) => this.changeComair(idx, 'ac1_id', v)}
					/>
				)
			}
		},{
			title: 'Конечная точка',
			dataIndex: 'ac2_id',
			width: 180,
			className: 'txt-center',
			render: (val, row, idx) => {
				return(
					<AcSelect 
						loader={this.state.change_loader} 
						body={() => this.props.ac()}
						value={val}
						change={(v) => this.changeComair(idx, 'ac2_id', v)}
					/>
				)
			}
		},{
			title: 'Расстояние',
			dataIndex: 'distance',
			width: 180,
			className: 'txt-center',
			render: (val, row, idx) => {
				return(
					<DistanceField 
						loader={this.state.change_loader} 
						value={val}
						change={(v) => this.changeComair(idx, 'distance', v)}
					/>
				)
			}
		},{
			title: 'Опасная зона',
			dataIndex: 'is_danger',
			width: 180,
			className: 'txt-center',
			render: (val, row, idx) => {
				return(
					<AlertField 
						loader={this.state.change_loader} 
						value={Boolean(val)}
						change={(v) => this.changeComair(idx, 'is_danger', v)}
					/>
				)
			}
		},{
			title: 'Сохр.',
			dataIndex: 'unsaved',
			width: 40,
			className: 'txt-center',
			render: (val) => {
				return (
					<>
						{
							val ? (
								<Tooltip title='Не сохранено !!!'>
									<WarningOutlined 
										style={{
											color: 'orange'
										}}
									/>
								</Tooltip>
							) : false
						}
					</>
				)
			}
		},{
			title: 'Действия',
			dataIndex: 'id',
			width: 180,
			className: 'txt-center',
			render: (val, row, idx) => {
				return(
					<TblDistance 
						save={() => this.saveCompair(idx)}
						delete={() => this.deleteCompair(idx)}
					/>
				)
			}
		}]
		return cols
	}

	saveAction(){
		this.setState({ open: false })
	}

	render(){
		return(
			<>
				<Button
					onClick={() => this.setState({ open: true }, () => {
						this.getData()
					})}
				>
					Задать расстояния / Настроить опасные зоны
				</Button>
				<Modal
					title={
						<>
							<b>Задать расстояния / Настроить опасные зоны</b>&emsp;
							<Tooltip title="Создать">
								<FileAddOutlined 
									style={{
										color: 'green'
									}}
									onClick={() => this.addCompair()}
								/>
							</Tooltip> 
						</>
					}
					footer={[
						<Button 
							key="back" 
							onClick={() => this.setState({ 
								open: false, data: [] 
							}, () => {
								// перезапрос зон карты
								this.props.requery()
							})}
						>
							Закрыть
						</Button>
					]}
					visible={this.state.open} 
					onOk={() => this.saveAction()}
					onCancel={() => this.setState({ 
						open: false, data: [] 
					}, () => {
						// перезапрос зон карты
						this.props.requery()
					})}
					width={1000}
				>
					<div style={{ maxWidth: '100%', overflowX: 'auto' }}>
						<Table
							loading={this.state.loader}
							columns={this.columns()}
							dataSource={this.state.data}
							rowKey='id'
							pagination={false}
						/>
					</div>
				</Modal>
			</>
		)
	}
}


