import React from 'react'
import {Select, DatePicker, Button} from "antd";
import {get_query} from "../../functions";
import moment, {now} from 'moment';
import 'moment/locale/ru.js';
import locale from 'antd/es/locale/ru_RU.js';
import {FullLoader} from "../../containers";
moment.locale("ru_RU", {
	week: {
		dow: 1
	}
});
const {RangePicker} = DatePicker
const Option = Select
// Scheme Conf
const dateFormat = 'YYYY/MM/DD HH:mm';
export default class ReportsConf extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			objects: [],
			mines: [],
			horizonts: [],
			shifts:[],
			count_range: 0,
			loader: false,
			date_start: '',
			date_end: '',
			date_start_tmp: '',
			date_end_tmp: '',
			res_tmp: [],
			shift_start:'',
			shift_end:'',
			shift_id: '',
			worker_job_titles: [],
			all_workers: [],
			technic: [],
			worker_types: []
		}
	}
	componentDidMount() {
		this.getObjects()
		this.getAllWorkers()
		this.getMines()
		this.getDepartments()
		this.getHorizon()
		this.getShifts()
		this.getWorkersJobTitles()
		this.getTypes()
	}

	getWorkersJobTitles = () => {
		get_query('/workerjobtitle').then(res => {
			this.setState({'worker_job_titles': res.data})
		})
	}

	getTypes(){
		return new Promise((resolve, reject) => {
			get_query(`/workertype/`, {}, false)
				.then((res) => {
					if(res.data){
						this.setState({worker_types: res.data})
						let technic_tmp = res.data.filter(x=>x.is_vehicle == true)
						this.setState({technic:technic_tmp})
					}
					resolve(res.data)
				})
				.catch((err) => {
					// reject()
					resolve(false)
				})
		})
	}

	makeReport = () => {
		this.props.change('filteredInfo', {})
		this.props.change('tableFilters', {})
		this.props.change('currentPage', 1)
		if(this.props.report_type!=8&&this.props.report_type!=9) {
			this.getReportData(1, 10)
		}else{
			if(this.props.report_type==8){
				this.getNewReportData(1, 10000000)
			}else{
				this.getNewTabelReportData(1, 10000000)
			}
		}
	}

	getReportData=(page, pageSize, dateStart, dateEnd, filters1)=>{
		this.props.setLoader(true)
		let t=this
		return new Promise(function (resolve, reject){
		let date_start = t.state.date_start
		let date_start_visual = dateStart
		let date_end = t.state.date_end
		let date_end_visual = dateEnd
		if(date_start_visual) {
			t.props.change('date_start_visual', date_start_visual)
		}
		if(date_end_visual) {
			t.props.change('date_end_visual', date_end_visual)
		}
		t.props.change('date_start', date_start)
		t.props.change('date_end', date_end)
		if(date_start&&date_end) {
			let diff_in_minutes = (date_end - date_start) / (1000 * 60)
			let report_detail_time = t.props.report_detail
			let count_range = diff_in_minutes / report_detail_time
			t.props.change('count_range', count_range)
			t.props.change('date_start', date_start)
			let w_id_tmp = t.props.report_object.toString().split(',')
			if(filters1&&(filters1.worker_id!=null||filters1.fio!=null)){
				if(filters1.worker_id!=null&&filters1.fio!=null){
					if(filters1.worker_id.length>filters1.fio.length){
						w_id_tmp = filters1.fio.toString().split(',')
					}else{
						w_id_tmp = filters1.worker_id.toString().split(',')
					}
				}else if(filters1.worker_id!=null){
					w_id_tmp = filters1.worker_id.toString().split(',')
				}else if(filters1.fio!=null){
					w_id_tmp = filters1.fio.toString().split(',')
				}

			}
			let w_id = ''
			let count_tmp = 1
			w_id_tmp.map(x=>{
				if(count_tmp<w_id_tmp.length) {
					w_id += `'${x}', `
					count_tmp++
				}else{
					w_id += `'${x}'`
				}
			})
			let h_id = t.props.report_horizons.toString()
			let worker_type=''
			if(t.props.report_type<=4||t.props.report_type==8||t.props.report_type==9){
				let worker_type_tmp = ''
					t.state.worker_types.map(x=>{
						if(x.is_vehicle!=true) {
							worker_type_tmp = worker_type_tmp+x.id+','
						}
				})
				worker_type=worker_type_tmp.slice(0, -1);
			}else{
				if(t.props.report_type==5||t.props.report_type==6){
					let worker_type_tmp = ''
					t.state.worker_types.map(x=>{
						if(x.is_vehicle==true) {
							worker_type_tmp = worker_type_tmp+x.id+','
						}
					})
					worker_type=worker_type_tmp.slice(0, -1);
				}
			}
			let tmp_all_horizons = t.state.horizonts.map(x=>{
					return x.id
			})
			tmp_all_horizons.shift()
			get_query('/report', {
					'start_date': date_start_visual ? new Date(date_start_visual) : new Date(date_start),
					'end_date': date_end_visual ? new Date(date_end_visual) : new Date(date_end),
					'worker_id': w_id&&!w_id.includes('all')?`(${w_id})`:'',
					'worker_type_id': worker_type?`(${worker_type})`:'',
					'horizon': h_id&&!h_id.includes('all')?`(${h_id})`:tmp_all_horizons.toString(),
					'intervalByMin': t.props.report_detail,
					'page': page,
					'size': pageSize,
				}
				, true).then(
				(res) => {
					if(t.props.report_type!=3){
					let new_data = res.data.report
					let new_data_count = res.data.total_count
					let data_mass = []
					if (new_data) {
						for (let i = 0; i < new_data.length; i++) {
							let ac_tmp=''
							if(new_data[i].ac1_name=='fake'){
								ac_tmp=new_data[i].ac2_identifier
							}else if(new_data[i].ac2_name=='fake'){
								ac_tmp=new_data[i].ac1_identifier
							}else{
								ac_tmp=(new_data[i].chain_distance/2>new_data[i].distance)?new_data[i].ac1_identifier:new_data[i].ac2_identifier
							}
							data_mass.push({
								'rowkey': i,
								'worker_id': new_data[i].worker_id,
								'time': new_data[i].time_stamp,
								'fio': new_data[i].worker_name,
								'reader': ac_tmp,
								//'reader2': new_data[i].ac2_name,
								'chain': new_data[i].chain_id,
								'distance': new_data[i].distance,
								'horizon': new_data[i].horizon,
								'worker_type_id': new_data[i].worker_type_id
							})
						}
						t.props.change('data', data_mass)
						t.props.change('data_filter', data_mass)
						t.props.change('data_count', new_data_count)
					} else {
						t.props.change('data', [])
						t.props.change('data_filter', [])
						t.props.change('no_data', true)
					}
				}else{
						let w_online = res.data.report
						if(w_online) {
							t.setState({'res_tmp': w_online})
						}else {
							t.setState({'res_tmp':[]})
						}
						let t=this
						get_query('/worker', {}, true).then(
							(res_w)=>{
								let w_data = res_w.data.filter(el=>el.worker_type_id!==1)
								setTimeout(()=>{
									let data_mass=[]
									let w_offline = w_data.map(worker=>{
										if(t.state.res_tmp.find(el=>el.worker_id==worker.id)===undefined){
											data_mass.push({
												'worker_id':worker.id,
												'fio':worker.fio
											})
										}
									})
									if(data_mass.length>1){
										t.props.change('data', data_mass)
										t.props.change('data_filter', data_mass)
										t.props.change('data_count', data_mass.length)
									} else {
										t.props.change('data', [])
										t.props.change('data_filter', [])
										t.props.change('no_data', true)
									}
								}, 2000)
							}
						)
					}
					t.props.setLoader(false)
				}
			).then(()=> resolve("ok")).catch(()=>t.props.setLoader(false))
		}else {
			t.props.setLoader(false)
			alert ('Введите даты формирования отчета!')
		}
		})
	}

	getNewReportData=(page, pageSize, dateStart, dateEnd, filters1)=>{
		this.props.setLoader(true)
		let t=this
		return new Promise(function (resolve, reject){
			let date_start = t.state.date_start
			let date_end = t.state.date_end
			t.props.change('date_start', date_start)
			t.props.change('date_end', date_end)
			if(date_start&&date_end) {
				let w_id_tmp = t.props.report_object.toString().split(',')
				if(filters1&&(filters1.worker_id!=null||filters1.fio!=null)){
					if(filters1.worker_id!=null&&filters1.fio!=null){
						if(filters1.worker_id.length>filters1.fio.length){
							w_id_tmp = filters1.fio.toString().split(',')
						}else{
							w_id_tmp = filters1.worker_id.toString().split(',')
						}
					}else if(filters1.worker_id!=null){
						w_id_tmp = filters1.worker_id.toString().split(',')
					}else if(filters1.fio!=null){
						w_id_tmp = filters1.fio.toString().split(',')
					}

				}
				let w_id = ''
				let count_tmp = 1
				w_id_tmp.map(x=>{
					if(count_tmp<w_id_tmp.length) {
						w_id += `'${x}', `
						count_tmp++
					}else{
						w_id += `'${x}'`
					}
				})
				let h_id = t.props.report_horizons.toString()
				let tmp_all_horizons = t.state.horizonts.filter((x)=>x.mine_id==t.props.report_mine).map(x=>{
					return x.id
				})
				tmp_all_horizons.shift()
				let tmp_dep = ''
				let tmp_work = ''
				let depid = t.props.report_department+''
				if(depid.slice(0, 2)=='d-'){
					tmp_dep = t.props.report_department.split('-')[1]
				}else{
					tmp_work = t.props.report_department
				}

				get_query('/stat/horizon/'+t.props.report_mine, {
						'worker_id': w_id&&!w_id.includes('all')?`(${w_id})`:'',
						'horizon': h_id&&!h_id.includes('all')?`(${h_id})`:tmp_all_horizons.toString(),
						'page': page,
						'size': pageSize,
						'date': new Date(date_start),
						'shift': t.state.shift_id,
						'department': tmp_dep,
						'worker': tmp_work
					}
					, true).then(
					(res) => {
						if(t.props.report_type!=3){
							let new_data = res.data
							let new_data_count = res.data.length
							let data_mass = []
							if (new_data) {
								let i=1
								let tmp_horizons = []
								for (let key in new_data) {
									let worker_tmp = t.state.all_workers.find(x=>x.id==key)
									let job_title_tmp = t.state.worker_job_titles.find(x=>x.id==worker_tmp.job_title_id)
									let obj_tmp = {
										'rowkey': i,
										'worker_id': key,
										'fio': worker_tmp.fio,
										'jobtitle': job_title_tmp.name
									}
									for(let key2 in new_data[key]){
										obj_tmp[key2]='Да'
										if(!tmp_horizons.find(x=>x==key2)){
											tmp_horizons.push(key2)
										}
									}
									data_mass.push(
										obj_tmp
									)
									i++
								}
								t.props.change('data', data_mass)
								t.props.change('data_filter', data_mass)
								t.props.change('data_count', new_data_count)
								t.props.change('horizons_for_table', tmp_horizons)
								t.props.change('time_query', new Date())
							} else {
								t.props.change('data', [])
								t.props.change('data_filter', [])
								t.props.change('no_data', true)
								t.props.change('horizons_for_table', [])
								t.props.change('time_query', '')
							}
						}else{
							let w_online = res.data.report
							if(w_online) {
								t.setState({'res_tmp': w_online})
							}else {
								t.setState({'res_tmp':[]})
							}
							let t=this
							get_query('/worker', {}, true).then(
								(res_w)=>{
									let w_data = res_w.data.filter(el=>el.worker_type_id!==1)
									setTimeout(()=>{
										let data_mass=[]
										let w_offline = w_data.map(worker=>{
											if(t.state.res_tmp.find(el=>el.worker_id==worker.id)===undefined){
												data_mass.push({
													'worker_id':worker.id,
													'fio':worker.fio
												})
											}
										})
										if(data_mass.length>1){
											t.props.change('data', data_mass)
											t.props.change('data_filter', data_mass)
											t.props.change('data_count', data_mass.length)
										} else {
											t.props.change('data', [])
											t.props.change('data_filter', [])
											t.props.change('no_data', true)
										}
									}, 2000)
								}
							)
						}
						t.props.setLoader(false)
					}
				).then(()=> resolve("ok")).catch(()=>t.props.setLoader(false))
			}else {
				t.props.setLoader(false)
				alert ('Введите даты формирования отчета!')
			}
		})
	}

	getNewTabelReportData=(page, pageSize, dateStart, dateEnd, filters1)=>{
		this.props.setLoader(true)
		let t=this
		return new Promise(function (resolve, reject){
			let date_start = t.state.date_start
			let date_end = t.state.date_end
			t.props.change('date_start', date_start)
			t.props.change('date_end', date_end)
			if(date_start&&date_end) {
				let w_id_tmp = t.props.report_object.toString().split(',')
				if(filters1&&(filters1.worker_id!=null||filters1.fio!=null)){
					if(filters1.worker_id!=null&&filters1.fio!=null){
						if(filters1.worker_id.length>filters1.fio.length){
							w_id_tmp = filters1.fio.toString().split(',')
						}else{
							w_id_tmp = filters1.worker_id.toString().split(',')
						}
					}else if(filters1.worker_id!=null){
						w_id_tmp = filters1.worker_id.toString().split(',')
					}else if(filters1.fio!=null){
						w_id_tmp = filters1.fio.toString().split(',')
					}

				}
				let w_id = ''
				let count_tmp = 1
				w_id_tmp.map(x=>{
					if(count_tmp<w_id_tmp.length) {
						w_id += `'${x}', `
						count_tmp++
					}else{
						w_id += `'${x}'`
					}
				})
				let h_id = t.props.report_horizons.toString()
				let tmp_all_horizons = t.state.horizonts.filter((x)=>x.mine_id==t.props.report_mine).map(x=>{
					return x.id
				})
				tmp_all_horizons.shift()
				let tmp_dep = ''
				let tmp_work = ''
				let depid = t.props.report_department+''
				if(depid.slice(0, 2)=='d-'){
					tmp_dep = t.props.report_department.split('-')[1]
				}else{
					tmp_work = t.props.report_department
				}

				get_query('/stat/time/'+t.props.report_mine, {
						'worker_id': w_id&&!w_id.includes('all')?`(${w_id})`:'',
						'horizon': h_id&&!h_id.includes('all')?`(${h_id})`:tmp_all_horizons.toString(),
						'page': page,
						'date_start': new Date(date_start),
						'date_end': new Date(date_end),
						'department': tmp_dep,
						'worker': tmp_work,
						'size': pageSize
					}
					, true).then(
					(res) => {
						if(t.props.report_type!=3){
							let new_data = res.data
							let new_data_count = res.data.length
							let data_mass = []
							if (new_data) {
								let i=1
								let tmp_horizons = []
								for (let key in new_data) {
									let worker_tmp = t.state.all_workers.find(x=>x.id==key)
									let job_title_tmp = t.state.worker_job_titles.find(x=>x.id==worker_tmp.job_title_id)
									let obj_tmp = {
										'rowkey': i,
										'worker_id': key,
										'fio': worker_tmp.fio,
										'jobtitle': job_title_tmp.name,
										'count': Object.keys(new_data[key]).length
									}
									for(let key2 in new_data[key]){
										obj_tmp[key2]='Да'
										if(!tmp_horizons.find(x=>x==key2)){
											tmp_horizons.push(key2)
										}
									}
									data_mass.push(
										obj_tmp
									)
									i++
								}
								t.props.change('data', data_mass)
								t.props.change('data_filter', data_mass)
								t.props.change('data_count', new_data_count)
								t.props.change('horizons_for_table', tmp_horizons)
								t.props.change('time_query', new Date())
							} else {
								t.props.change('data', [])
								t.props.change('data_filter', [])
								t.props.change('no_data', true)
								t.props.change('horizons_for_table', [])
								t.props.change('time_query', '')
							}
						}else{
							let w_online = res.data.report
							if(w_online) {
								t.setState({'res_tmp': w_online})
							}else {
								t.setState({'res_tmp':[]})
							}
							let t=this
							get_query('/worker', {}, true).then(
								(res_w)=>{
									let w_data = res_w.data.filter(el=>el.worker_type_id!==1)
									setTimeout(()=>{
										let data_mass=[]
										let w_offline = w_data.map(worker=>{
											if(t.state.res_tmp.find(el=>el.worker_id==worker.id)===undefined){
												data_mass.push({
													'worker_id':worker.id,
													'fio':worker.fio
												})
											}
										})
										if(data_mass.length>1){
											t.props.change('data', data_mass)
											t.props.change('data_filter', data_mass)
											t.props.change('data_count', data_mass.length)
										} else {
											t.props.change('data', [])
											t.props.change('data_filter', [])
											t.props.change('no_data', true)
										}
									}, 2000)
								}
							)
						}
						t.props.setLoader(false)
					}
				).then(()=> resolve("ok")).catch(()=>t.props.setLoader(false))
			}else {
				t.props.setLoader(false)
				alert ('Введите даты формирования отчета!')
			}
		})
	}

	getDangerReportData=async ()=>{
		let worker_in_danger = []
		let res_h = await get_query('/horizon')
		let itmp = 1
				for (let i = 0; i < res_h.data.length; i++) {
					let res = await get_query(`/worker/filter/horizon_id=${res_h.data[i].id}`)
					let new_data = res.data.workers
					for (let i = 0; i < new_data.length; i++) {
						if (new_data[i].is_danger) {
							worker_in_danger.push({
								'rowkey': itmp,
								'worker_id': new_data[i].worker_id,
								'fio': new_data[i].fio,
								'reader': new_data[i].chain
							})
							itmp++
						}
					}
				}

			if (worker_in_danger.length>0) {

				this.props.change('data', worker_in_danger)
				this.props.change('data_filter', worker_in_danger)
				this.props.change('data_count', worker_in_danger.length)
			} else {
				this.props.change('data', [])
				this.props.change('data_filter', [])
				this.props.change('no_data', true)
			}
	}
	changeTime=(value, dateString)=>{
		if(value) {
			if(Array.isArray(value)) {
				value[0]._d.setSeconds(0, 0)
				value[1]._d.setSeconds(0, 0)
				this.setState({'date_start': value[0], 'date_end': value[1]})
			}else{
				this.setState({'date_start': value-60000, 'date_end':value+60000})
			}
		}else{
			this.setState({'date_start': '', 'date_end': ''})
		}
	}
	changeDate=(value)=>{
		if(value) {
			value._d.setHours(0, 0, 0, 0)
			this.setState({'date_start_tmp': value, 'date_end_tmp': value})
			if (this.state.shift_start) {
				this.setState({
					'date_start': value + this.state.shift_start,
					'date_end': value + this.state.shift_end
				})
			}
		}else{
			this.setState({'date_start': '', 'date_end': '', 'date_start_tmp':'','date_end_tmp':'',
			 'shift_start':'', 'shift_end':''})
		}
	}
	getObjects(){
		this.setState({ loader: true })
		get_query('/worker', {}, true)
			.then((res) => {
				let dat
				if(this.props.report_type==1||this.props.report_type==2){
					dat = res.data.filter((x)=>x.worker_type_id==2||x.worker_type_id==3)
				}else{
					dat = res.data.filter((x)=>x.worker_type_id==1)
				}
				dat.map(x=>{
					x.fio=x.fio+' ('+x.id+') '
				})
				dat.splice(0, 0, {id:'all', fio:'Все'})
				this.setState({ objects: dat, loader: false })
			})
			.catch((err) => {
				this.setState({ objects: [], loader: false })
			})
	}
	getAllWorkers(){
		this.setState({ loader: true })
		get_query('/worker', {}, true)
			.then((res) => {
				let dat
					dat = res.data
				dat.map(x=>{
					x.fio=x.fio+' ('+x.id+') '
				})
				this.setState({ all_workers: dat, loader: false })
			})
			.catch((err) => {
				this.setState({ all_workers: [], loader: false })
			})
	}
	getShifts(){
		this.setState({ loader: true })
		get_query('/shift', {}, true)
			.then((res) => {
				if(res.data!==null) {
					this.setState({shifts: res.data, loader: false})
				}else{
					this.setState({ shifts: [], loader: false })
				}
			})
			.catch((err) => {
				this.setState({ shifts: [], loader: false })
			})
	}
	getMines(){
		get_query('/mine', {}, true)
			.then((res) => {
				this.setState({ mines: res.data, loader: false })
				this.props.change('report_mine', res.data[0].id )
			})
			.catch((err) => {
				this.setState({ mines: [], loader: false })
			})
	}
	getHorizon(){
		get_query('/horizon', {}, true)
			.then((res) => {
				res.data.splice(0, 0, {id:'all', name:'Все'})
				this.setState({ horizonts: res.data, loader: false })
			})
			.catch((err) => {
				this.setState({ horizonts: [], loader: false })
			})
	}
	getDepartments(){
		get_query('/department', {}, true)
			.then((res) => {
				this.setState({ departments: res.data, loader: false })
			})
			.catch((err) => {
				this.setState({ departments: [], loader: false })
			})
	}
	render(){
		let reportType = this.props.report_type;
		return(
			<>
				<div className=''>
					<div className='div-flex-row' style={{ flex: 1, width:'100%' }}>
						<div>
							<div>
								Тип отчета
							</div>
							<Select
								style={{width: '190px'}}
								defaultValue={1}
								dropdownMatchSelectWidth={false}
								onChange={(v) => {
									this.getObjects()
									this.changeTime('')
									this.changeDate('')
									this.props.change('date_start', '')
									this.props.change('visual', false)
									this.props.change('data',[])
									this.props.change('data_filter',[])
									this.props.change('data_visual',[])
									this.props.change('range_val', 1)
									this.props.change('filteredInfo', {})
									this.props.change('no_data', false)
									if(v==3||v==4){
										this.props.change('report_detail', 60)
									}else {
										this.props.change('report_detail', 1)
									}
									this.props.change('report_type', v)}}
							>
								<Option key={1} value={1}>Перемещение конкретного человека за смену</Option>
								<Option key={2} value={2}>Перемещение конкретного человека за произвольный промежуток времени</Option>
{/*								<Option key={3} value={3}>Список и количество сотрудников в ламповой</Option>
								<Option key={4} value={4}>Список и количество сотрудников на смене</Option>*/}
								<Option key={5} value={5}>Перемещение транспорта за смену</Option>
								<Option key={6} value={6}>Перемещение транспорта за определенный промежуток времени</Option>
								<Option key={7} value={7}>Нахождение меток в опасных зонах в данный момент</Option>
								<Option key={8} value={8}>Отчет о нахождении персонала за смену</Option>
								<Option key={9} value={9}>Табельный отчет</Option>
							</Select>
						</div>
						{(reportType==8||reportType==9)?(
							<>
								<div style={{width:'15%'}}>
									<div>
										<div>
											Шахта
										</div>
										<Select
											key={'mine'+reportType}
											style={{width: '100%'}}
											defaultValue={this.state.mines&&this.state.mines[0].id}
											onChange={(v) => this.props.change('report_mine', v )}
										>
											{this.state.mines.map((mine, id)=>{
												return(
													<Option key={mine.id} value={mine.id}>{mine.name}</Option>
												)
											})
											}
										</Select>
									</div>
								</div>
								<div style={{width:'15%'}}>
									<div>
										<div>
											Горизонт
										</div>
										<Select
											mode={"multiple"}
											key={'horizon'+reportType}
											style={{width: '100%'}}
											defaultValue={'all'}
											onChange={(v) => this.props.change('report_horizons', v )}
										>
											{this.state.horizonts.map((horizon, id)=>{
												if(this.props.report_mine==horizon.mine_id||horizon.id=='all') {
													return (
														<Option key={horizon.id}
																value={horizon.id}>{horizon.name}</Option>
													)
												}
											})
											}
										</Select>
									</div>
								</div>
								{reportType==8?
									(<div>
										<div>
											Смена
										</div>
										<DatePicker
											mode='date'
											key={'date' + reportType}
											placeholder='Выбрать дату'
											locale={locale}
											//defaultValue={moment('00:00', 'HH:mm') }
											onChange={this.changeDate}
										/>
										<Select
											style={{width: 125}}
											key={'shift' + reportType}
											onChange={(v) => {
												let s = this.state.shifts.find(x => x.id == v)
												this.props.change('shift', [s.name, s.start_time, s.end_time, s.is_next_date])
												let startTime = s.start_time.split(':')
												startTime = startTime[0] * 60 * 60 * 1000 + startTime[1] * 60 * 1000
												let endTime = s.end_time.split(':')
												endTime = endTime[0] * 60 * 60 * 1000 + endTime[1] * 60 * 1000
												if (s.is_next_date) {
													endTime = endTime + 24 * 60 * 60 * 1000
												}
												this.setState({
													'date_start': this.state.date_start_tmp + startTime,
													'date_end': this.state.date_end_tmp + endTime,
													'shift_start': startTime,
													'shift_end': endTime, shift_id: s.id
												})
											}
											}
										>
											{this.state.shifts.map((object, id) => {
												return (
													<Select key={object.id} id={object.id}
															value={object.id}>{object.name}</Select>
												)
											})}
										</Select>
									</div>):(
										<div>
											<div>
												Период
											</div>
											<RangePicker
												key={'range'+reportType}
												format={dateFormat}
												locale={locale}
												placeholder={['Начало', 'Окончание']}
												defaultTime={'12:00'}
												onChange={this.changeTime}
											/>
										</div>
									)
								}
								<div style={{width:'15%'}}>
									<div>
										<div>
											Участок/отдел
										</div>
										<Select
											showSearch
											optionFilterProp="children"
											filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
											key={'department'+reportType}
											style={{width: '100%'}}
											defaultValue={''}
											onChange={(v) => this.props.change('report_department', v )}
											options={this.state.departments.map(x=>{x.value='d-'+x.id; x.label=x.name; return x}).concat(this.state.all_workers.map(x=>{x.value=x.id; x.label=x.fio; return x}))}
										/>
									</div>
								</div>
							</>
						):((reportType==3||reportType==4||reportType==7)?(
							<div>
							{(reportType==3||reportType==4)?(
								<div>
									<div>
										Дата
									</div>
									<DatePicker
												key={'date'+reportType}
												format={dateFormat}
												 locale={locale}
												 onChange={this.changeTime}
									/>
								</div>
							):(
								<div></div>
							)}
							</div>
							):(
								<div className='div-flex-row' style={{width:'100%'}}>
						<div>
							{(reportType==2||reportType==6)?(
								<div>
									<div>
										Период
									</div>
									<RangePicker
										key={'range'+reportType}
										format={dateFormat}
										locale={locale}
										placeholder={['Начало', 'Окончание']}
										defaultTime={'12:00'}
										onChange={this.changeTime}
									/>
								</div>):(
									<div>
										<div>
											Смена
										</div>
										<DatePicker
											mode='date'
											key={'date'+reportType}
											placeholder='Выбрать дату'
											locale={locale}
											//defaultValue={moment('00:00', 'HH:mm') }
											onChange={this.changeDate}
										/>
										<Select
											style={{width: 125}}
											key={'shift'+reportType}
											onChange={(v) =>{
													let s = this.state.shifts.find(x=>x.id==v)
													this.props.change('shift', [s.name, s.start_time, s.end_time, s.is_next_date])
													let startTime = s.start_time.split(':')
													startTime = startTime[0]*60*60*1000+startTime[1]*60*1000
													let endTime = s.end_time.split(':')
													endTime = endTime[0]*60*60*1000+endTime[1]*60*1000
													if(s.is_next_date){
														endTime=endTime+24*60*60*1000
													}
													this.setState({'date_start':this.state.date_start_tmp+startTime,
														'date_end':this.state.date_end_tmp+endTime,
														'shift_start':startTime,
														'shift_end':endTime})
												}
											}
										>
											{this.state.shifts.map((object, id)=> {
													return(
														<Select key={object.id} id={object.id} value={object.id}>{object.name}</Select>
													)
												})}
										</Select>
									</div>
							)
							}
						</div>
						<div style={{width:'20%'}}>
							<div>
								Объект
							</div>
							<Select
								mode={"multiple"}
								key={'object'+reportType}
								style={{width: '100%'}}
								defaultValue='all'
								showSearch
								filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
								onChange={(v) =>{
									this.props.change('report_object', v )
									this.props.change('data', [])
									this.props.change('data_filter', [])
									this.props.change('filteredInfo', {})
								}}
							>
								{this.state.objects.map((object, id)=> {
									return(
										<Option key={object.num} value={object.id}>{object.fio}</Option>
									)
								})
								}
							</Select>
						</div>
						<div style={{width:'20%'}}>
							<div>
								Горизонт
							</div>
							<Select
								mode={"multiple"}
								key={'horizon'+reportType}
								style={{width: '100%'}}
								defaultValue={'all'}
								onChange={(v) => this.props.change('report_horizons', v )}
							>
								{this.state.horizonts.map((horizon, id)=>{
									return(
										<Option key={horizon.id} value={horizon.id}>{horizon.name}</Option>
									)
								})
								}
							</Select>
						</div>
						<div>
							<div>
								Уровень детализации
							</div>
							<Select
								style={{width: '200px'}}
								defaultValue={1}
								onChange={(v) => this.props.change('report_detail', v)}
							>
								<Option key={1} value={1}>1 минута</Option>
								<Option key={2} value={2}>2 минуты</Option>
								<Option key={3} value={5}>5 минут</Option>
								<Option key={4} value={10}>10 минут</Option>
								<Option key={5} value={15}>15 минут</Option>
								<Option key={6} value={30}>30 минут</Option>
								<Option key={7} value={60}>1 час</Option>
							</Select>
						</div>
								</div>))}
					</div>
					<div style={{display:"flex", justifyContent:"end"}}>
						{this.props.report_type!=7?(
							<Button
								onClick={()=>{this.makeReport()}}
								style={{width: "200px"}}>Сформировать отчет</Button>
						):(
							<Button
								onClick={this.getDangerReportData}
								style={{width: "200px"}}>Сформировать отчет</Button>
						)
						}
					</div>
				</div>
			</>
		)
	}
}


