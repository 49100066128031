import React from 'react'
import {
	Button,
	Select, Table, DatePicker
} from 'antd'
import GeoPlane from './../../containers/modules/GeoPlane'
import ReportsConf from './ReportsConf'
import ClockComp from '../../containers/modules/ClockComp'
import { get_query } from '../../functions'
import jsPDF from "jspdf"
import "jspdf-autotable";
import "../../fonts/Roboto-Black-normal.js"
import autoTable from "jspdf-autotable";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import {GalkaImg, XlsImg} from "../../styles/icons";
import 'moment/locale/ru.js';
import {user_reducer} from "../../reducers/worker_reducer";
import store from "../../reducers/store";
import {del_memory, findMap, set_memory} from "../../functions/memory";

const { Option } = Select
const dateFormat = 'YYYY/MM/DD HH:mm';
// Reports page

export default class Reports extends React.Component {
	constructor(props){
		super(props) 
		this.state = {
			date_start: '',
			date_start_visual: '',
			date_end: '',
			date_end_visual: '',
			view_readers: true,
			view_workers: true,
			visual: false,
			loader: false,
			report_type: 1,
			report_object: 'all',
			report_mine: 0,
			report_horizons: 'all',
			report_detail: '1',
			report_department: '',
			count_range: 0,
			search_act: null,
			search_res: [],
			filteredInfo:{},
			data_filter:[],
			tableFilters: {},
			data: [],
			data_count: 0,
			data_visual:[],
			range_val:1,
			play_range:false,
			play_on:'',
			filter_time:'',
			no_data: false,
			shift: '',
			query_timeout: '',
			currentPage: 1,
			isExportLoading: false,
			report_visual_loading: false,
			counter_name: '',
			horizons_for_table: [],
			time_query: '',
			horizons: [],
			mines: [],
			objects: []
		}
		this.geoplane = React.createRef()
		this.confRef = React.createRef()
	}

	componentDidMount() {
		this.getData(true, true)
		this.getMines()
		this.getHorizon()
		this.getObjects()
	}

	getData(change, change_mine){
		this.setState({loader: true})
		Promise.all([
			get_query('/mine', {}, true).then((res) => {
				this.setState({
					mines: res.data
				}, () => {
					if (typeof change_mine === 'object') {
						// change.height = 600
						// change.width = 1000
						this.changeMine(change_mine)
					} else if (typeof change_mine === 'boolean' && change_mine) {
						var mine = findMap(res.data, 'mine_id')
						this.changeMine(mine ? mine : false)
						// this.changeMap(res.data[0] ? res.data[0] : false)
					}

				})
			}).catch((err)=>{
				console.log(err)
			}),
			get_query('/horizon', {}, true) // - вынес на уровень выше
				.then((res) => {
					this.setState({
						horizons_all: res.data,
						horizonts: res.data.filter(x => x.mine_id == this.state.current_mine.id),
					}, () => {
						if(window.location.search!=''){
							let tmp = window.location.search.split('=')
							let hor = this.state.horizons_all.find(x=>x.id==tmp[1])
							change = hor
						}
						if (typeof change === 'object') {
							// change.height = 600
							// change.width = 1000
							this.changeMap(change)
						} else if (typeof change === 'boolean' && change) {
							var map = findMap(res.data, 'map_id')
							this.changeMap(map ? map : false)
							// this.changeMap(res.data[0] ? res.data[0] : false)
						} else if (typeof change === 'boolean' && !change) {
							var map2 = res.data.filter(x => x.mine_id == this.state.current_mine.id)[0]
							this.changeMap(map2 ? map2 : false)
						}

					})
					this.setState({loader: false})
				})
				.catch((err) => {
					this.setState({
						horizonts: [],
						loader: false,
						current: false,
						change_mine: false
					})
				})
		])
	}

	changeMine(current_mine, refreshHorizon){
		if(current_mine){
			set_memory(current_mine.id, 'mine_id')
			this.setState({
				current_mine: current_mine
			},()=>{
				if(refreshHorizon){
					this.getData(false, current_mine)
				}
			})
		} else {
			del_memory('mine_id')
		}
	}

	changeMap(current){
		if(current){
			set_memory(current.id, 'map_id')
			this.setState({
				current: current,
				ac: [],
				worker: [],
				tmp_dots: [],
				equipment: [],
			}, () => {
				/*				let height = current.height
                                let width = current.width
                                this.imageOverlay.current.setBounds([
                                    [0,0],
                                    [height,width]
                                ])
                                this.goToCoords(parseInt(height/2), parseInt(width/2))

                                // получить считыватели
                                this.getAc(current)
                                this.getAlertLines(current)
                                this.getDo1(current)
                                this.getCounters(current)

                                // получить воркеров
                                this.clearWorkers()
                                this.getWorkers(this.state.current.id)
                                this.intervalWorkers(this.state.current.id)*/
			})
		} else {
			del_memory('map_id')
		}
	}


	getObjects(){
		this.setState({ loader: true })
		get_query('/worker', {}, true)
			.then((res) => {
				let dat
					dat = res.data
				dat.map(x=>{
					x.fio=x.fio+' ('+x.id+') '
				})
				this.setState({ objects: dat, loader: false })
			})
			.catch((err) => {
				this.setState({ objects: [], loader: false })
			})
	}

	getMines(){
		get_query('/mine', {}, true)
			.then((res) => {
				this.setState({ mines: res.data, loader: false })
			})
			.catch((err) => {
				this.setState({ mines: [], loader: false })
			})
	}

	getHorizon(){
		get_query('/horizon', {}, true)
			.then((res) => {
				this.setState({ horizons: res.data, loader: false })
			})
			.catch((err) => {
				this.setState({ horizons: [], loader: false })
			})
	}

	setCounterName=(res)=>{
		this.setState({counter_name: res})
	}
	changeDataVisual(val, set=false) {
		if(val>=this.state.count_range){
			this.stopDataVisual()
		}
		let filter_time=''
		if(set) {
			this.stopDataVisual()
			clearTimeout(this.state.query_timeout)
			this.setState({'range_val': val.target.valueAsNumber})
			filter_time = this.state.date_start + val.target.valueAsNumber * 60 * 1000 * this.state.report_detail
			this.state.query_timeout = setTimeout(
				() => {
					let time = this.state.date_start + val.target.valueAsNumber * 60 * 1000 * this.state.report_detail;
					if(time>this.state.date_end_visual||time<this.state.date_start_visual) {
						this.setState({'report_visual_loading':true})
						this.confRef.current.getReportData(1, 1000000, time, time + 36000000).then(()=>{
							this.setState({'report_visual_loading':false})
						});
					}
				}, 500)
		}else{
			filter_time = this.state.date_start + val * 60 * 1000 * this.state.report_detail
			if((this.state.date_end_visual - filter_time)<2400000){
				this.confRef.current.getReportData(1, 1000000, filter_time, filter_time+36000000);
			}
			this.setState({'range_val': val})
		}
		let res = this.state.data_filter.filter((x)=>{
			//let ddd = x.time.split('-')
			let rr = new Date(x.time).getTime()
			return	rr==filter_time
		}
		)
		res.map(x=>{
			let tmp_worker=this.state.objects.filter(y=>y.id==x.worker_id)[0]
			x.phone = tmp_worker.phone
			x.job_title_id = tmp_worker.job_title_id
			x.last_connect = filter_time
		})
		this.setState({'data_visual':res, 'filter_time':filter_time})
		if(res.length==1&&this.state.report_object!='all'&&this.state.report_object.length==1) {
				res[0].horizon_id = res[0].horizon
/*				this.setState({ search_act: res[0].worker_id })*/
				this.geoplane.current.findWorkerCoords(res[0])
		}else if(res.length>1) {
			this.setState({ search_act: null})
		}
	}
	playDataVisual(){
		let np=1
		this.setState({'play_range':true})
			this.state.play_on = setInterval(
				() => {
						this.changeDataVisual(this.state.range_val + np, false)
				}, 500
			)
	}
	stopDataVisual(){
		clearInterval(this.state.play_on)
		this.setState({'play_range':false})
	}
	isVisual = (vis) =>{
		if(vis==false){
			let time=this.state.date_start
			this.confRef.current.getReportData(1, 100000, time, time+3600000);
			this.setState({filter_time: time})
		}else{
			this.stopDataVisual()
			this.setState({'range_val':1, 'currentPage':1})
			this.confRef.current.getReportData(1, 10);
		}
		this.setState({visual:!vis})
	}

	exportToCSVtype8 = (fileName) => {
		this.setState({'isExportLoading': true})
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';
		let dataHeader =[{'Отчет':''}]
		let subheader = [{'':''}]
		let data2 = []
		let t = this
		let user_tmp = localStorage.getItem('username')
		let mine_tmp = this.state.mines.find(x=>x.id==this.state.report_mine)
		switch (this.state.report_type) {
			case 8:
				dataHeader = [{
					'"Отчет о нахождении персонала за смену"': '',
					'Шахта': mine_tmp.name,
					'Диспетчер': user_tmp,
					'Время': this.state.time_query.toLocaleString()
				}]
				for (let i = 0; i < this.state.data.length; i++) {
					let obj_tmp = {
						'№': this.state.data[i].rowkey,
						'Номер лампы': this.state.data[i].worker_id,
						'ФИО/Наименование транспорта': this.state.data[i].fio,
						'Участок/отдел': this.state.data[i].department
					}
					for (let col_tmp in this.state.horizons_for_table) {
						if (this.state.data[i][this.state.horizons_for_table[col_tmp]]) {
							let horizon_tmp_name = this.state.horizons.find(x => x.id == this.state.horizons_for_table[col_tmp])
							obj_tmp[horizon_tmp_name.name] = '+'
						}
					}
					data2.push(obj_tmp)
				}
				break
			case 9:
				dataHeader = [{
					'"Табельный отчет"': '',
					'Шахта': mine_tmp.name,
					'Диспетчер': user_tmp,
					'Время': this.state.time_query.toLocaleString()
				}]
				for (let i = 0; i < this.state.data.length; i++) {
					let obj_tmp = {
						'№': this.state.data[i].rowkey,
						'Номер лампы': this.state.data[i].worker_id,
						'ФИО/Наименование транспорта': this.state.data[i].fio,
						'Участок/отдел': this.state.data[i].department
					}
					for (let col_tmp in this.state.horizons_for_table) {
						if (this.state.data[i][this.state.horizons_for_table[col_tmp]]) {
							obj_tmp[this.state.horizons_for_table[col_tmp]] = '+'
						}
					}
					data2.push(obj_tmp)
				}
				break
			case 7:
				dataHeader = [{
					'"Отчет о нахождении в опасных зонах"': '',
					'Шахта': mine_tmp.name,
					'Диспетчер': user_tmp,
					'Время': this.state.time_query.toLocaleString()
				}]
				for (let i = 0; i < this.state.data.length; i++) {
					let obj_tmp = {
						'№': this.state.data[i].rowkey,
						'Номер лампы': this.state.data[i].worker_id,
						'ФИО/Наименование транспорта': this.state.data[i].fio,
						'Опасная зона': this.state.data[i].reader
					}
					data2.push(obj_tmp)
				}
		}
			/*			for(let i=0; i<this.state.data_filter.length; i++) {
                            data2.push({'Время':this.state.data_filter[i].time, 'Табельный номер':this.state.data_filter[i].worker_id, 'ФИО':this.state.data_filter[i].fio, 'ID считывателя':this.state.data_filter[i].reader})
                        }*/
		const ws = XLSX.utils.json_to_sheet(dataHeader);
		XLSX.utils.sheet_add_json(ws, data2, {origin: "A4"});
		const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
		const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
		const data = new Blob([excelBuffer], {type: fileType});
		FileSaver.saveAs(data, fileName + fileExtension);
		t.setState({'isExportLoading': false})
	}

	exportToCSV = (fileName) => {
		this.setState({'isExportLoading': true})
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
		const fileExtension = '.xlsx';
			let dataHeader =[{'Отчет':''}]
			let subheader = [{'':''}]
			let data2 = []

			let time=this.state.date_start
			let time_end=this.state.date_end
			let t = this
			getDataForReport(t, prepareAndExport)
			async function getDataForReport(t,callback) {
				let res = await t.confRef.current.getReportData(1, 10000, time, time_end, t.state.tableFilters);
				let tmp_data=[]
				tmp_data.push(...t.state.data)
				let counter = 10000;
				let tmp_page = 2
				while(t.state.data_count>counter){
					await t.confRef.current.getReportData(tmp_page, 10000, time, time_end, t.state.tableFilters);
					tmp_data.push(...t.state.data)
					tmp_page+=1
					counter+=10000
				}
				callback(t, tmp_data)
			}
		function prepareAndExport(t, data_tmp) {
			switch (t.state.report_type) {
				case 1:
					dataHeader = [{'Отчет "Перемещение конкретного человека за смену"': ''}]
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'Табельный номер': data_tmp[i].worker_id,
								'ФИО': data_tmp[i].fio,
								'ID считывателя': data_tmp[i].reader
							})
						}
					} else if (data_tmp.length > 0 && t.state.report_object.length == 1) {
						let person = 'ФИО: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						dataHeader = [{'Отчет "Перемещение конкретного человека за смену"': person}]
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'ID считывателя': data_tmp[i].reader
							})
						}
					}
					break
				case 2:
					dataHeader = [{'Отчет "Перемещение конкретного человека за промежуток времени"': ''}]
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'Табельный номер': data_tmp[i].worker_id,
								'ФИО': data_tmp[i].fio,
								'ID считывателя': data_tmp[i].reader
							})
						}
					} else if (data_tmp.length > 0 && t.state.report_object.length == 1) {
						let person = 'ФИО: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						dataHeader = [{'Отчет "Перемещение конкретного человека за промежуток времени"': person}]
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'ID считывателя': data_tmp[i].reader
							})
						}
					}
					break
				case 3:
					let person = 'Общее количество - ' + data_tmp.length
					dataHeader = [{'Отчет "Список и количество сотрудников в ламповой"': person}]
					for (let i = 0; i < data_tmp.length; i++) {
						data2.push({
							'Табельный номер': data_tmp[i].worker_id,
							'ФИО': data_tmp[i].fio
						})
					}
					break
				case 4:
					let person2 = 'Общее количество - ' + data_tmp.length
					dataHeader = [{'Отчет "Список и количество сотрудников на смене"': person2}]
					for (let i = 0; i < data_tmp.length; i++) {
						data2.push({
							'Табельный номер': data_tmp[i].worker_id,
							'ФИО': data_tmp[i].fio
						})
					}
					break
				case 5:
					dataHeader = [{'Отчет "Перемещение транспорта за смену"': ''}]
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'Номер': data_tmp[i].worker_id,
								'Наименование транспорта': data_tmp[i].fio,
								'ID считывателя': data_tmp[i].reader
							})
						}
					} else if (data_tmp.length > 0 && t.state.report_object.length == 1) {
						let person = 'Наименование: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						dataHeader = [{'Отчет "Перемещение транспорта за смену"': person}]
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'ID считывателя': data_tmp[i].reader
							})
						}
					}
					break
				case 6:
					dataHeader = [{'Отчет "Перемещение транспорта за определенный промежуток времени"': ''}]
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'Номер': data_tmp[i].worker_id,
								'Наименование транспорта': data_tmp[i].fio,
								'ID считывателя':data_tmp[i].reader
							})
						}
					} else if (data_tmp.length > 0 && t.state.report_object.length == 1) {
						let person = 'Наименование: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						dataHeader = [{'Отчет "Перемещение транспорта за определенный промежуток времени"': person}]
						for (let i = 0; i < data_tmp.length; i++) {
							data2.push({
								'Время': new Date(data_tmp[i].time).toLocaleString(),
								'ID считывателя': data_tmp[i].reader
							})
						}
					}
					break
				case 7:
					dataHeader = [{'"Отчет "Нахождение меток в опасных зонах в данный момент"': ''}]
					for (let i = 0; i < data_tmp.length; i++) {
						data2.push({
							'Табельный номер': data_tmp[i].worker_id,
							'ФИО/Наименование транспорта': data_tmp[i].fio,
							'ID опасной зоны': data_tmp[i].reader
						})
					}
					break
			}

			/*			for(let i=0; i<this.state.data_filter.length; i++) {
                            data2.push({'Время':this.state.data_filter[i].time, 'Табельный номер':this.state.data_filter[i].worker_id, 'ФИО':this.state.data_filter[i].fio, 'ID считывателя':this.state.data_filter[i].reader})
                        }*/
			const ws = XLSX.utils.json_to_sheet(dataHeader);
			XLSX.utils.sheet_add_json(ws, data2, {origin: "A4"});
			const wb = {Sheets: {'data': ws}, SheetNames: ['data']};
			const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
			const data = new Blob([excelBuffer], {type: fileType});
			FileSaver.saveAs(data, fileName + fileExtension);
			t.setState({'isExportLoading': false})
		}
	}
	clearFilter= () =>{
		this.setState({ filteredInfo: {} })
		this.setState({data_filter: this.state.data})
		this.setState({tableFilters: ''})
		this.confRef.current.getReportData(this.state.currentPage, 10);
		//очистить массив фильтров используемых для запроса!!!!
	}
	handleChange = (pagination, filters, sorter, extra) => {
		this.setState({filteredInfo: filters});
		this.setState({data_filter: extra.currentDataSource})
		if(filters.worker_id?.length==0||filters.worker_id==null){
			filters.worker_id=null
		}
		if(filters.fio?.length==0||filters.fio==null){
			filters.fio=null
		}
		this.confRef.current.getReportData(pagination.current, pagination.pageSize, null, null, filters);
		this.setState({tableFilters: filters})
		this.setState({currentPage: pagination.current})
	};
	handleData(page, pageSize){

	};
	exportPDF = () => {
		this.setState({'isExportLoading': true})
		const unit = "pt";

		const size = "A4"; // Use A1, A2, A3 or A4
		const orientation = "portrait"; // portrait or landscape

		const marginLeft = 40;
		const doc = new jsPDF(orientation, unit, size);

		doc.addFont('Roboto-Black-normal.ttf', 'Roboto-Black', 'normal')
		doc.setFont('Roboto-Black', 'normal')
		doc.setFontSize(15);

		let title = "Отчет";
		let subtitle = "";
		let headers = []
		let time=this.state.date_start
		let time_end=this.state.date_end
		let t = this
		getDataForReport(t, prepareAndExport)
		async function getDataForReport(t,callback) {
			let res = await t.confRef.current.getReportData(1, 10000, time, time_end, t.state.tableFilters);
			let tmp_data=[]
			tmp_data.push(...t.state.data)
			let counter = 10000;
			let tmp_page = 2
			while(t.state.data_count>counter){
				await t.confRef.current.getReportData(tmp_page, 10000, time, time_end, t.state.tableFilters);
				tmp_data.push(...t.state.data)
				tmp_page+=1
				counter+=10000
			}
			callback(t, tmp_data)
		}
		function prepareAndExport(t, data_tmp){
			let data_report = []
			switch (t.state.report_type) {
				case 1:
					title = title + ' Перемещение конкретного человека за смену'
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						headers = [["Время", "Табельный номер", "ФИО", "ID считывателя"]];
						data_report = t.state.data_filter.map(elt => [new Date(elt.time).toLocaleString(), elt.worker_id, elt.fio, elt.reader]);
					} else if (t.state.data_filter.length > 0 && t.state.report_object.length == 1) {
						headers = [["Время", "ID считывателя"]];
						subtitle = 'ФИО: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						data_report = data_tmp.map(elt => [new Date(elt.time).toLocaleString(), elt.reader]);
					}
					break
				case 2:
					title = title + ' Перемещение конкретного человека за промежуток времени'
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						headers = [["Время", "Табельный номер", "ФИО", "ID считывателя"]];
						data_report = data_tmp.map(elt => [new Date(elt.time).toLocaleString(), elt.worker_id, elt.fio, elt.reader]);
					} else if (data_tmp.length > 0 && t.state.report_object.length == 1) {
						headers = [["Время", "ID считывателя"]];
						subtitle = 'ФИО: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						data_report = data_tmp.map(elt => [new Date(elt.time).toLocaleString(), elt.reader]);
					}
					break
				case 3:
					title = title + ' Список и количество сотрудников в ламповой'
					headers = [["Табельный номер", "ФИО"]];
					subtitle = 'Общее количество - ' + data_tmp.length
					data_report = data_tmp.map(elt => [elt.worker_id, elt.fio]);
					break
				case 4:
					title = title + ' Список и количество сотрудников на смене'
					headers = [["Табельный номер", "ФИО"]];
					subtitle = 'Общее количество - ' + data_tmp.length
					data_report = data_tmp.map(elt => [elt.worker_id, elt.fio]);
					break
				case 5:
					title = title + ' Перемещение транспорта за смену'
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						headers = [["Время", "Номер", "Наименование транспорта", "ID считывателя"]];
						data_report = data_tmp.map(elt => [new Date(elt.time).toLocaleString(), elt.worker_id, elt.fio, elt.reader]);
					} else if (data_tmp.length > 0 && t.state.report_object.length == 1) {
						headers = [["Время", "ID считывателя"]];
						subtitle = 'Наименование: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						data_report = data_tmp.map(elt => [new Date(elt.time).toLocaleString(), elt.reader]);
					}
					break
				case 6:
					title = title + ' Перемещение транспорта за определенный промежуток времени'
					if (data_tmp.length > 0 && (t.state.report_object == 'all' || t.state.report_object == '' || t.state.report_object.length > 1)) {
						headers = [["Время", "Номер", "Наименование транспорта", "ID считывателя"]];
						data_report = data_tmp.map(elt => [new Date(elt.time).toLocaleString(), elt.worker_id, elt.fio, elt.reader]);
					} else if (data_tmp.length > 0 && t.state.report_object.length == 1) {
						headers = [["Время", "ID считывателя"]];
						subtitle = 'Наименование: ' + data_tmp[0].fio + ' (' + data_tmp[0].worker_id + ')'
						data_report = data_tmp.map(elt => [new Date(elt.time).toLocaleString(), elt.reader]);
					}
					break
				case 7:
					title = title + ' Нахождение меток в опасных зонах в данный момент'
					headers = [["Табельный номер", "ФИО/Наименование транспорта", "ID опасной зоны"]];
					data_report = data_tmp.map(elt => [elt.worker_id, elt.fio, elt.reader]);
					break
			}
			let content = {
				startY: 50,
				head: headers,
				body: data_report
			};

			doc.text(title, marginLeft, 40);
			doc.text(subtitle, marginLeft, 58);
			/*doc.autoTable(content);*/
			/*		doc.text(headers[0], marginLeft, 80)
                    for(let i=0; i<data1.length; i++){
                        doc.text(data[i].name, marginLeft, 40*i)
                    }*/
			autoTable(doc, {
				head: headers,
				body: data_report,
				styles: {font: "Roboto-Black"},
				startY: 65
			})
			doc.save("report.pdf")
			t.setState({'isExportLoading': false})
		}
	}
	setLoader=(loader)=>{
		this.setState({loader: loader})
	}

	render(){
		const is_visual=this.state.visual
		let id_filters=[]
		let name_filters=[]
		let reader_filters=[]
		for(let i=0; i<this.state.data_filter.length; i++){
			if(id_filters.find((index)=>index.text==this.state.data_filter[i].worker_id)==-1||id_filters.find((index)=>index.text==this.state.data_filter[i].worker_id)==undefined) {
				id_filters.push({text: this.state.data_filter[i].worker_id, value: this.state.data_filter[i].worker_id})
			}
			if(name_filters.find((index1)=>index1.text==this.state.data_filter[i].fio)==-1||name_filters.find((index1)=>index1.text==this.state.data_filter[i].fio)==undefined) {
				name_filters.push({text: this.state.data_filter[i].fio, value: this.state.data_filter[i].worker_id})
			}
			if(reader_filters.find((index2)=>index2.text==this.state.data_filter[i].reader)==-1||reader_filters.find((index2)=>index2.text==this.state.data_filter[i].reader)==undefined) {
				reader_filters.push({text: this.state.data_filter[i].reader, value: this.state.data_filter[i].reader})
			}
		}
		let col1={}
		let col2={}
		let col3={}
		let col4={}
		let col5={}
		if(this.state.report_type==1||this.state.report_type==2||this.state.report_type==5||this.state.report_type==6){
			col1={
				title: "Время",
				dataIndex: "time",
				render: (val) => {
					return val ? <p>{new Date(val).toLocaleString()}</p> : <p> - </p>
				}
			}
				col4={
					title: "ID считывателя",
					dataIndex: "reader",
/*					filters:reader_filters,
					filteredValue: this.state.filteredInfo.reader? this.state.filteredInfo.reader : null,
					onFilter: (value, record) => record.reader.toString()==value,
					filterSearch: true*/
				}
				if(this.state.report_object.length>1||this.state.report_object=='all'||this.state.report_object==''){
					col2={
						title: "Табельный номер",
						dataIndex: "worker_id",
						filters:id_filters,
						filteredValue: this.state.filteredInfo.worker_id? this.state.filteredInfo.worker_id : null,
						onFilter: (value, record) => record.worker_id.toString() == value,
						filterSearch: true
					}
					col3={
						title: "ФИО",
						dataIndex: "fio",
						filters:name_filters,
						filteredValue: this.state.filteredInfo.fio? this.state.filteredInfo.fio : null,
						onFilter: (value, record) => record.worker_id.toString() == value,
						filterSearch: true
					}
				}
		}
		if(this.state.report_type==3||this.state.report_type==4||this.state.report_type==7){
			col2={
				title: "Табельный номер",
				dataIndex: "worker_id",
				filters:id_filters,
				filteredValue: this.state.filteredInfo.worker_id? this.state.filteredInfo.worker_id : null,
				onFilter: (value, record) => record.worker_id.toString()==value,
				filterSearch: true
			}
			col3={
				title: "ФИО",
				dataIndex: "fio",
				filters:name_filters,
				filteredValue: this.state.filteredInfo.fio? this.state.filteredInfo.fio : null,
				onFilter: (value, record) => record.worker_id.toString() == value,
				filterSearch: true
			}
		}
		if(this.state.report_type==7){
			col1={
				title: '№',
				dataIndex: 'rowkey'
			}
			col4={
				title: "ID опасной зоны",
				dataIndex: "reader",
/*				filters:reader_filters,
				filteredValue: this.state.filteredInfo.reader? this.state.filteredInfo.reader : null,
				onFilter: (value, record) => record.reader.toString()==value,
				filterSearch: true*/
			}
		}
		let columns_tmp=[]
		if(this.state.report_type==8){
			col1={
				title: '№',
				dataIndex: 'rowkey'
			}
			col2={
				title: '№ лампы',
				dataIndex: 'worker_id',
			}
			col3={
				title: "ФИО",
				dataIndex: "fio",
				sorter: (a, b) => a.fio-b.fio
			}
			col4={
				title: "Должность",
				dataIndex: "jobtitle",
				sorter: (a, b) => a.jobtitle-b.jobtitle
			}
			columns_tmp=[col1, col2, col3, col4]
			for(let col_tmp in this.state.horizons_for_table){
					let horizon_tmp_name = this.state.horizons.find(x=>x.id==this.state.horizons_for_table[col_tmp])
					columns_tmp.push(
						{title: `${horizon_tmp_name.name}`,
						dataIndex: `${this.state.horizons_for_table[col_tmp]}`,
							render: (row) => (
								row=='Да'?<GalkaImg width='20px' height='20px' background-color='white'/>:''
						)}
				)
			}
		}
		if(this.state.report_type==9){
			col1={
				title: '№',
				dataIndex: 'rowkey'
			}
			col2={
				title: '№ лампы',
				dataIndex: 'worker_id',
			}
			col3={
				title: "ФИО",
				dataIndex: "fio",
				sorter: (a, b) => a.fio-b.fio
			}
			col4={
				title: "Должность",
				dataIndex: "jobtitle",
				sorter: (a, b) => a.jobtitle-b.jobtitle
			}
			col5={
				title: "Итого",
				dataIndex: "count"
			}
			columns_tmp=[col1, col2, col3, col4, col5]
			for(let col_tmp in this.state.horizons_for_table){
				columns_tmp.push(
					{title: `${this.state.horizons_for_table[col_tmp]}`,
						dataIndex: `${this.state.horizons_for_table[col_tmp]}`}
				)
			}
		}
		if(this.state.report_type!=8&&this.state.report_type!=9) {
			this.columns = [
				col1,
				col2,
				col3,
				col4
			]
		}else{
			this.columns = columns_tmp
		}
		let options = this.state.search_res.map((d, id) => <Option key={id} value={d.worker_id}>{d.fio}</Option>);
		return( 
			<>
				<div className='page-title' style={{display: 'flex', justifyContent: "space-between"}}>
					<p className='txt-title'>
						Отчеты
					</p>
					<div>
						{(this.state.report_type==8||this.state.report_type==9||this.state.report_type==7)&&<XlsImg style={{width: '50px', cursor:'pointer'}} onClick={(e) => this.exportToCSVtype8('Report')} />}
					</div>
				</div>
				<div>
					<ReportsConf
						report_type={this.state.report_type}
						report_object={this.state.report_object}
						report_mine={this.state.report_mine}
						report_horizons={this.state.report_horizons}
						report_detail={this.state.report_detail}
						report_department={this.state.report_department}
						setLoader={this.setLoader}
						change={(f, v) => {
							if(f=='report_type'){
								this.setState({'date_start':'', 'date_end':'', 'report_object': 'all',
									report_horizons: 'all' })
							}
							this.setState({ [f]: v })}}
						ref={this.confRef}
					/>
				</div>
				<div className='div-flex-row' style={{ backgroundColor: '#E5E5E5', width:'100%', position: 'absolute', left: '0', padding:'0 120px', height: 'calc(100% - 240px)' }}>
					<div className='div-reports-container' style={{width: '100%', margin: '0 2px 0 0'}}>
						{!is_visual?(
							<div>
								{this.state.isExportLoading||this.state.loader?(<div style={{display: 'flex', position: "fixed", background: 'gray', opacity:'0.8', zIndex: '100000', top: '0px', bottom: '0', left: '0', right: '0', justifyContent: "center", alignItems: 'center'}}><div style={{fontSize: '24px'}}>Идет формирование отчета, не закрывайте страницу до завершения загрузки</div></div>):('')}
							{this.state.data.length>0?(
								<div>
								<div>
									{this.state.report_object.length==1&&this.state.report_object!='all'?this.state.data[0].fio+' (№'+this.state.data[0].worker_id+')':''}
									{this.state.report_type==3||this.state.report_type==4?'Общее количество сотрудников: '+this.state.data.length:''}
								</div>
								{this.state.report_type!=8&&this.state.report_type!=9&&this.state.report_type!=7?(<Table
									columns={this.columns}
									dataSource={this.state.data}
									onChange={this.handleChange}
									pagination={{
										current: this.state.currentPage,
										position: ['bottomRight'],
										total: this.state.data_count,
									}}
								/>):(<Table
									columns={this.columns}
									dataSource={this.state.data}
									pagination={false}
									scroll={{
										y: 600,
									}}
								/>)
								}

							</div>):(
								<div>
									{this.state.no_data?'Отчет пуст':'Сформируйте отчет'}
								</div>
								)
							}</div>):(
								<>
									{this.state.report_visual_loading?<div className={'div-loader-visual'}>Загрузка данных</div>:''}
									<div className={'div-geo-container'} style={{minHeight:'400px', height: 'calc(100% - 140px)'}}>
										<GeoPlane
											ref={this.geoplane}
											view_readers={this.state.view_readers}
											view_workers={this.state.view_workers}
											workers_data={this.state.data_visual}
											interval={250}
											view_tmp_dots={this.state.view_tmp_dots}
											view_zones={true}
											view_alertzones={true}
											search_act={this.state.search_act}
											setCounterName={this.setCounterName}
											current={this.state.current}
											current_mine={this.state.current_mine}
											is_report={true}
										/>
									</div>
									<div className={'play-control'}>
										<input type={"range"} min={0} disabled={this.state.report_visual_loading} value={this.state.range_val} max={this.state.count_range} onChange={(val)=>this.changeDataVisual(val, true)}/>
										<DatePicker format={dateFormat} disabled style={{color:'black', backgroundColor:'white', opacity:'1'}} value={moment(this.state.filter_time)}></DatePicker>
										<div style={{width:'200px', display:'flex', justifyContent:'end'}}>
											{!this.state.play_range?(<Button type="primary"
																			 onClick={()=>this.playDataVisual()}
											>Воспроизвести</Button>):(
												<Button type="primary"
														onClick={()=>this.stopDataVisual()}
												>Остановить</Button>)
											}
										</div>
									</div>
								</>
						)
						}
					</div>
					{this.state.report_type!=8&&this.state.report_type!=9&&this.state.report_type!=7?(
						<div className='div-subconf' style={{margin: "0 0 0 0"}}>
							<Button onClick={() => this.clearFilter()} style={{width: "200px"}}>Сбросить
								фильтры</Button>
							<Button style={{width: '200px'}} onClick={() => this.exportPDF()}>Экспорт в PDF</Button>
							<Button style={{width: '200px'}} onClick={(e) => this.exportToCSV('Report')}>Экспорт в
								XLS</Button>
							<Button style={{width: '200px'}}
									disabled={this.state.data.length <= 0 || this.state.report_type == 3 || this.state.report_type == 4 || this.state.report_type == 7}
									title={this.state.report_type == 3 || this.state.report_type == 4 || this.state.report_type == 7 ? 'Для данного типа отчета визуализация не предусмотрена' : (this.state.data.length <= 0 ? 'Нет данных для визуализации' : 'Визуализация отчета')}
									onClick={() => this.isVisual(this.state.visual)}>{!is_visual ? 'Визуализировать' : 'К таблице'}</Button>
							<div id='div-subconf-time'>
								<ClockComp/>
							</div>
						</div>
					):('')
					}
				</div>
			</>
		)
	}
}


