import React from 'react'
import {
	Button,
	Input,
	Modal, Select,
	Spin, TreeSelect
} from 'antd'
import {
	get_query,
	put_query
} from '../../functions'



// new scheme
export default class NewCounter extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			filename: '',
			value: [],
			treeData: []
		}
	}

	componentDidMount() {
		this.getData()
	}

	saveMine(){
		this.setState({ save_loader: true })
		put_query('/counter', {
			name: this.state.filename,
			items: this.state.value
		}, true)
		.then((res) => {
			this.setState({ 
				save_loader: false,
				open: false,
				filename: ''
			}, () => {
				this.props.getData()
			})
		})
		.catch((err) => {
			this.setState({ save_loader: false })
		})
	}

	addListener(){
        document.addEventListener("keydown", this.listener);
    }

    removeListener(){
        document.removeEventListener("keydown", this.listener);
    }

    listener = (ev) => {
        if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            this.saveMine()
        }
    }

	onChange = (newValue) => {
		this.setState({value:newValue});
	};

	 getData = async () => {
		 let mines = await get_query('/mine')
		let horizons = await get_query('/horizon')
		let acs = await get_query('/ac')
		 let treeData_tmp = []
		 mines.data.map(mine=>{
			 let mine_horizons = horizons.data.filter(x => x.mine_id == mine.id)
			 let mine_tmp = {
				 title: mine.name,
				 value: 'mine'+mine.id,
				 key: 'mine'+mine.id,
				 checkable: false,
				 children: []
			 }
			 mine_horizons.map(horizon=> {
					let horizon_acs = acs.data.filter(x => x.horizon_id == horizon.id)
					let horizon_tmp = {
						title: horizon.name,
						value: -horizon.id,
						key: -horizon.id,
						children: []
					}
					horizon_acs.map(y=>{
						 y.title=y.identifier;
						 y.value=y.id;
						 y.key=y.id
						horizon_tmp.children.push(y)
					})
					mine_tmp.children.push(horizon_tmp)
				 }
			 )
			 treeData_tmp.push(mine_tmp)
		 })
		 this.setState({treeData:treeData_tmp})
	}
	render(){
		return(
			<>
				<Button
					style={{ width: '100%' }}
					type='primary'
					onClick={() => this.setState({
						open: true
					})}
				>
					Добавить счетчик
				</Button>
				<Modal
					title={<b>Добавить новый счетчик</b>}
					visible={this.state.open} 
					onCancel={() => {
						this.setState({ 
							open: false,
							filename: ''
						})
					}}
					footer={[
						<Button 
							key="back" 
							onClick={() => { 
								this.setState({ 
									open: false,
									filename: ''
								})
							}}
						>
							Отменить
						</Button>,
						<Button 
							key="submit" 
							type="primary" 
							loading={this.state.save_loader} 
							onClick={() => this.saveMine()}
						>
							Загрузить
						</Button>
					]}
					width={572}
				>
					<div className='dlg-img-body'>
						<p>
							<Input
								type='text'
								value={this.state.filename}
								onChange={(ev) => this.setState({ filename: ev.target.value })}
								placeholder='Название счетчика'
							/>
						</p>
						<p>
							<TreeSelect
									treeData= {this.state.treeData}
									value={this.state.value}
									onChange={this.onChange}
									treeCheckable={true}
									showCheckedStrategy= 'SHOW_PARENT'
									placeholder = 'Выберите точки'
									style={{width:'100%'}}
							/>
						</p>
					</div>
				</Modal>
			</>
		)
	}
}


