import { 
    createStore, 
    combineReducers 
} from 'redux'

import { user_reducer } from './user_reducer'
import {worker_reducer} from "./worker_reducer";
import { error_reducer } from './error_reducer';

const store = createStore(
    combineReducers({
        user_reducer, worker_reducer, error_reducer
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

// const store = createStore(
//     combineReducers({
//         user_reducer
//     })
// )

export default store