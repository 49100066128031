// queries
import axios from 'axios'
import store from './../reducers/store'
import React from 'react'
import { message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import * as user_act from './../actions/user_act'
import * as error_act from './../actions/error_act'
const { confirm } = Modal

// запрос GET
export function get_query(url, params, notice){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.get(`${process.env.REACT_APP_RADIUS_API}${url}`, {
            params: params,
            headers: {
                token: token
            }
        })
        .then((res) => {
            resolve(res.data)
            Modal.destroyAll();
            store.dispatch(error_act.setCount(0))
        })
        .catch((err) => {
            if(notice){ handle_errors(err) }
            reject(err)
        })
    })
}

export function get_query_info(url, params, notice){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.get(`${url}`, {
            params: params,
            headers: {
                token: token
            }
        })
            .then((res) => {
                resolve(res.data)
                Modal.destroyAll();
                store.dispatch(error_act.setCount(0))
            })
            .catch((err) => {
                if(notice){ handle_errors(err) }
                reject(err)
            })
    })
}

// Запуск модуля оповещений
export function get_query_alert(params){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.get(process.env.REACT_APP_RADIUS_ALERT, {
            params: params,
            headers: {
                token: token
            }
        })
        .then((res) => {
            resolve(res.data)
        })
        .catch((err) => {
            alert_errors(err)
            reject(err)
        })
    })
}

// запрос GET
export function delete_query(url, notice){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.delete(`${process.env.REACT_APP_RADIUS_API}${url}`, {
            headers: {
                token: token
            }
        })
        .then((res) => {
            resolve(res.data)
        })
        .catch((err) => {
            if(notice){ handle_errors(err) }
            reject(err)
        })
    })
}

// запрос POST
export function post_query(url, body, notice){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`, body, {
            headers: {
                token: token
            }
        })
        .then((res) => {
            resolve(res.data)
        })
        .catch((err) => {
            if(notice){ handle_errors(err) }
            reject(err)
        })
    })
}


export function post_query_admin(url, body, notice){
    return new Promise((resolve, reject) => {
        let token = localStorage.getItem('token2')
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`, body, {
            headers: {
                token: token
            }
        })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                if(notice){ handle_errors(err) }
                reject(err)
            })
    })
}

// запрос PUT
export function put_query(url, body, notice){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.put(`${process.env.REACT_APP_RADIUS_API}${url}`, body, {
            headers: {
                token: token
            }
        })
        .then((res) => {
            resolve(res.data)
        })
        .catch((err) => {
            if(notice){ handle_errors(err) }
            reject(err)
        })
    })
}

//  upload file new PUT
export function upload_file(url, file){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        let formData = new FormData()
        formData.append('image', file)
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`, 
        formData,
        {
            headers: {
                token: token
            }
        })
        .then((res) => {
            resolve(res.data)
        })
        .catch((err) => {
            handle_errors(err)
            reject(err)
        })
    })
}

//  upload icon new POST
export function upload_icon(url, id, file){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        let formData = new FormData()
        formData.append('file', file)
        formData.append('id', id)
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`,
            formData,
        {
            headers: {
                token: token
            }
        })
        .then((res) => {
            resolve(res.data)
        })
        .catch((err) => {
            handle_errors(err)
            reject(err)
        })
    })
}

//  put icon new POST
export function put_icon(url, file){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        let formData = new FormData()
        formData.append('file', file)
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`,
            formData,
            {
                headers: {
                    token: token
                }
            })
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                handle_errors(err)
                reject(err)
            })
    })
}

// handle errors
function handle_errors(err){
    let state = store.getState()
    let err_c = state.error_reducer.error_count
    store.dispatch(error_act.setCount(err_c+1))
        if(err.response){
            if(err.response.status === 401){
                store.dispatch(user_act.setClear())
                store.dispatch(user_act.setToken(false))
            } else {
                let err_message = err.response.data ? err.response.data : {}
                let view_mes = err.response.data.err_message ? err.response.data.err_message : 'Ошибка запроса'
                Modal.destroyAll();
                Modal.confirm({
                    title: 'Ошибка',
                    content: (<>
                                <div>{view_mes}</div>
                                <div>{list_err(err_message.errors)}</div>
                                Попыток: {err_c}
                                </>),
                    okText: 'Ok',
                    okType: 'danger',
                    cancelButtonProps: {
                        style: { display: 'none' }
                    },
                    onOk() {
                      console.log('OK');
                    },
                    onCancel() {
                      console.log('Cancel');
                    },
                  });
                /*confirm({
                    icon: <ExclamationCircleOutlined />,
                    content: (
                        <>
                        {state.error_reducer.error_count}
                            <h3>{ view_mes }</h3>
                            {list_err(err_message.errors)}
                        </>
                    ),
                    onOk() {
                        console.log('OK')
                    },
                    cancelButtonProps: {
                        style: { display: 'none' }
                    },
                    zIndex: 110000
                })*/
            }
        } else {
            Modal.destroyAll();
            Modal.confirm({
                title: 'Ошибка',
                content: (<>
                            <div>Server error</div>
                            Попыток: {err_c}
                            </>),
                okText: 'Ok',
                okType: 'danger',
                cancelButtonProps: {
                    style: { display: 'none' }
                },
                onOk() {
                  console.log('OK');
                },
                onCancel() {
                  console.log('Cancel');
                },
              });
            /*confirm({
                icon: <ExclamationCircleOutlined />,
                content: <>
                {state.error_reducer.error_count}
                            <h4>Server error</h4>
                        </>,
                onOk() {
                    console.log('OK '+err)
                },
                cancelButtonProps: {
                    style: { display: 'none' }
                },
                zIndex: 110000
            })*/
        }  
}

// вывод списка ошибок
function list_err(errors){
    if(errors && errors.length > 0){
        let content = []
        errors.map((e, id) => {
            content.push(
                <li key={id}>{ e.message }</li>
            )
        })
        return <ul>{ content }</ul>
    } else {
        return false
    }
}

// alert error
function alert_errors(err){
    if(err.response){
        switch(err.response.status){
            case 404:
                message.error('Модуль оповещений не найден по указанному пути !!!')
            break
            case 400:
                message.error('Отсутствует параметр path для запуска модуля оповещений !!!')
            break
            case 500:
                message.error('Ошибка запуска модуля оповещений !!!')
            break
            default:
                message.error('Общая ошибка запуска модуля оповещений !!!')
            break
        }
    } else {
        message.error('Модуль запуска оповещений недоступен !!!')
    }
}