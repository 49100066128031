export function setError(payload){
    return {
        type: 'SET_ERROR',
        payload: payload
    }
}
export function setCount(payload){
    return {
        type: 'SET_COUNT',
        payload: payload
    }
}
export function setClearError(payload){
    return {
        type: 'SET_CLEAR_ERROR',
        payload: payload
    }
}