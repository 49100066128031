// user reducer

import { 
    del_ls_token, 
    set_ls_token
} from "../functions"

const initState = {
    user: '',
    id: false,
    fio: false,
    login: false,
    is_admin: false,
    token: localStorage.getItem('radius_token'),
    path: null,
    acl: {}
}

// user reducer
export function user_reducer(state = initState, action){
    switch (action.type) {
        case 'SET_USER':
            return Object.assign({}, state, {
                id: action.payload.id,
                fio: action.payload.fio,
                login: action.payload.login,
                is_admin: action.payload.is_admin,
                acl: action.payload.acl
            })
        case 'SET_ID': 
            return Object.assign({}, state, {
                id: action.payload
            })
        case 'SET_FIO': 
            return Object.assign({}, state, {
                fio: action.payload
            })
        case 'SET_LOGIN': 
            return Object.assign({}, state, {
                login: action.payload
            })
        case 'SET_IS_ADMIN': 
            return Object.assign({}, state, {
                is_admin: action.payload
            })
        case 'SET_TOKEN': 
            if(!action.payload){
                del_ls_token()
            } else {
                set_ls_token(action.payload)
            }
            return Object.assign({}, state, {
                token: action.payload
            })
        case 'SET_PATH': 
            return Object.assign({}, state, {
                path: action.payload
            })
        case 'SET_INIT':
            return Object.assign({}, state, action.payload)
        case 'SET_CLEAR':
            return initState
        default: 
            return state
    }
}