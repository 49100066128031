import React from 'react'
import {
	Button,
	Select
} from 'antd'
import { fontsw } from './../../constants'
import { 
	get_body_font_size,
	set_body_font_size
} from '../../functions/ui'

const { Option } = Select


// Font switcher
export default class PropFontSize extends React.Component {

	render(){
		return(
			<div
				className='div-flex-row'
				style={{
					padding: '32px 24px 36px 24px'
				}}
			>
				<Select
					style={{ width: '100%' }}
					value={get_body_font_size()}
					onChange={(v) => {
						set_body_font_size(v)
						this.forceUpdate()
					}}
				>
					<Option value={8} key='f8'>8</Option>
					<Option value={10} key='f10'>10</Option>
					<Option value={12} key='f12'>12</Option>
					<Option value={14} key='f14'>14</Option>
					<Option value={16} key='f16'>16</Option>
					<Option value={18} key='f18'>18</Option>
					<Option value={20} key='f20'>20</Option>
					<Option value={22} key='f22'>22</Option>
					<Option value={24} key='f24'>24</Option>
					<Option value={26} key='f26'>26</Option>
					<Option value={28} key='f28'>28</Option>
					<Option value={30} key='f30'>30</Option>
					<Option value={32} key='f32'>32</Option>
					<Option value={34} key='f34'>34</Option>
					<Option value={36} key='f36'>36</Option>
					<Option value={38} key='f38'>38</Option>
					<Option value={40} key='f40'>40</Option>
				</Select>
				<Button
					type='primary'
					style={{
						marginLeft: 12,
						width: 167
					}}
				>
					Сохранить
				</Button>
			</div>
		)
	}
}


