import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import Appbar from './../../components/main/Appbar'


// PriavteAdmin route container for main routes
class PrivateAdminRoute extends React.Component {
	state = {
		isLoading: true
	}
	componentDidMount() {
		setTimeout(()=>{
		this.setState({isLoading : false})}, 1000)
	}

	render(){
		return(
			this.state.isLoading?(
				<>
					<Appbar />
					<div>
						<div className='container'>
							Loading data
						</div>
					</div>
				</>
			):(
			<Route
				{...this.props}
			>
				{
					this.props.token&&this.props.is_admin ? (
						<>
							<Appbar />
							<div className='container'>
								{this.props.children}
							</div>
						</>
					) : (
						<Redirect
							to={{
								pathname: "/login",
							}}
						/>
					)
				}
			</Route>)
		)
	}
}

function mapStateToProps(state){
	return{
		token: state.user_reducer.token,
		is_admin: state.user_reducer.is_admin
	}
}

export default connect(mapStateToProps)(PrivateAdminRoute)


