import React from 'react'
import { 
	Button,
	Table
} from 'antd'
import { 
	get_query,
	delete_query,
	put_query,
	post_query
} from '../../functions'
import { TableUserAction } from './../../containers/ui/tables'
import ActWorker from '../../containers/modules/ActWorker'



// workers
export default class Workers extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			row: false,
			data: [],
			data_filter: [],
			filteredInfo: {},
			job_titles: [],
			departments: [],
			mines: [],
			worker_types: []
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		Promise.all([
			this.getJobTitle(),
			this.getDepartments(),
			this.getMines(),
			this.getTypes()
			]
		).then(()=>console.log(this.state.job_titles))
		this.setState({ loader: true })
		get_query('/worker', {}, true)
		.then((res) => {
			if(res.data.length>0) {
				this.setState({data: res.data, loader: false})
				this.setState({data_filter: res.data, loader: false})
			}else{
				this.setState({ data: [], loader: false })
				this.setState({ data_filter: [], loader: false })
			}
		})
		.catch((err) => {
			console.log(err)
			this.setState({ data: [], loader: false })
			this.setState({ data_filter: [], loader: false })
		})
	}
	getJobTitle(){
		return new Promise((resolve, reject) => {
			get_query(`/workerjobtitle/`, {}, false)
				.then((res) => {
					if(res.data){
							this.setState({job_titles: res.data})
					}
					resolve(res.data)
				})
				.catch((err) => {
					// reject()
					resolve(false)
				})
		})
	}
	getTypes(){
		return new Promise((resolve, reject) => {
			get_query(`/workertype/`, {}, false)
				.then((res) => {
					if(res.data){
						this.setState({worker_types: res.data})
					}
					resolve(res.data)
				})
				.catch((err) => {
					// reject()
					resolve(false)
				})
		})
	}
	getDepartments(){
		return new Promise((resolve, reject) => {
			get_query(`/department/`, {}, false)
				.then((res) => {
					if(res.data){
						this.setState({departments: res.data})
					}
					resolve(res.data)
				})
				.catch((err) => {
					// reject()
					resolve(false)
				})
		})
	}
	getMines(){
		return new Promise((resolve, reject) => {
			get_query(`/mine/`, {}, false)
				.then((res) => {
					if(res.data){
						this.setState({mines: res.data})
					}
					resolve(res.data)
				})
				.catch((err) => {
					// reject()
					resolve(false)
				})
		})
	}
	clearFilter= () =>{
		this.setState({ filteredInfo: {} })
		this.setState({data_filter: this.state.data})
	}

	workerAction(row){
		this.setState({ save_loader: true })
		put_query('/worker', row, true)
		.then((res) => {
			this.setState({ open: false, row: false, save_loader:false })
			this.getData()
		})
		.catch((err) => {
			this.setState({ save_loader: false })
		})
	}

	updateAction(row){
		this.setState({ save_loader: true })
		post_query(`/worker/${this.state.row.num}`, row, true)
		.then((res) => {
			this.setState({ open: false, row: false, save_loader: false })
			this.getData()
		})
		.catch((err) => {
			this.setState({ save_loader: false })
		})
	}

	removeAction(num){
		this.setState({ loader: true })
		delete_query(`/worker/${num}`, true)
		.then((res) => {
			this.getData()
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}
	clearFilter= () =>{
		this.setState({ filteredInfo: {} })
		this.setState({data_filter: this.state.data})
	}
	handleChange = (pagination, filters, sorter, extra) => {
		this.setState({filteredInfo: filters});
		this.setState({data_filter:extra.currentDataSource})
	}
	exportWorkersJson=()=>{
		let workers_data= this.state.data
		let export_data=[]
		for(let i=0; i<workers_data.length; i++){

			export_data.push({
				'Mac':workers_data[i].mac?workers_data[i].mac.toString().toUpperCase():'',
				'BeaconType':workers_data[i].worker_type_id,
				'PersonalNumber':workers_data[i].id,
				'Name':workers_data[i].fio
			})
		}
		let exstring=JSON.stringify(export_data)
		let exportstring = new Blob([exstring], {type:'application/json'})
		let link = document.createElement('a');
		link.download = 'BeaconList.json';

		let blob = new Blob([exstring], {type: 'application/json'});

		link.href = URL.createObjectURL(blob);

		link.click();

		URL.revokeObjectURL(link.href);
/*		var fs = require('fs');
		fs.writeFile("thing.json", exportstring, function(err, result) {
			if(err) console.log('error', err);
		});*/
	}

/*	confCol(){
		let columns = [{
			title: 'Тип',
			dataIndex: 'worker_type_id',
			key: 'type',
			width: 180,
			className: 'txt-center',
			render: (row) => {
				console.log(row)
				let worker_type=''
				switch (row) {
					case 1:
						worker_type='Транспорт'
						break
					case 2:
						worker_type='Водитель'
						break
					case 3:
						worker_type='Сотрудник'
						break
				}
				return(
					<div>
						{worker_type}
					</div>
				)
			}
		},{
			title: 'MAC адрес',
			dataIndex: 'mac',
			key: 'mac',
			width: 180,
			className: 'txt-center'
		},{
			title: 'ФИО/Наименование',
			dataIndex: 'fio',
			key: 'worker',
			sorter: (a, b) => a.worker - b.worker,
		},{
			title: 'Табельный номер/Номер машины',
			dataIndex: 'id',
			key: 'id',
			width: 240,
			className: 'txt-center',
			filters:id_filters,
			filteredValue: this.state.filteredInfo.worker_id? this.state.filteredInfo.worker_id : null,
			onFilter: (value, record) => record.worker_id.startsWith(value),
			filterSearch: true
		},{
			title: 'Действия',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<TableUserAction 
						edit={() => this.setState({ 
							open: 'Редактировать сотрудника/транспорт',
							row: row
						})}
						remove={() => this.removeAction(row.id)}
					/>
				)
			}
		}]
		return columns
	}*/


	render(){
		let id_filters=[]
		let name_filters=[]
		let mac_filters=[]
		let worker_type_id_filters=[]
		for(let i=0; i<this.state.data_filter.length; i++){
			if(id_filters.find((index)=>index.text==this.state.data_filter[i].id)==-1||id_filters.find((index)=>index.text==this.state.data_filter[i].id)==undefined) {
				id_filters.push({text: this.state.data_filter[i].id, value: this.state.data_filter[i].id})
			}
			if(name_filters.find((index1)=>index1.text==this.state.data_filter[i].fio)==-1||name_filters.find((index1)=>index1.text==this.state.data_filter[i].fio)==undefined) {
				name_filters.push({text: this.state.data_filter[i].fio, value: this.state.data_filter[i].fio})
			}
			if(mac_filters.find((index2)=>index2.text==this.state.data_filter[i].mac)==-1||mac_filters.find((index2)=>index2.text==this.state.data_filter[i].mac)==undefined) {
				mac_filters.push({text: this.state.data_filter[i].mac, value: this.state.data_filter[i].mac})
			}
			if(worker_type_id_filters.find((index3)=>index3.value==this.state.data_filter[i].worker_type_id)==-1||worker_type_id_filters.find((index3)=>index3.value==this.state.data_filter[i].worker_type_id)==undefined) {
				let worker_type=''
				switch (this.state.data_filter[i].worker_type_id) {
					case 1:
						worker_type='Транспорт'
						break
					case 2:
						worker_type='Водитель'
						break
					case 3:
						worker_type='Сотрудник'
						break
				}
				worker_type_id_filters.push({text: worker_type, value: this.state.data_filter[i].worker_type_id})
			}
		}
		let columns = [{
			title: 'Тип',
			dataIndex: 'worker_type_id',
			width: 180,
			className: 'txt-center',
			sorter: (a, b) => a.worker_type_id-b.worker_type_id,
			filters:worker_type_id_filters,
			filteredValue: this.state.filteredInfo.worker_type_id? this.state.filteredInfo.worker_type_id : null,
			onFilter: (value, record) => record.worker_type_id.toString().startsWith(value),
			render: (row) => {
				let worker_type=''
				switch (row) {
					case 1:
						worker_type='Транспорт'
						break
					case 2:
						worker_type='Водитель'
						break
					case 3:
						worker_type='Сотрудник'
						break
				}
				return(
					<div>
						{worker_type}
					</div>
				)
			}
		},{
			title: 'MAC адрес',
			dataIndex: 'mac',
			width: 180,
			className: 'txt-center',
			filters:mac_filters,
			filteredValue: this.state.filteredInfo.mac? this.state.filteredInfo.mac : null,
			onFilter: (value, record) => record.mac&&record.mac.startsWith(value),
			filterSearch: true
		},{
			title: 'ФИО/Наименование',
			dataIndex: 'fio',
			defaultSortOrder: 'descend',
			sortDirections: ['ascend'],
			sorter: (a, b)=>a.fio>b.fio,
			filters:name_filters,
			filteredValue: this.state.filteredInfo.fio? this.state.filteredInfo.fio : null,
			onFilter: (value, record) => record.fio.startsWith(value),
			filterSearch: true
		},{
			title: 'Табельный номер/Номер машины',
			dataIndex: 'id',
			width: 240,
			className: 'txt-center',
			filterSearch: true,
			sorter: (a, b) => ((typeof b.id === "number") - (typeof a.id === "number")) || (a.id > b.id ? 1 : -1),
			filters:id_filters,
			filteredValue: this.state.filteredInfo.id? this.state.filteredInfo.id : null,
			onFilter: (value, record) => record.id.toString().startsWith(value)
		},{
			title: 'Действия',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<TableUserAction
						edit={() => this.setState({
							open: 'Редактировать сотрудника/транспорт',
							row: row
						})}
						remove={() => this.removeAction(row.num)}
					/>
				)
			}
		}]
		return(
			<>
				<div style={{display:"flex", justifyContent:"end"}}>
					<Button
						onClick={this.exportWorkersJson}
						style={{width: "242px"}}>Выгрузить список рабочих
					</Button>
				</div>
				<div className='div-flex-row ant-row-space-between settings-title'>
					<p>Сотрудники/Транспорт</p>
					<div className='div-flex-row' style={{flexDirection: 'column', justifyContent: 'center'}}>
						<Button
							onClick={()=>this.clearFilter()}
							style={{width: "242px"}}>Сбросить фильтры
						</Button>
					</div>
				</div>
				<div  className='settings-body'>
					<Table 
						columns={columns}
						dataSource={this.state.data}
						loading={this.state.loader} 
						pagination={false}
						rowKey='id'
						onChange={this.handleChange}
					/>
				</div>
				<div className='settings-btn'>
					<Button
						style={{ width: '100%' }}
						type='primary'
						onClick={() => this.setState({ 
							open: 'Добавить нового сотрудника/транспорт',
							row: false
						})}
					>
						Добавить сотрудника/транспорт
					</Button>
				</div>
				<ActWorker 
					save_loader={this.state.save_loader}
					open={Boolean(this.state.open)}
					title={this.state.open}
					row={this.state.row}
					close={() => this.setState({ open: false })}
					save={(v) => this.workerAction(v)}
					update={(v) => this.updateAction(v)}
					job_titles={this.state.job_titles}
					departments={this.state.departments}
					mines={this.state.mines}
					worker_types={this.state.worker_types}
				/>
			</>
		)
	}
}


