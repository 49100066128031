import React from 'react'
import { Select } from 'antd'

const { Option } = Select

// Alert field 
export default class AlertField extends React.Component {

	render(){
		return(
			<Select
				disabled={this.props.loader}
				value={this.props.value}
				onChange={(ev) => this.props.change(ev)}
			>
				<Option key={1} value={false}>Нет</Option>
				<Option key={2} value={true}>Да</Option>
			</Select>
		)
	}
}


