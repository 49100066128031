import React, {useEffect, useRef, useState} from 'react'
import {
	Button, Checkbox,
	Table
} from 'antd'
import ActUser from '../../containers/modules/ActUser';
import { TableUserAction } from './../../containers/ui/tables'
import { 
	get_query,
	delete_query,
	put_query,
	post_query
} from '../../functions'


const DropdownMineRights =(row)=>{
	let [mines_open, setMines_open] = useState(false)
	let [mines_arr, setMines_arr] = useState([])
	const rootEl = useRef(null);
	useEffect(()=>{setMines_arr(row.row.acl.mine)
		const onClick = e => rootEl.current.contains(e.target) || setMines_open(false);
		document.addEventListener('click', onClick);
		return () => document.removeEventListener('click', onClick);
	},[])

	function userMineAction(row){
		let r = {id:'', acl:{}, login:'', fio:'', email:'', is_admin:false, path:''}
			r.id = row.row.id
			r.login = row.row.login
			r.email = row.row.email
			r.is_admin = row.row.is_admin
			r.fio = row.row.fio
			r.acl.horizon = row.row.acl.horizon
			r.acl.mine=  mines_arr

		post_query(`/user/${r.id}`, r, true)
			.then((res)=>{
				row.getData()
				setMines_open(false)
			})
	}
	function selectMine(e){
		if(mines_arr&&mines_arr.includes(e.target.value)){
			let mines_tmp  = mines_arr.filter((id) => id !== e.target.value);
			setMines_arr(mines_tmp);
		}else {
			let mines_tmp = []
			if (mines_arr) {
				mines_tmp = [...mines_arr]
			}
			mines_tmp.push(e.target.value)
			setMines_arr(mines_tmp)
		}
	}
	return(
		<div ref={rootEl}>
			<div>
							<span onClick={()=>setMines_open(!mines_open) }role="img" aria-label="down" tabIndex="-1"
								  className="anticon anticon-down ant-dropdown-trigger table-act-icon"><svg
								viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em"
								fill="currentColor" aria-hidden="true"><path
								d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
			</div>
			{mines_open?(
				<div className='dropdown-div-menu'>
					{
						row.mines.map((mine) => {
							return (
								<div>
									<Checkbox id={mine.id} key={mine.id} value={mine.id}
									  checked={mines_arr&&mines_arr.includes(mine.id)?true:false}
									  onChange={selectMine}/> {mine.name}
								</div>
							)
						})
					}
					<button className='ant-btn ant-btn-primary' onClick={() => userMineAction(row)}>Сохранить</button>
				</div>):(<div></div>)
			}
		</div>
	)
}

const DropdownHorizonRights =(row)=>{
	let [mines_open, setMines_open] = useState(false)
	let [mines_arr, setMines_arr] = useState([])
	const rootEl = useRef(null);
	useEffect(()=>{setMines_arr(row.row.acl.horizon)
		const onClick = e => rootEl.current.contains(e.target) || setMines_open(false);
		document.addEventListener('click', onClick);
		return () => document.removeEventListener('click', onClick);
	},[])

	function userMineAction(row){
		let r = {id:'', acl:{}, login:'', fio:'', email:'', is_admin:false, path:''}
		r.id = row.row.id
		r.login = row.row.login
		r.email = row.row.email
		r.is_admin = row.row.is_admin
		r.fio = row.row.fio
		r.acl.horizon=mines_arr
		r.acl.mine=row.row.acl.mine
		post_query(`/user/${r.id}`, r, true)
			.then((res)=>{
				row.getData()
				setMines_open(false)
			})
	}
	function selectMine(e){
		if(mines_arr&&mines_arr.includes(e.target.value)){
			let mines_tmp  = mines_arr.filter((id) => id !== e.target.value);
			setMines_arr(mines_tmp);
		}else {
			let mines_tmp = []
			if (mines_arr) {
				mines_tmp = [...mines_arr]
			}
			mines_tmp.push(e.target.value)
			setMines_arr(mines_tmp)
		}
	}
	return(
		<div ref={rootEl}>
			<div>
							<span onClick={()=>setMines_open(!mines_open) }role="img" aria-label="down" tabIndex="-1"
								  className="anticon anticon-down ant-dropdown-trigger table-act-icon"><svg
								viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em"
								fill="currentColor" aria-hidden="true"><path
								d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
			</div>
			{mines_open?(
				<div className='dropdown-div-menu-horizon-wrap'>
					<div className='dropdown-div-menu dropdown-div-menu-horizon'>
						{
							row.mines.map((mine)=> {
								return (<div className='mine-item'>
									<h3>{mine.name}</h3>
									{
										row.horizons.filter(horizon=>horizon.mine_id==mine.id).map((horizon) => {
										return (
											<div>
												<Checkbox id={horizon.id} key={horizon.id} value={horizon.id}
														  checked={mines_arr && mines_arr.includes(horizon.id) ? true : false}
														  onChange={selectMine}/> {horizon.name}
											</div>
											)
										})
									}
								</div>)
								}
							)
						}
					</div>
					<button className='ant-btn ant-btn-primary' onClick={() => userMineAction(row)}>Сохранить</button>
				</div>):(<div></div>)
			}
		</div>
	)
}


// users
export default class Users extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			row: false,
			data: [],
			horizons:[],
			mines: [],
			mine: [],
			mines_open: false,
			horizons_open: false
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		get_query('/user', {}, true)
		.then((res) => {
			this.setState({ data: res.data, loader: false })
		})
		.catch((err) => {
			console.log(err)
			this.setState({ data: [], loader: false })
		})
		get_query('/mine', {}, true)
			.then((res) => {
				this.setState({mines: res.data})
			})
		get_query('/horizon', {}, true)
			.then((res) => {
				this.setState({horizons: res.data})
			})
	}

	confCol(){
		let columns = [{
			title: 'ФИО',
			dataIndex: 'fio',
			key: 'fio',
			sorter: (a, b) => a.user - b.user,
		},{
			title: 'Логин',
			dataIndex: 'login',
			key: 'login',
			sorter: (a, b) => a.login - b.login,
		},{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
			sorter: (a, b) => a.email - b.email,
		},{
			title: 'Администратор',
			dataIndex: 'is_admin',
			width: 240,
			className: 'txt-center',
			render: (val) => {
				return val ? <p>admin</p> : <p>no</p>
			}
		},{
			title: 'Действия',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<TableUserAction 
						edit={() => this.setState({ 
							open: 'Редактировать пользователя',
							row: row
						})}
						remove={() => this.removeAction(row.id)}
					/>
				)
			}
		},{
			title: 'Шахта',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<DropdownMineRights getData={()=>this.getData()} row={row} mines={this.state.mines}/>
				)
			}
		},{
			title: 'Горизонт',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<DropdownHorizonRights getData={()=>this.getData()} row={row} mines={this.state.mines} horizons={this.state.horizons}/>
				)
			}
		}]
		return columns
	}

	removeAction(id){
		this.setState({ loader: true })
		delete_query(`/user/${id}`, true)
		.then((res) => {
			this.getData()
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	userAction(row){
		this.setState({ save_loader: true })
		row = Object.assign({}, row)
		row.is_admin = row.is_admin.toString()
		if(row.id){
			post_query(`/user/${row.id}`, row, true)
			.then((res) => {
				this.setState({ open: false, row: false, save_loader: false })
				this.getData()
			})
			.catch((err) => {
				this.setState({ save_loader: false })
			})
		} else {
			put_query('/user', row, true)
			.then((res) => {
				this.setState({ open: false, row: false, save_loader:false })
				this.getData()
			})
			.catch((err) => {
				this.setState({ save_loader: false })
			})
		}
	}

	render(){
		return(
			<>
				<div className='settings-title'>
					<p>Пользователи</p>
				</div>
				<div  className='settings-body'>
					<Table 
						columns={this.confCol()}
						dataSource={this.state.data}
						loading={this.state.loader} 
						pagination={false}
						rowKey='id'	
					/>
				</div>
				<div className='settings-btn'>
					<Button
						style={{ width: '100%' }}
						type='primary'
						onClick={() => this.setState({ 
							open: 'Добавить нового пользователя',
							row: false
						})}
					>
						Добавить пользователя
					</Button>
				</div>
				<ActUser 
					save_loader={this.state.save_loader}
					open={Boolean(this.state.open)}
					title={this.state.open}
					row={this.state.row}
					close={() => this.setState({ open: false })}
					save={(v) => this.userAction(v)}
				/>
			</>
		)
	}
}


