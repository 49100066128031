export function setWorkers(payload){
    return {
        type: 'REFRESH_WORKERS',
        payload: payload
    }
}
export function setWorkersOnlineCount(payload){
    return {
        type: 'REFRESH_WORKERS_ONLINE',
        payload: payload
    }
}
export function setTransportOnlineCount(payload){
    return {
        type: 'REFRESH_TRANSPORT_ONLINE',
        payload: payload
    }
}
