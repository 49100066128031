import React from 'react'
import {
	Modal,
	Form, 
	Input,
	Switch,
	Button,
} from 'antd'


const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

const initState = {
	fio: '',
	login: '',
	email: '',
	password: '',
	is_admin: false
} 

// user dlg
export default class ActUser extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			fio: '',
			login: '',
			email: '',
			password: '',
			is_admin: false
		}
	}

	UNSAFE_componentWillReceiveProps(np){
		if(this.props.open !== np.open && np.open){
			if(np.row){
				this.setState(Object.assign({}, this.state, np.row))
			} else {
				this.setState(initState)
			}
		}
	}

	render(){
		return(
			<Modal
				title={<b>{this.props.title}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={[
					<Button 
						key="back" 
						onClick={() => this.props.close()}
					>
						Отменить
					</Button>,
					<Button 
						key="submit" 
						type="primary" 
						loading={this.props.save_loader} 
						onClick={() => this.props.save(this.state)}
					>
						Сохранить
					</Button>
				]}
				width={440}
			>
				<div className='modal-body'>
					<Form
						{...layout}
					>
						<Form.Item
							label="Ф.И.О."
						>
							<Input 
								value={this.state.fio}
								onChange={(ev) => this.setState({ fio: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Email"
						>
							<Input 
								value={this.state.email}
								onChange={(ev) => this.setState({ email: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Логин"
						>
							<Input 
								autoComplete="nope"
								value={this.state.login}
								onChange={(ev) => this.setState({ login: ev.target.value })}
							/>
						</Form.Item>
						{
							!this.state.id && (
								<Form.Item
									label="password"
								>
									<Input
										autoComplete="nope"
										type='password' 
										value={this.state.password}
										onChange={(ev) => this.setState({ password: ev.target.value })}
									/>
								</Form.Item>
							)
						}
						<Form.Item
							label="Администратор"
						>
							<Switch 
								checked={this.state.is_admin} 
								onChange={(ev) => this.setState({ is_admin: ev })} />
						</Form.Item>
					</Form>	
				</div>
			</Modal>
		)
	}
}


