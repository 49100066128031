import React from 'react'
import { Input } from 'antd'


//  distance field
export default class DistanceField extends React.Component {

	render(){
		return(
			<Input 
				disabled={this.props.loader}
				placeholder="Расстояние" 
				value={this.props.value}
				onChange={(ev) => this.props.change(ev.target.value)}
			/>
		)
	}
}


