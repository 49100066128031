import React from 'react'
import { withRouter } from 'react-router-dom'
import {
	Button,
	Input,
	Checkbox,
	Spin
} from 'antd'
import { CompanyImg } from '../../styles/icons/elem'
import { login_query } from '../../functions'


// Login form 
class Login extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			error: false,
			login: '', 
			password: '',
			remember: false
		}
	}

	authAction(){
		this.setState({ loader: true, error: false })
		login_query('/session/login', {
			login: this.state.login,
			password: this.state.password
		}) 
		.then((res) => {
			localStorage.setItem('username', res.user.fio)
			this.props.history.push('/')
		})
		.catch((err) => {
			this.setState({ loader: false, error: 'Ошибка авторизации' })
		})
	}

	handleKeypress = (e) => {
		if (e.keyCode === 13) {
			this.authAction()
		}
	};

	render(){
		return(
			<div className='login-tmpl'>
				<div className='login-form txt-center'>
					<p>
						<CompanyImg />
					</p>
					<div className='input-form'>
						<p className='txt-title'>Войти в систему</p>
						<p className='txt-alert'>{this.state.error}</p>
						<p>
							<Input
								placeholder='Введите email или id' 
								value={this.state.login}
								onChange={(ev) => this.setState({ login: ev.target.value })}
								onPressEnter={this.handleKeypress}
							/>
						</p>
						<p>
							<Input 
								placeholder='Введите пароль' 
								type='password'
								value={this.state.password}
								onChange={(ev) => this.setState({ password: ev.target.value })}
								onPressEnter={this.handleKeypress}
							/>
						</p>
						<p className='remember-me'>
							<Checkbox
								checked={this.state.remember}
								onChange={(ev) => this.setState({ remember: !this.state.remember })}
							>
								Запомнить меня
							</Checkbox>
						</p>
						<p>
							<Button
								type={this.state.loader ? false : 'primary'}
								onClick={() => this.authAction()}
							>
								{
									this.state.loader ? (
										<Spin />
									) : 'Войти'
								}
							</Button>
						</p>
					</div>
				</div>
			</div>
		)
	}
}


export default withRouter(Login)