import React from 'react'
import {
	Row, 
	Col
} from 'antd'
import PropsBlock from '../../containers/modules/PropsBlock'
import PropIcon from '../../containers/modules/PropIcon'
import PropFont from '../../containers/modules/PropFont'
import PropTime from "../../containers/modules/PropTime";
import PropLogo from "../../containers/modules/PropLogo";
import PropsAlert from '../../containers/modules/PropsAlert'
import PropEquipment from "../../containers/modules/PropEquipment";
import PropFontSize from "../../containers/modules/PropFontSize";

// Properties
export default class Properties extends React.Component {

	render(){
		return(
			<>
				<Row>
					<Col 
						span={8}
						style={{ 
							paddingRight: 30
						}}
					>
						<PropsBlock
							title='Логотип'
							style={{
								marginBottom: 30
							}}
							lock={false}
						>
							<PropLogo />
						</PropsBlock>
						<PropsBlock
							title='Иконки'
							style={{
								marginBottom: 30
							}}
							lock={false}
						>
							<PropIcon />
						</PropsBlock>
						<PropsBlock
							title='Иконки дополнительного оборудования'
							style={{
								marginBottom: 30
							}}
							lock={false}
						>
							<PropEquipment />
						</PropsBlock>
						<PropsBlock
							title='Время до вывода "оффлайн" в ламповую'
							lock={false}
						>
							<PropTime />
						</PropsBlock>
					</Col>
					<Col span={16}>
						<PropsBlock
							title='Кнопки аварийного оповещения'
							lock={true}
							style={{
								marginBottom: 30
							}}
						>
							<PropsAlert />
						</PropsBlock>
						<PropsBlock
							title='Выбор шрифта'
							lock={false}
							style={{
								marginBottom: 30
							}}
						>
							<PropFont />
						</PropsBlock>
						<PropsBlock
							title='Размер шрифта'
							lock={false}
							style={{
								marginBottom: 30
							}}
						>
							<PropFontSize />
						</PropsBlock>
					</Col>
				</Row>
			</>
		)
	}
}


