import moment from 'moment';
import React from 'react'
import {
	TimePicker,
	Modal,
	Form,
	Input,
	Switch,
	Button, Checkbox,
} from 'antd'


const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

const initState = {
	name: '',
	description: '',
	is_itr: false,
	is_driver: false,
	is_vehicle: 'false'
}

// user dlg
export default class ActType extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			name: '',
			description: '',
			is_itr: '',
			is_driver:'',
			is_vehicle: 'false'
		}
	}

	UNSAFE_componentWillReceiveProps(np){
		if(this.props.open !== np.open && np.open){
			if(np.row){
				this.setState(Object.assign({}, this.state, np.row))
			} else {
				this.setState(initState)
			}
		}
	}

	render(){
		return(
			<Modal
				title={<b>{this.props.title}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={[
					<Button 
						key="back" 
						onClick={() => this.props.close()}
					>
						Отменить
					</Button>,
					<Button 
						key="submit" 
						type="primary" 
						loading={this.props.save_loader} 
						onClick={() => this.props.save(this.state)}
					>
						Сохранить
					</Button>
				]}
				width={640}
			>
				<div className='modal-body'>
					<Form
						{...layout}
					>
						<Form.Item
							label="Название"
						>
							<Input 
								value={this.state.name}
								onChange={(ev) => this.setState({ name: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Описание"
						>
							<Input
								value={this.state.description}
								onChange={(ev) => this.setState({ description: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Водитель"
						>
							<Checkbox
								checked={this.state.is_driver}
								onChange={(ev) => this.setState({ is_driver: ev.target.checked })}
							/>
						</Form.Item>
						<Form.Item
							label="Техника"
						>
							<Checkbox
								checked={this.state.is_vehicle=="true"}
								onChange={(ev) => this.setState({ is_vehicle: `${ev.target.checked}` })}
							/>
						</Form.Item>
						<Form.Item
							label="ИТР"
						>
							<Checkbox
								checked={this.state.is_itr}
								onChange={(ev) => this.setState({ is_itr: ev.target.checked })}
							/>
						</Form.Item>
					</Form>	
				</div>
			</Modal>
		)
	}
}


