import {Spin} from "antd";
import React from "react";

export default function IconShow(props){
    return(
        <>
            {
                props.loader ? (
                    <Spin />
                ) : (
                    <>
                        {
                            props.img ? (
                                <img src={props.img} style={{ width: 24, height: 24 }} />
                            ) : (
                                <>{props.default}</>
                            )
                        }
                    </>
                )

            }
        </>
    )
}