import React from 'react'
import {
	Modal,
	Form,
	Input,
	Button,
	message, Select, Checkbox
} from 'antd'
const Option = Select

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

const initState = {
	worker_type_id: 1,
	department_id: '',
	job_title_id: '',
	id: '',
	fio: '',
	mac: '',
	experience: '',
	phone: '',
	mine_id: '',
	lamp_number: ''
}

// user dlg
export default class ActWorker extends React.Component {
	constructor(props){
		super(props)
		this.state = {
		}
	}

	UNSAFE_componentWillReceiveProps(np){
		if(this.props.open !== np.open && np.open){
			if(np.row){
				this.setState(np.row)
			} else {
				this.setState(initState)
			}
		}
	}

	confirmAction(){
		if(this.state.id){
			let dt = Object.assign({}, this.state)
			dt.id = dt.id
			switch(this.props.title){
				case 'Редактировать сотрудника/транспорт':
					this.props.update(dt)
				break
				case 'Добавить нового сотрудника/транспорт':
					dt.num=undefined
					this.props.save(dt)
				break
				default:
					message.error('Действие не определено')
				break
			}
		} else {
			message.error('Не введен ID сотрудника')
		}
	}

	render(){
		return(
			<Modal
				title={<b>{this.props.title}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={[
					<Button 
						key="back" 
						onClick={() => this.props.close()}
					>
						Отменить
					</Button>,
					<Button 
						key="submit" 
						type="primary" 
						loading={this.props.save_loader} 
						onClick={() => this.confirmAction()}
					>
						Сохранить
					</Button>
				]}
				width={440}
			>
				<div className='modal-body'>
					<Form
						{...layout}
					>
						<Form.Item
							label="Шахта"
						>
							<Select
								type='number'
								value={this.state.mine_id}
								onSelect={(ev) => this.setState({ mine_id: ev })}
							>
								{this.props.mines.map(x=>{
									return <Option id={x.id} value={x.id}>{x.name}</Option>
								})}
							</Select>
						</Form.Item>
						<Form.Item
							label="Категория"
						>
							<Select
								type='number'
								value={this.state.worker_type_id}
								onSelect={(ev) => this.setState({ worker_type_id: ev })}
							>
								{this.props.worker_types.map(x=>{
									return <Option id={x.id} value={x.id}>{x.name}</Option>
								})}
							</Select>
						</Form.Item>
						<Form.Item
							label="Участок/Отдел"
						>
							<Select
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
								value={this.state.department_id}
								options={this.props.departments.map(x=>{x.value = x.id; x.label = x.name; return x})}
								onSelect={(ev) => this.setState({ department_id: ev })}
							/>
						</Form.Item>
						<Form.Item
							label="Должность"
						>
							<Select
								showSearch
								optionFilterProp="children"
								filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
								value={this.state.job_title_id}
								onSelect={(ev) => this.setState({ job_title_id: ev })}
								options={this.props.job_titles.map(x=>{x.value = x.id; x.label = x.name; return x})}
							/>
						</Form.Item>
						<Form.Item
							label="Номер лампы"
						>
							<Input
								value={this.state.lamp_number}
								onChange={(ev) => this.setState({ lamp_number: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label={this.state.worker_type_id==1?"Номер машины":"Табельный номер"}
						>
							<Input
								value={this.state.id}
								onChange={(ev) => this.setState({ id: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label={this.state.worker_type_id==1?"Наименование":"Ф.И.О."}
						>
							<Input 
								value={this.state.fio}
								onChange={(ev) => this.setState({ fio: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="MAC адрес"
						>
							<Input 
								value={this.state.mac}
								onChange={(ev) => {
									if(ev.target.value.length <= 17){
										let val = ev.target.value.toLowerCase().replace(/-/g, '')
										if(val.length>12){
											alert('Проверьте длину MAC адреса')
										}
										this.setState({ mac: val})
									}
								}}
							/>
						</Form.Item>
						<Form.Item
							label="Стаж"
						>
							<Input
								value={this.state.experience}
								onChange={(ev) => this.setState({ experience: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Телефон"
						>
							<Input
								value={this.state.phone}
								onChange={(ev) => this.setState({ phone: ev.target.value })}
							/>
						</Form.Item>
					</Form>	
				</div>
			</Modal>
		)
	}
}


