import React from 'react'
import {
	Modal,
	Button,
	Form,
	Input
} from 'antd'
import { Confirm } from './../../containers/modules/elems'

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}


// reader work modal
export default class DoModal extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}
	save(){
				this.props.save(this.state)
	}
	UNSAFE_componentWillReceiveProps(np){
		let open = Boolean(np.open)
		let old_open = Boolean(this.props.open)
		if(open && open !== old_open){
			this.addListener()
			this.setState(Object.assign({}, this.state, np.open))
			if(np.open.url===null){
				this.setState({url:'http://'})
			}
		}
		if(!open && open !== old_open){
			this.removeListener()
		}
	}

	addListener(){
        document.addEventListener("keydown", this.listener);
    }

    removeListener(){
        document.removeEventListener("keydown", this.listener);
    }

    listener = (ev) => {
        if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            this.save()
        }
    }

	render(){
		return(
			<Modal
				title={<b>Дополнительное оборудование ID - {this.state.identifier}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={[
					<Confirm
						key='confirm-key'
						title='Вы действительно хотите удалить дополнительное оборудование?'
						content=' '
						action={() => this.props.delete(this.state.id)}
					>
						<Button 
							loading={this.props.loader}
							key="back-key" 
						>
							Удалить
						</Button>
					</Confirm>,
					<Button 
						key="submit-key" 
						type="primary" 
						loading={this.props.loader}
						onClick={() => this.save()}
					>
						Сохранить
					</Button>
				]}
				width={440}
			>
				<div className='modal-body'>
					<Form
						{...layout}
					>
						<Form.Item
							label="ID устройства"
						>
							<Input
								value={this.state.identifier}
								onChange={(ev) => this.setState({ identifier: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Наименование"
						>
							<Input
								value={this.state.name}
								onChange={(ev) => this.setState({ name: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Место расп-ния"
						>
							<Input 
								value={this.state.place}
								onChange={(ev) => this.setState({ place: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="URL"
						>
							<Input
								value={this.state.url}
								onChange={(ev) => this.setState({ url: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Координата X"
						>
							<Input 
								disabled
								value={this.state.coord_x}
							/>
						</Form.Item>
						<Form.Item
							label="Координата Y"
						>
							<Input 
								disabled
								value={this.state.coord_y}
							/>
						</Form.Item>
					</Form>
				</div>
			</Modal>
		)
	}
}


