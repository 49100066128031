import React from 'react'
import {
	Select
} from 'antd'

const { Option } = Select

// Ac select field
export default class AcSelect extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	render(){
		let body = this.props.body()
		return(
			<Select
				disabled={this.props.loader}
				style={{ width: 180 }}
				value={this.props.value}
				onChange={(ev) => this.props.change(ev)}
			>
				{
					body.map((b, id) => {
						return(
							<Option key={id} value={b.id}>{b.identifier?b.identifier:' - '}</Option>
						)
					})
				}
			</Select>
		)
	}
}


