// ui functions
import moment from 'moment'


// hide pswd
export function pswd_stars(pswd){
    let res = ''
    let pl = pswd.length
    for(let i = 1; i <= pl; i++){
        res += '*'
    }
    return res
}

// get font
export function get_body_font(){
    let elem = document.getElementsByTagName('body')
    if(elem[0]){
        let font = elem[0].getAttribute('font')
        return font
    } else {
        return 'Roboto'
    }
}
// set font
export function set_body_font(val){
    let elem = document.getElementsByTagName('body')
    if(elem[0]){
        elem[0].setAttribute('font', val)
        document.documentElement.style.setProperty('--fontfamily', val)
    } else {

    }
}

// get font
export function get_body_font_size(){
    let elem = document.getElementsByTagName('body')
    if(elem[0]){
        let font = elem[0].getAttribute('font-size')
        return font
    } else {
        return 14
    }
}
// set font
export function set_body_font_size(val){
    let elem = document.getElementsByTagName('body')
    if(elem[0]){
        elem[0].classList.value='font-size-'+val
    } else {

    }
}

// selected path
export function selected_item(path, target){
    if(path === target){
        return true
    } else {
        return false
    }
}

export function number_time(){
    let nt = moment().format('HH:mm')
    let elem = document.getElementById('clock-time')
    if(elem){
        elem.innerHTML=nt
    }
}

export function analog_time(){
    let nt = moment()
    // int time values
    let hh = nt.hours()
    let mm = nt.minutes()
    let ss = nt.seconds()
    // calc
    let t_min = 6* (mm + (1/60) * ss) //Определяем угол для минут
    var t_hour = 30*(hh + (1/60) * mm)  //Определяем угол для часов
    // set rotate
    let hh_elem = document.getElementById('hour-line')
    let mm_elem = document.getElementById('minute-line')
    if(hh_elem){
        hh_elem.setAttribute('transform', `rotate(${t_hour}, 72, 72)`)
    }
    if(mm_elem){
        mm_elem.setAttribute('transform', `rotate(${t_min}, 72, 72)`)
    }
}

// reconf workers
export function unique_chains(workers){
    let res = workers.map(item => item.chain)
        .filter((value, index, self) => self.indexOf(value) === index)
    return res
}

// check chains
export function check_chains(idx, data){
    let status = true
    let msg = false
    let current = data[idx]
    if(!current.ac1_id && current.ac1_id !== 0){
        return {
            status: false,
            msg: 'Не установлена начальная точка !!!'
        }
    }
    if(!current.ac2_id && current.ac2_id !== 0){
        return {
            status: false,
            msg: 'Не установлена конечная точка !!!'
        }
    }
    if(current.ac1_id === current.ac2_id){
        return {
            status: false,
            msg: 'Запрет на установку расстояния от точки к самой себе !!!'
        }
    }
    if(!current.distance){
        return {
            status: false,
            msg: 'Не указано расстояние между точками !!!'
        }
    }
    let ac_1_content = data.map( d => d.ac1_id )
    let ac_2_content = data.map( d => d.ac2_id )
    let ac_content = ac_1_content.concat(ac_2_content)
    if(ac_content.filter( c => c == current.ac1_id).length > 4){
        return {
            status: false,
            msg: `Точка №${current.ac1_id} связана с более чем 4-мя друими точками !!!`
        }
    }
    if(ac_content.filter( c => c == current.ac2_id).length > 4){
        return {
            status: false,
            msg: `Точка №${current.ac2_id} связана с более чем 4-мя друими точками !!!`
        }
    }
    let chain_check1 = data.filter( x => x.ac1_id === current.ac1_id && x.ac2_id === current.ac2_id && !x.unsaved)
    let chain_check2 = data.filter( x => x.ac1_id === current.ac2_id && x.ac2_id === current.ac1_id && !x.unsaved )
    if(chain_check1.length > 0){
        return {
            status: false,
            msg: `Между точками ${current.ac1_id} и ${current.ac2_id} расстояние установлено ранее !!!`
        }
    }
    if(chain_check2.length > 0){
        return {
            status: false,
            msg: `Между точками ${current.ac2_id} и ${current.ac1_id} расстояние установлено ранее !!!`
        }
    }
    return { status, msg }
}
