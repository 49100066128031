import React, {useEffect, useState} from 'react'
import { Spin } from 'antd'
import {
	WifiVector,
	WifiOfflineVector,
	UserVector,
	DoVector
} from './../../styles/icons'
import { get_query } from './../../functions'
import IconUpload from './IconUpload'
import IconShow from "./IconShow";

export default function PropEquipment(){
	const [iconDo1, setIconDo1] = useState('')
	const [iconDo2, setIconDo2] = useState('')
	const [loaderWiFi, setLoaderWiFi] = useState(false)
	let [open, setOpen] = useState(false)
	const [equipmenttypes, setEquipmenttypes] = useState([])

	useEffect(()=>{
		getEquipments()
	}, [])
 

	const getEquipments=()=>{
		get_query(`/equipmenttype/`, {}, true)
		.then((res) => {
			setEquipmenttypes(res.data)
			res.data.map(x=>{
				if(x.id==1){
					setIconDo1(process.env.REACT_APP_RADIUS_API + x.icon)
				}else{
					setIconDo2(process.env.REACT_APP_RADIUS_API + x.icon)
				}
			})
		})
		.catch((err) => {
			setEquipmenttypes( [])
		})
	}

		return(
			<>
				<table>
					<tbody>
					{equipmenttypes.map((equipmenttype)=>
							<tr
								className='icon-row'
								onClick={()=>setOpen({'open':equipmenttype.id})}
								key = {equipmenttype.id}
							>
								<td
									className='icon-name'
								>
									{equipmenttype.name=='ДО1'?'Дополнительное оборудование №1':'Дополнительное оборудование №2'}
								</td>
								<td
									className='icon-position'
								>
									<IconShow
										loader={loaderWiFi}
										default={<DoVector />}
										img={equipmenttype.id==1?iconDo1:iconDo2}
									/>
								</td>
								<td
									className='icon-action'
								>
									Изменить
								</td>
							</tr>
					)}
					</tbody>
				</table>
				<IconUpload
					open={Boolean(open)}
					id={open.open}
					isDo={true}
					close={(v) => {
						if(v) {
							v.iconDo1 ? (
								setIconDo1(v.iconDo1)
							) : (
								setIconDo2(v.iconDo2)
							)
						}
						setOpen( false)}}
					title={'Загрузка иконки ' + (open.open === 1 ? 'ДО1' : (open.open===2?'ДО2':''))}
				/>
			</>
		)
}


