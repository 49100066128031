import React from 'react'
import {
	Button
} from 'antd'
import NewScheme from '../../containers/modules/NewScheme'
import AlertZones from '../../containers/modules/AlertZones'
import ReaderDlg from '../../containers/modules/ReaderDlg'
import {Confirm, MapSelect, MineSelect} from '../../containers/modules/elems'
import Do1Dlg from "../../containers/modules/Do1Dlg";
import NewMine from "../../containers/modules/NewMine";
import EditScheme from "../../containers/modules/EditScheme";


// conftrol of configuration 
export default class ConfControl extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			open: false,
			doOpen: false
		}
	}


	render(){
		return(
			<>
				<p>Добавить:</p>
				<div className='div-flex-row'>
					<div id='div-left-control' style={{ flex: 1 }}>
						<Button
							disabled={this.props.reader}
							onClick={() => this.props.set_reader()}
						>
							Считыватель
						</Button>
						<Button
							disabled={this.props.do_1}
							onClick={() => this.props.set_do_1()}
						>
							ДО1
						</Button>
						<Button
							disabled={this.props.do_2}
							onClick={() => this.props.set_do_2()}
						>
							ДО2
						</Button>
						<Button
							disabled={this.props.counter}
							onClick={() => this.props.set_counter()}
						>
							Счетчик
						</Button>
						<NewScheme
							getgeodata={(id, mine_id) => this.props.getgeodata(id, mine_id)}
						/>
						<EditScheme
							getgeodata={(id, mine_id) => this.props.getgeodata(id, mine_id)} horizon={this.props.current}
						/>
						<Confirm
							title='Вы действительно хотите удалить горизонт'
							content=''
							action={() => this.props.remove()}
						>
							<Button 
								id='control-delete-btn'
							>
								Удалить горизонт
							</Button>
						</Confirm>
					</div>
					<div id='div-right-control'>
						<AlertZones 
							ac={() => this.props.ac()}
							horizont={() => this.props.horizont()} 
							requery={() => this.props.requery()}
						/>
					</div>
				</div>
				<div className={'div-flex-row'} style={{justifyContent:'end'}}>
					<MineSelect
						horizonts={this.props.mines}
						current={this.props.current_mine ? this.props.current_mine.name : false}
						change={(v) => {this.props.changeMine(v, true)}}
					/>
					<MapSelect
						horizonts={this.props.horizonts}
						current={this.props.current ? this.props.current.name : false}
						change={(v) => this.props.changeMap(v)}
					/>
				</div>
				<ReaderDlg 
					open={Boolean(this.state.open)}
					title={this.state.open}
					close={() => this.setState({ open: false })}
					reader={this.state.reader}
					save={(v) => this.saveAction(v)}
					remove={(v) => this.removeAction(v)}
				/>
				<Do1Dlg
					open={Boolean(this.state.doOpen)}
					title={this.state.doOpen}
					close={() => this.setState({ doOpen: false })}
					do_1={this.state.do_1}
					save={(v) => this.saveAction(v)}
					remove={(v) => this.removeAction(v)}
				/>
			</>
		)
	}
}


