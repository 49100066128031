import React, {useEffect, useRef, useState} from 'react'
import {get_query} from "../../functions";
const Logs = (props) =>{
    useEffect(()=>{
        scrollToBottom()
    }, [])
    const messagesEnd = useRef()
    const scrollToBottom = () => {
        messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
    const [open, setOpen] = useState(false)
    return (
        <>
            {open?(<button className='ant-btn-primary' onClick={()=>setOpen(false)}>Свернуть</button>):(<button className='ant-btn-primary' onClick={()=>setOpen(true)}>Развернуть</button>)}
            <div id='logs' style={{maxHeight: '500px', overflowY: "auto"}}>{open?(<div>{JSON.stringify(props.logs)}</div>):('')}<div ref={messagesEnd}></div></div>
        </>
    )
}
const LogItem = (props) => {
    return (<><div style={{color: "red", marginTop: '20px'}}>ID - {props.x.id}, NAME - {props.x.name}, MEMORY - {props.x.memory}, UPTIME - {props.x.uptime_human}</div><Logs logs={props.x.logs}/></>)
}
export default function InfoPage(){
    const [info, setInfo] = useState([])
    const [loading, setLoading] = useState(false)
    let messagesEnd = ''
    const getInfo = () =>{
/*        setLoading(true)*/
        fetch(`${process.env.REACT_APP_RADIUS_API}/debug`).then((response) => {
            return response.json();
        })
            .then((data) => {
                let ss=data.data
                setInfo(...info, ss)
                setLoading(false)
            });
    }
    useEffect(()=>{
        let timer = setInterval(getInfo, 1000)
    }, [])
    return(
        <>
            {loading?(''):(
                <div style={{backgroundColor: '#E5E5E5', position: 'absolute'}}>
                    <div>
                        Логи Radius
                    </div>
                    <div>
                        {info.map(x=>{
                            return <LogItem x={x} />
                        })}
                    </div>
                </div>)}
        </>
    )
}