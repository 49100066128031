import React from 'react'
import {
	Button,
	Table
} from 'antd'
import ActShift from '../../containers/modules/ActShift';
import { TableShiftAction } from './../../containers/ui/tables'
import {
	get_query,
	delete_query,
	put_query,
	post_query
} from '../../functions'
import NewMine from "../../containers/modules/NewMine";



// mines
export default class Mines extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			row: false,
			data: []
		}
	}

	componentDidMount(){
		this.getData()
	}

	getData(){
		this.setState({ loader: true })
		get_query('/mine', {}, true)
			.then((res) => {
				if(res.data!='null') {
					this.setState({data: res.data, loader: false})
				}else{
					this.setState({data: '', loader: false})
				}
			})
			.catch((err) => {
				console.log(err)
				this.setState({ data: [], loader: false })
			})
	}

	confCol(){
		let columns = [{
			title: 'Название',
			dataIndex: 'name',
			key: 'name'
		},{
			title: 'Действия',
			width: 240,
			className: 'txt-center',
			render: (row) => {
				return(
					<div>
						<div onClick={()=>this.removeAction(row.id)}>
							Удалить
						</div>
					</div>
				)
			}
		}]
		return columns
	}

	removeAction(id){
		this.setState({ loader: true })
		delete_query(`/mine/${id}`, true)
			.then((res) => {
				this.getData()
			})
			.catch((err) => {
				this.setState({ loader: false })
			})
	}

	render(){
		return(
			<>
				<div className='settings-title'>
					<p>Шахты</p>
				</div>
				<div  className='settings-body'>
					<Table
						columns={this.confCol()}
						dataSource={this.state.data}
						loading={this.state.loader}
						pagination={false}
						rowKey='id'
					/>
				</div>
				<div className='settings-btn'>
					<NewMine
					getData={()=>{this.getData()}}
					/>
				</div>
			</>
		)
	}
}


