import React from 'react'

//  exception block
export default class Exception extends React.Component {

	render(){
		return(
			<div className='div-ex'>
				<div className='div-center'>
				</div>
			</div>
		)
	}
}


