import React from 'react'
import {
	Button,
	Select
} from 'antd'
import { fontsw } from './../../constants'
import { 
	get_body_font,
	set_body_font
} from '../../functions/ui'

const { Option } = Select


// Font switcher
export default class PropFont extends React.Component {

	render(){
		return(
			<div
				className='div-flex-row'
				style={{
					padding: '32px 24px 36px 24px'
				}}
			>
				<Select
					style={{ width: '100%' }}
					value={get_body_font()}
					onChange={(v) => {
						set_body_font(v)
						this.forceUpdate()
					}}
				>
					{
						fontsw.map((f, id) => {
							return(
								<Option value={f} key={id}>{f}</Option>
							)
						})
					}
				</Select>
				<Button
					type='primary'
					style={{
						marginLeft: 12,
						width: 167
					}}
				>
					Сохранить
				</Button>
			</div>
		)
	}
}


