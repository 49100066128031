import React from 'react'
import { Spin } from 'antd'
import { get_query } from './../../functions'
import LogoUpload from './LogoUpload'
import IconShow from "./IconShow";


// Icon properties
export default class PropLogo extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			open: false
		}
	}

	componentDidMount(){

	}

	render(){
		return(
			<>
				<table>
					<tbody>
						<tr 
							className='icon-row'
							onClick={() => this.setState({ open: true })}
						>
							<td
								className='icon-name'
							>
								Логотип
							</td>
							<td
								className='icon-position'
							>
							</td>
							<td
								className='icon-action'
							>
								Изменить
							</td>
						</tr>
					</tbody>
				</table>
				<LogoUpload
					open={Boolean(this.state.open)}
					close={(v) => this.setState({'open': false})}
					title={'Загрузка логотипа'}
				/>
			</>
		)
	}
}


