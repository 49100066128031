import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import Appbar from './../../components/main/Appbar'


// Priavte route container for main routes
class PrivateRoute extends React.Component {
	render(){
		return(
			<Route
				{...this.props}
			>
				{
					this.props.token ? (
						<>
							<Appbar />
							<div className='container'>
								{this.props.children}
							</div>
						</>
					) : (
						<Redirect
							to={{
								pathname: "/login",
							}}
						/>
					)
				}
			</Route>
		)
	}
}

function mapStateToProps(state){
	return{
		token: state.user_reducer.token,
		is_admin: state.user_reducer.is_admin
	}
}

export default connect(mapStateToProps)(PrivateRoute)


