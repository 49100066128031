import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
	SettingOutlined
} from '@ant-design/icons'
import { CompanyLiteImg } from '../../styles/icons/elem'
import Appuser from './../../containers/modules/Appuser'
import { selected_item } from '../../functions/ui'
import {get_query, get_query_alert} from '../../functions'
import { message } from 'antd'


// app bar component
class Appbar extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			mineLogo: ''
		}
	}
	componentDidMount() {
		this.getLogo()
	}

	notifyQuery(){
		message.info('Выполнен запрос на запуск модуля оповещений')
		get_query_alert({
			path: this.props.path
		}) 
	}

	getLogo=()=>{
		get_query('/settings/getlogomine').then(res=>{
			this.setState({'mineLogo': res.img})
		})
	}

	render(){
		return(
			<div className='appbar'>
				<p 
					className='appbar-icon'
					onClick={() => this.props.history.push('/')}
				>
					<div
						style={{
							height: 35,
							cursor: 'pointer'
						}} 
					>{this.state.mineLogo?<img style={{height: 35}} src={'/api/files/logo/'+this.state.mineLogo}/>:''}</div>
				</p>
				<p
					className={
						selected_item(this.props.history.location.pathname, '/') ?
							'appbar-route appbar-route-active' :
							'appbar-route'
					}
					onClick={() => this.props.history.push('/')}
				>
					Главная
				</p>
				<p
					className={
						selected_item(this.props.history.location.pathname, '/scheme') ?
							'appbar-route appbar-route-active' :
							'appbar-route'
					}
					onClick={() => window.open('/scheme', '_blank')}
				>
					Схема
				</p>
				{this.props.is_admin?(<p
					className={
						selected_item(this.props.history.location.pathname, '/configurator') ?
							'appbar-route appbar-route-active' : 
							'appbar-route'
						}
					onClick={() => window.open('/configurator', '_blank')}
				>
					Конфигуратор
				</p>):('')}
{/*				<p
					className={
						selected_item(this.props.history.location.pathname, '/notice') ? 
							'appbar-route appbar-route-active' : 
							'appbar-route'
						}
					onClick={() => this.notifyQuery()}
				>
					Аварийное оповещение
				</p>*/}
				<p
					className={
						selected_item(this.props.history.location.pathname, '/reports') ?
							'appbar-route appbar-route-active' :
							'appbar-route'
					}
					onClick={() => window.open('/reports', '_blank')}
				>
					Отчеты
				</p>
				<div 
					className='appbar-user-container'
				>
					<p
						className='appbar-icon'
						onClick={() => this.props.history.push('/')}
					>
						<CompanyLiteImg
							style={{
								width: 140,
								height: 35,
								cursor: 'pointer'
							}}
						/>
					</p>
					{this.props.is_admin?(
					<SettingOutlined 
						className={
							selected_item(this.props.history.location.pathname, '/settings') ? 'appbar-route-active' : ''
						}
						onClick={() => window.open('/settings', '_blank')}
					/>):('')}
					<Appuser />
				</div>
			</div>
		)
	}
}

function mapStateToProps(state){
	return{
		path: state.user_reducer.path,
		is_admin: state.user_reducer.is_admin
	}
}

export default withRouter(
	connect(mapStateToProps)(Appbar)
)


