import L from 'leaflet'
import IconWifiSvg from './icon_wifi.svg'
import IconWifiOfflineSvg from './icon_wifi_offline.svg'
import IconUserSvg from './icon_user.svg'
import IconTransportSvg from './icon_transport.svg'
import IconDo1Svg from './icon_camera.svg'

export const wifiMarkerIcon = (path) => {
    return{
        normal: new L.Icon({
            iconUrl: path ? path : IconWifiSvg,
            iconRetinaUrl: path ? path : IconWifiSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon wifi-normal',
        }),
        nodata: new L.Icon({
            iconUrl: path ? path : IconWifiOfflineSvg,
            iconRetinaUrl: path ? path : IconWifiOfflineSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon wifi-nodata',
        }),
        alert: new L.Icon({
            iconUrl: path ? path : IconWifiOfflineSvg,
            iconRetinaUrl: path ? path : IconWifiOfflineSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon wifi-alert',
        }),
    }
}

export const workerMarkerIcon = (path) => {
    return{
        normal: new L.Icon({
            iconUrl: path ? path : IconUserSvg,
            iconRetinaUrl: path ? path : IconUserSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(28, 28),
            className: 'leaflet-icon front-icon',
        }),
        alert: new L.Icon({
            iconUrl: path ? path : IconUserSvg,
            iconRetinaUrl: path ? path : IconUserSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(28, 28),
            className: 'leaflet-icon worker-noconnect front-icon',
        }),
        search: new L.Icon({
            iconUrl: path ? path : IconUserSvg,
            iconRetinaUrl: path ? path : IconUserSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon worker-search front-icon',
        }),
    }
}

export const transportMarkerIcon = (path) => {
    return{
        normal: new L.Icon({
            iconUrl: path ? path : IconTransportSvg,
            iconRetinaUrl: path ? path : IconTransportSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(28, 28),
            className: 'leaflet-icon front-icon',
        }),
        alert: new L.Icon({
            iconUrl: path ? path : IconTransportSvg,
            iconRetinaUrl: path ? path : IconTransportSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(28, 28),
            className: 'leaflet-icon worker-noconnect front-icon',
        }),
        search: new L.Icon({
            iconUrl: path ? path : IconTransportSvg,
            iconRetinaUrl: path ? path : IconTransportSvg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon worker-search front-icon',
        }),
    }
}

export const do1MarkerIcon = (path) => {
    return{
        normal: new L.Icon({
            iconUrl: path ? path : IconDo1Svg,
            iconRetinaUrl: path ? path : IconDo1Svg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon front-icon',
        }),
        nodata: new L.Icon({
            iconUrl: path ? path : IconDo1Svg,
            iconRetinaUrl: path ? path : IconDo1Svg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon worker-noconnect front-icon',
        }),
        alert: new L.Icon({
            iconUrl: path ? path : IconDo1Svg,
            iconRetinaUrl: path ? path : IconDo1Svg,
            iconAnchor: null,
            popupAnchor: null,
            shadowUrl: null,
            shadowSize: null,
            shadowAnchor: null,
            iconSize: new L.Point(48, 48),
            className: 'leaflet-icon worker-search front-icon',
        }),
    }
}