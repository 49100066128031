import React, {useEffect, useState} from 'react'
import {
    Button,
    Input,
    Modal, Select,
    Spin, TreeSelect
} from 'antd'
import {
    get_query,
    post_query, post_query_admin, put_query, sso_query,
    upload_file
} from '../../functions'
import {setIsAdmin, setUser} from "../../actions/user_act";
const {Option} = Select

export default function MineProductionSelectDate(props){
    const [mineProduction, setMineProduction] = useState(0)
    const [error, setError] = useState('')
    const [date, setDate] = useState('')
    const [shifts, setShifts] = useState([])
    const [shift, setShift] = useState('')
    const [shiftData, setShiftData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [idForChange, setIdForChange] = useState('')
    
    const unsetDateShift = () => {
        setDate('')
        setShift('')
    }

    useEffect(()=>{
        getShifts()
    }, [])

    const getShifts = () => {
        get_query('/shift').then(res=>{
            setShifts(res.data)
        })
    }

    const changeDate = (e) => {
        setDate(e.target.value)
        get_query('/production/search', {'date': e.target.value, 'mine_id': props.mine}).then(res=>{
            setShiftData(res.data)
        })
    }

    const changeShift = (e) => {
        setShift(e)
        if(shiftData.length > 0) {
            let shift_data_tmp = shiftData.find(x => x.shift_id == e)
            if(shift_data_tmp){
                setMineProduction(shift_data_tmp.volume)
                setIsUpdate(true)
                setIdForChange(shift_data_tmp.id)
            } else {
                setMineProduction(0)
                setIsUpdate(false)
            }
        }
    }

    const updateMine = () => {
        if(isUpdate){
            let t2 = localStorage.getItem('token2')
            const data = {
                shift_id: shift,
                mine_id: props.mine,
                production_date: date,
                volume: mineProduction
            }

            if(t2){
                post_query_admin(`/production/${idForChange}`, data).then((res) => {
                    setMineProduction(res.data.volume)
                    // Обновляем shiftData после успешного обновления
                    const updatedShiftData = shiftData.map(item => 
                        item.id === idForChange ? {...item, volume: res.data.volume} : item
                    )
                    setShiftData(updatedShiftData)

                    localStorage.setItem('username2', '')
                    localStorage.setItem('token2', '')
                    props.close()
                }).catch((err) => {
                    setError('Данные за эту смену уже внесены!')
                })
            } else {
                post_query(`/production/${idForChange}`, data).then((res) => {
                    setMineProduction(0)
                    // Обновляем shiftData после успешного обновления
                    const updatedShiftData = shiftData.map(item => 
                        item.id === idForChange ? {...item, volume: res.data.volume} : item
                    )
                    setShiftData(updatedShiftData)

                    localStorage.setItem('username2', '')
                    localStorage.setItem('token2', '')
                    props.close()
                }).catch((err) => {
                    setError('Данные за эту смену уже внесены!')
                })
            }
        } else {
            put_query('/production', {
                shift_id: shift,
                mine_id: props.mine,
                production_date: date,
                volume: mineProduction
            }).then((res) => {
                setMineProduction(0)
                // Добавляем новые данные в shiftData после успешного добавления
                setShiftData([...shiftData, res.data])
                props.close()
            }).catch((err) => {
                setError('Данные за эту смену уже внесены!')
            })
        }
    }

    const validateProduction = (ev) => {
        if((/^\d+$/).test(ev.target.value)){
            setMineProduction(ev.target.value)
        }
    }

    return(
        <Modal
            title={<b></b>}
            visible={props.open}
            onCancel={() => {unsetDateShift(); setError(''); props.close()}}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => updateMine()}
                >
                    Изменить данные
                </Button>
            ]}
            width={572}
        >
            <div className='dlg-img-body'>
                <div style={{display: "flex", justifyContent: "center"}}>
                    Выберите дату для корректировки:
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <input type={"date"} onChange={e=>changeDate(e)}></input>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    Выберите смену для корректировки:
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Select value={shift} style={{width: '300px'}} onChange={e=>{changeShift(e)}}>
                        {shifts.map(x => <Option value={x.id}>{x.name}</Option>)}
                    </Select>
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    Значение:
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Input
                        type='text'
                        value={mineProduction}
                        onChange={(ev) => validateProduction(ev)}
                        placeholder='Выработка'
                        style={{width: '300px'}}
                    /> 
                    <div style={{display:"flex", flexDirection: 'column', justifyContent: 'center', marginLeft: '20px'}}>ТОНН</div>
                </div>
                <div style={{display: "flex", justifyContent: "center", color: 'red'}}>
                    {error}
                </div>
            </div>
        </Modal>
    )
}
