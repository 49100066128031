import React, {useEffect} from "react";
export default function Dashinformercircle(props){
  useEffect(()=> {
    var ctx = document.getElementById(props.id_canvas);
    var content = ctx.getContext("2d");
    content.clearRect(0, 0, ctx.width, ctx.height);

    content.strokeStyle = "#1e497e";
    content.fillStyle = "#1E497E";
    content.lineWidth = 20;

    var ctx_gray = document.getElementById(props.id_canvas+'_gray');
    var content_gray = ctx_gray.getContext("2d");

    content_gray.strokeStyle = "#91a4ac";
    content_gray.fillStyle = "#91a4ac";
    content_gray.lineWidth = 20;

    function drawPie(content, x, y, radius, percent) {
      content.beginPath();
      content.moveTo(x + radius, y);
      content.arc(x, y, radius, 0, (2 * Math.PI * percent) / 100);
      content.stroke();
    }
    drawPie(content_gray, 85,85, 72, 100);
    drawPie(content, 85, 85, 72, props.percent);
  }, [props.percent]
)
    const gr=props.id_canvas+'_gray'
    return (
      <div className="dash_info_wrap">
        <canvas style={{position:"absolute"}} id={gr} className="round_info" height={200}></canvas>
        <canvas style={{position:"absolute"}} id={props.id_canvas} className="round_info" height={200}></canvas>
        <div className="dash_informer_info">
          <div className="dash_informer_digit" style={{color: '#1E497E',
            textAlign: 'center',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            paddingTop: '65px'}}>{props.all}</div>
          <div className="dash_informer_text small_text">{props.name}</div>
        </div>
      </div>
    );
}
