// worker reducer

const initState = {
    workers: [],
    workers_online: 0,
    transport_online: 0
}

// worker reducer
export function worker_reducer(state = initState, action){
    switch (action.type) {
        case 'REFRESH_WORKERS':
            return Object.assign({}, state, {
                workers: action.payload
            })
        case 'REFRESH_WORKERS_ONLINE':
            return Object.assign({}, state, {
                workers_online: action.payload
            })
        case 'REFRESH_TRANSPORT_ONLINE':
            return Object.assign({}, state, {
                transport_online: action.payload
            })
        default: 
            return state
    }
}