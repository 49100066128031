import React from 'react'
import { Spin } from 'antd'
import {
	WifiVector,
	WifiOfflineVector,
	UserVector,
	TransportVector
} from './../../styles/icons'
import { get_query } from './../../functions'
import IconUpload from './IconUpload'
import IconShow from "./IconShow";


// Icon properties
export default class PropIcon extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loaderWifi: false,
			loaderWifiOffline: false,
			loaderTransport: false,
			loaderWorker: false,
			iconWifi: false,
			iconWifiOffline: false,
			iconTransport: false,
			iconWorker: false,
			open: false
		}
	}

	componentDidMount(){
		this.getIcons(1)
		this.getIcons(2)
		this.getIcons(3)
/*		this.getIcons(4)*/
	}
 
	getIcons(id){
		let f = ''
		let v = ''
		if(id===1){
			f = 'loaderWifi'
			v = 'iconWifi'
		}else if(id===2){
			f = 'loaderWorker'
			v = 'iconWorker'
		}else if(id==3){
			f = 'loaderTransport'
			v = 'iconTransport'
		}/*else{
			f = 'loaderWifiOffline'
			v = 'iconWifiOffline'
		}*/
		this.setState({ [f]: true })
		get_query(`/icon/${id}`, {}, true)
		.then((res) => {
			let icon_url = false
			if(res.data){
				if(res.data.filename !== 'default-worker.png' && res.data.filename !== 'default-reader.png'&& res.data.filename !== 'default-truck.png'){
					icon_url = process.env.REACT_APP_RADIUS_API + res.data.filename 
				}
			}
			this.setState({ 
				[f]: false,
				[v]: icon_url
			})
		})
		.catch((err) => {
			this.setState({ [f]: false, [v]: false })
		})
	}

	render(){
		return(
			<>
				<table>
					<tbody>
						<tr 
							className='icon-row'
							onClick={() => this.setState({ open: 2 })}
						>
							<td
								className='icon-name'
							>
								Сотрудник
							</td>
							<td
								className='icon-position'
							>
								<IconShow 
									loader={this.state.loaderWorker}
									default={<UserVector/>}
									img={this.state.iconWorker}
								/>
							</td>
							<td
								className='icon-action'
							>
								Изменить
							</td>
						</tr>
						<tr
							className='icon-row'
							onClick={() => this.setState({ open: 1 })}
						>
							<td
								className='icon-name'
							>
								Считыватель онлайн
							</td>
							<td
								className='icon-position'
							>
								<IconShow 
									loader={this.state.loaderWifi}
									default={<WifiVector/>}
									img={this.state.iconWifi}
								/>
							
							</td>
							<td
								className='icon-action'
							>
								Изменить
							</td>
						</tr>
						<tr
							className='icon-row'
							onClick={() => this.setState({ open: 4 })}
						>
							<td
								className='icon-name'
							>
								Считыватель оффлайн
							</td>
							<td
								className='icon-position'
							>
								<IconShow
									loader={this.state.loaderWifi}
									default={<WifiOfflineVector/>}
									img={this.state.iconWifiOffline}
								/>

							</td>
							{/*<td
								className='icon-action'
							>
								Изменить
							</td>*/}
						</tr>
						<tr
							className='icon-row'
							onClick={() => this.setState({ open: 3 })}
						>
							<td
								className='icon-name'
							>
								Транспорт
							</td>
							<td
								className='icon-position'
							>
								<IconShow
									loader={this.state.loaderWifi}
									default={<TransportVector/>}
									img={this.state.iconTransport}
								/>
							</td>
							<td
								className='icon-action'
							>
								Изменить
							</td>
						</tr>
					</tbody>
				</table>
				<IconUpload 
					open={Boolean(this.state.open)}
					id={this.state.open}
					close={(v) => this.setState(Object.assign({}, { 
						open: false
					}, v))}
					title={'Загрузка иконки ' + (this.state.open === 1 ? 'Считывателя онлайн' : (this.state.open===2?'Работника':(this.state.open===3?'Транспорта':'Считывателя оффлайн')))}
				/>
			</>
		)
	}
}


