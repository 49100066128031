import React, {useEffect, useState} from 'react'
import { Line } from '@ant-design/plots';
export default function WorkerByDate(props){
		const data = props.data;
		const config = {
			data,
			xField: 'date_shift',
			yField: 'max_count',
			label: {
				style:{
					fontSize: 18,
					lineHeight: 2
				}
			},
			tooltip: {
				show: false,
			},
			point: {
				size: 5,
				shape: 'square',
				style: {
					fill: '#1e497e',
					stroke: '#1e497e',
					lineWidth: 2,
				},
			},
			line:{
				style: {
					stroke: '#1e497e',
					lineWidth: 2,
				},
			},
			state: {

			},
			interactions: [
				{
					type: 'marker-active',
				},
			],
		};
		return <Line {...config} />;
};