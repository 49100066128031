import React from 'react'
import PropTypes from 'prop-types'
import {
	LockOutlined,
	UnlockOutlined
} from '@ant-design/icons'
import { 
	Modal,
	Form,
	Input, 
	message
} from 'antd'
import { unlock_query } from '../../functions'
import { connect } from 'react-redux'

const layout = {
	labelCol: { span: 5 },
	wrapperCol: { span: 19 },
}


// Props div
class PropsBlock extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			open: false,
			block: true,
			password: ''
		}
	}

	okAction(){
		this.setState({ loader: true })
		unlock_query('/session/validate', {		// UNLOCK URL
			login: this.props.login,
			password: this.state.password
		})
		.then((res) => {
			message.success('Функция разблокирована успешно')
			this.setState({
				loader: false,
				open: false,
				block: false,
				password: ''
			})
		})
		.catch((err) => {
			message.error('Не верный логин или пароль')
			this.setState({
				loader: false,
				open: true,
				block: true,
				password: ''
			})
		})
	}

	render(){
		return(
			<>
				<div 
					className='div-props'
					{...this.props}
				>
					<p className='props-title div-flex-row'>
						<b
							style={{
								display: 'inline-block',
								flex: 1
							}}
						>
							{this.props.title}
						</b>
						{
							this.props.lock ? (
								<span 
									style={{
										display: 'inline-block',
										paddingRight: 0
									}}
								>
									{
										this.state.block ? 
											<LockOutlined 
												onClick={() => this.setState({ open: true })}
											/> : 
											<UnlockOutlined 
												onClick={() => this.setState({ block: true })}
											/>
									}
								</span>
							) : false
						}
					</p>
					{React.cloneElement(this.props.children, { block: this.state.block })}
				</div>
				<Modal
					title={<b>Разблокировать функцию</b>}
					visible={this.state.open} 
					onOk={() => this.okAction()}
					okText='Разблокировать' 
					onCancel={() => this.setState({ open: false, password: '' })}
					cancelText='Отменить'
					width={345}
				>
					<Form
						{...layout}
						style={{ padding: 16 }}
					>
						<Form.Item
							label="Пароль:"
						>
							<Input 
								type='password'
								placeholder='Введите пароль'
								value={this.state.password}
								onChange={(ev) => this.setState({ password: ev.target.value })}
							/>
						</Form.Item>
					</Form>
				</Modal>
			</>
		)
	}
}

function mapStateToProps(state){
	return{
		login: state.user_reducer.login
	}
}

export default connect(mapStateToProps)(PropsBlock)

PropsBlock.defaultProps = {
	lock: false
}

PropsBlock.propTypes = {
	title: PropTypes.string.isRequired,
	lock: PropTypes.bool
}
