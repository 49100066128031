import React from 'react'

import ConfControl from './ConfControl'
import GeoPlane from './../../containers/modules/GeoPlane'
import {get_query} from "../../functions";
import {del_memory, findMap, set_memory} from "../../functions/memory";


// Configurator page
export default class Configurator extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			reader: false,
			do_1: false,
			do_2: false,
			counter: false,
			current: false, // текущая карта
			current_mine: false,
			mines: [],		// шахты
			horizonts: [],	// карты
		}
		this.geoplane = React.createRef()
	}

	componentDidMount() {
		this.getData(true, true)
	}

	getData(change, change_mine){
		Promise.all([
			get_query('/mine', {}, true).then((res) => {
				this.setState({
					mines: res.data
				}, () => {
					if (typeof change_mine === 'object') {
						// change.height = 600
						// change.width = 1000
						this.changeMine(change_mine)
					} else if (typeof change_mine === 'boolean' && change_mine) {
						var mine = findMap(res.data, 'mine_id')
						this.changeMine(mine ? mine : false)
						// this.changeMap(res.data[0] ? res.data[0] : false)
					}

				})
			}).catch((err)=>{
				console.log(err)
			}),
			get_query('/horizon', {}, true) // - вынес на уровень выше
				.then((res) => {
					this.setState({
						horizons_all: res.data,
						horizonts: res.data.filter(x => x.mine_id == this.state.current_mine.id),
					}, () => {
						if(window.location.search!=''){
							let tmp = window.location.search.split('=')
							let hor = this.state.horizons_all.find(x=>x.id==tmp[1])
							change = hor
						}
						if (typeof change === 'object') {
							// change.height = 600
							// change.width = 1000
							this.changeMap(change)
						} else if (typeof change === 'boolean' && change) {
							var map = findMap(res.data, 'map_id')
							this.changeMap(map ? map : false)
							// this.changeMap(res.data[0] ? res.data[0] : false)
						} else if (typeof change === 'boolean' && !change) {
							var map2 = res.data.filter(x => x.mine_id == this.state.current_mine.id)[0]
							this.changeMap(map2 ? map2 : false)
						}

					})
				})
				.catch((err) => {
					this.setState({
						horizonts: [],
						loader: false,
						current: false,
						change_mine: false
					})
				})
		])
	}

	changeMine(current_mine, refreshHorizon){
		if(current_mine){
			set_memory(current_mine.id, 'mine_id')
			this.setState({
				current_mine: current_mine
			},()=>{
				if(refreshHorizon){
					this.getData(false, current_mine)
				}
			})
		} else {
			del_memory('mine_id')
		}
	}

	changeMap(current){
		if(current){
			set_memory(current.id, 'map_id')
			this.setState({
				current: current,
				ac: [],
				worker: [],
				tmp_dots: [],
				equipment: [],
			}, () => {

			})
		} else {
			del_memory('map_id')
		}
	}

	removeHorizont(){
		this.geoplane.current.removeData()
	}

	getGeoData(id, mine_id){
		this.geoplane.current.getData(id, mine_id)
	}

	getCurrentHorizont(){
		return this.geoplane.current.state.current
	}

	getAc(){
		return this.geoplane.current.state.ac ? this.geoplane.current.state.ac : []
	}

	getEquipment(){
		return this.geoplane.current.state.equipment ? this.geoplane.current.state.equipment : []
	}

	reQuery(){
		let current = this.getCurrentHorizont()
		this.geoplane.current.getAlertLines(current)
	}

	render(){
		return(
			<>
				<div className='page-title'>
					<p className='txt-title'>
						Конфигуратор горизонтов 
					</p>
				</div>
				<div>
					<ConfControl
						ac={() => this.getAc()}
						equipment={() => this.getEquipment()}
						horizont={() => this.getCurrentHorizont()}
						getgeodata={(id, mine_id) => this.getGeoData(id, mine_id)}
						remove={() => this.removeHorizont()}
						reader={this.state.reader}
						do_1={this.state.do_1}
						do_2={this.state.do_2}
						counter={this.state.counter}
						set_reader={() => this.setState({ reader: !this.state.reader })}
						set_do_1={() => this.setState({ do_1: !this.state.do_1 })}
						set_do_2={() => this.setState({ do_2: !this.state.do_2 })}
						set_counter={() => this.setState({ counter: !this.state.counter })}
						requery={() => this.reQuery()}
						horizonts={this.state.horizonts}
						mines={this.state.mines}
						current={this.state.current}
						current_mine={this.state.current_mine}
						changeMine={(v)=>this.changeMine(v, true)}
						changeMap={(v)=>this.changeMap(v)}
					/>
				</div>
				<div 
					style={{ height: 'calc(100% - 140px)', margin:'16px 0 0 0' }}
				>
					<GeoPlane
						draggable
						configuration
						ref={this.geoplane}
						reader={this.state.reader}
						do_1={this.state.do_1}
						do_2={this.state.do_2}
						counter={this.state.counter}
						change={(f, v) => this.setState({ [f]: v })}
						set_reader={() => this.state.reader ? this.setState({ reader: false }) : false}
						set_do_1={() => this.state.do_1 ? this.setState({ do_1: false }) : false}
						set_do_2={() => this.state.do_2 ? this.setState({ do_2: false }) : false}
						set_counter={() => this.state.counter ? this.setState({ counter: false }) : false}
						view_readers={true}
						view_do_1={true}
						view_do_2={true}
						view_workers={false}
						view_tmp_dots={true}
						view_zones={true}
						view_alertzones={true}
						current={this.state.current}
						current_mine={this.state.current_mine}
						hide_select={true}
					/>
				</div>
			</>
		)
	}
}


