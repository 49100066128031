// error reducer

const initState = {
    error_text: '',
    error_count: 0
}

// error reducer
export function error_reducer(state = initState, action){
    switch (action.type) {
        case 'SET_ERROR':
            return Object.assign({}, state, {
                error_text: action.payload.error_text,
                error_count: action.payload.error_count
            })
        case 'SET_COUNT': 
            return Object.assign({}, state, {
                error_count: action.payload
            })
        case 'SET_CLEAR_ERROR':
            return initState
        default: 
            return state
    }
}