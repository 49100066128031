import React, {useEffect, useState} from 'react'
import {
    Button,
    Input,
    Modal, Select,
    Spin, TreeSelect
} from 'antd'
import {
    get_query, login_query,
    post_query, production_login_query, put_query,
    upload_file
} from '../../functions'
const {Option} =  Select

export default function AuthForProduction(props){
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const loginForProduction = () => {
        production_login_query('/session/login', {
            login: login,
            password: password
        })
            .then((res) => {
                if(res.user.is_admin == true){
                    localStorage.setItem('username2', res.user.fio)
                    localStorage.setItem('token2', res.token)
                    props.openEdit()
                    setPassword('')
                }else{
                    setError('Введите логин и пароль администратора')
                }
            })
            .catch((err) => {
                setError('Ошибка авторизации')
            })
    }

    return(
        <Modal
            title={<b></b>}
            visible={props.open}
            onCancel={() => {setPassword(''); props.close()}}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => loginForProduction()}
                >
                    Войти
                </Button>
            ]}
            width={572}
        >
            <div className='dlg-img-body'>
                <div style={{display: "flex", justifyContent: "center"}}>
                    Логин:
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Input
                        type='text'
                        value={login}
                        onChange={(ev) => setLogin(ev.target.value)}
                        placeholder='Логин'
                        style={{width: '300px'}}
                    />
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    Пароль:
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Input
                        type='password'
                        value={password}
                        onChange={(ev) => setPassword(ev.target.value)}
                        placeholder='Пароль'
                        style={{width: '300px'}}
                    />
                </div>
                <div style={{display: "flex", justifyContent: "center", color: 'red'}}>
                    {error}
                </div>
            </div>
        </Modal>
    )
}