import React from 'react'
import PropTypes from 'prop-types'
import {
	Modal,
	Button
} from 'antd'


// Reader dialog
export default class ReaderDlg extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	render(){
		return(
			<Modal
				title={<b>{this.props.title}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={
					<>
						<Button
							danger
							onClick={() => this.props.remove()}
						>
							Удалить
						</Button>
						<Button
							type='primary'
							onClick={() => this.props.save()}
						>
							Сохранить
						</Button>
					</>
				}
				width={440}
			>
				{/* <p>what a fuck !?!</p> */}
			</Modal>
		)
	}
}

ReaderDlg.defaultProps = {

}

ReaderDlg.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func.isRequired,
	save: PropTypes.func.isRequired,
	remove: PropTypes.func.isRequired,
}


