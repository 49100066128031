import React from 'react'


// 
export default class Notice extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}

	render(){
		return(
			<>
				<div className='page-title'>
					<p className='txt-title'>
						Система аварийного оповещения<br/>
						и персонального вызова
					</p>
				</div>
			</>
		)
	}
}


