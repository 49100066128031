import React from 'react'
import {
	Button,
	Input,
	Modal, Select,
	Spin
} from 'antd'
import {
	get_query,
	put_query,
	upload_file
} from '../../functions'
const {Option} =  Select


// new scheme
export default class NewScheme extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			filename: '',
			file_uploaded: false,
			mine_select: {},
			mines: [],
			is_aggregate: false,
			pin_dash: false
		}
		this.inputFile = React.createRef()
	}

	saveImage(ev){ 
		this.setState({ loader: true })
		let file = ev.target.files[0]
		upload_file('/upload/map', file)
		.then((res) => {
			this.setState({ file_uploaded: res.data, loader: false })
		})
		.catch((err) => {
			this.setState({ loader: false })
		})
	}

	saveHorizont(){
		this.setState({ save_loader: true })
		put_query('/horizon', {
			name: this.state.filename,
			map: this.state.file_uploaded.image,
			mine_id: this.state.mine_select.id,
			is_aggregate: this.state.is_aggregate,
			pin_dash: this.state.pin_dash
		}, true)
		.then((res) => {
			this.setState({ 
				save_loader: false,
				open: false,
				filename: '',
				file_uploaded: false,
				is_aggregate: false,
				pin_dash: false
			}, () => {
				this.props.getgeodata(res.data, this.state.mine_select)
			})
		})
		.catch((err) => {
			this.setState({ save_loader: false })
		})
	}

	addListener(){
        document.addEventListener("keydown", this.listener);
    }

    removeListener(){
        document.removeEventListener("keydown", this.listener);
    }

    listener = (ev) => {
        if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            this.saveHorizont()
        }
    }
	getMine = () => {
		get_query('/mine', {}).then((res)=>{
			this.setState({
				mines: res.data
			})
		})
	}

	render(){
		return(
			<>
				<Button
					onClick={() => {
						this.setState({ open: true })
						this.getMine()
					}}
				>
					Новый горизонт
				</Button>
				<Modal
					title={<b>Добавить новый горизонт</b>}
					visible={this.state.open} 
					onCancel={() => {
						this.setState({ 
							open: false,
							filename: '',
							file_uploaded: false,
							is_aggregate: false
						})
					}}
					footer={[
						<Button 
							key="back" 
							onClick={() => { 
								this.setState({ 
									open: false,
									filename: '',
									file_uploaded: false,
									is_aggregate: false
								})
							}}
						>
							Отменить
						</Button>,
						<Button 
							key="submit" 
							type="primary" 
							loading={this.state.save_loader} 
							onClick={() => this.saveHorizont()}
						>
							Загрузить
						</Button>
					]}
					width={572}
				>
					<div className='dlg-img-body'>
						<p>
							<Input 
								type='text'
								value={this.state.filename}
								onChange={(ev) => this.setState({ filename: ev.target.value })}
								placeholder='Название горизонта'
							/>
						</p>
						<p>
							<Select
								style={{width: '300px'}}
								onChange={(v) => {this.setState({'mine_select':JSON.parse(v)})}}
								dropdownMatchSelectWidth={true}
							>
								{this.state.mines.map((object, id)=> {
									return(
										<Option key={object.id} value={JSON.stringify(object)}>{object.name}</Option>
									)
								})
								}
							</Select>
						</p>
						{
							this.state.file_uploaded ? (
								<div
									style={{
										width: '100%',
										height: '100%',
										textAlign: 'center'
									}}
								>
									<img
										src={`${process.env.REACT_APP_RADIUS_API}${this.state.file_uploaded.image}`}
										style={{
											maxWidth: '100%',
											maxHeight: '100%'
										}}
										alt='radiusmap'
									/>
								</div>
							) : (
								<div className='img-active'>
									{
										this.state.loader ? (
											<Spin />
										) : (
											<p>
												<span
													style={{ textDecoration: 'underline', cursor: 'pointer' }}
													onClick={() => this.inputFile.current.click()}
													>
													Выберите изображение 
													<input 
														type="file" 
														id="file" 
														style={{ display: "none" }} 
														ref={this.inputFile}
														onChange={(ev) => this.saveImage(ev)}
														/>
												</span>
												&nbsp;
												<span
													style={{ color: '#1890FF' }}
													>
													или перетащите его сюда
												</span>
											</p>
										)
									}
								</div>
							)
						}
						<p
							style={{ marginTop: 14, marginBottom: 0, color: 'rgba(0,0,0,0.45)'}}
						>
							Загрузите изображение в формате jpg, bmp, png, tiff
						</p>
						<input type={"checkbox"} style={{marginTop: '10px'}} checked={this.state.is_aggregate} onChange={(ev)=>this.setState({'is_aggregate':!this.state.is_aggregate})}/> Обобщенный горизонт
						<br/>
						<input type={"checkbox"} style={{marginTop: '10px'}} checked={this.state.pin_dash} onChange={(ev)=>this.setState({'pin_dash':!this.state.pin_dash})}/> Отображать на дашборде
					</div>	
				</Modal>
			</>
		)
	}
}


