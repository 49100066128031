
export function set_memory(id, memory_key){
    localStorage.setItem(memory_key, id)
}

export function get_memory(memory_key){
    return localStorage.getItem(memory_key)
}

export function del_memory(memory_key){
    localStorage.removeItem(memory_key)
}

// find map
export function findMap(list, memory_key){
    try{
        let mid = get_memory(memory_key)
        if(mid){
            let res = list.find( x => x.id === parseInt(mid))
            return res ? res : list[0]
        } else {
            return false
        }
    } catch {
        return false
    }
}