import React from 'react'
import PswdChange from '../../containers/modules/PswdChange'


// контроль настроек 
export default class SettingsControl extends React.Component {

	render(){
		return(
			<div className='div-flex-row'>
				<div id='settings-conf' className='div-flex-row'>
					<p
						className={this.props.tab === 0 ? 'active' : ''}
						onClick={() => this.props.change(0)}
					>
						Сотрудники/Транспорт
					</p>
					<p
						className={this.props.tab === 6 ? 'active' : ''}
						onClick={() => this.props.change(6)}
					>
						Должности
					</p>
					<p
						className={this.props.tab === 7 ? 'active' : ''}
						onClick={() => this.props.change(7)}
					>
						Отделы
					</p>
					<p
						className={this.props.tab === 4 ? 'active' : ''}
						onClick={() => this.props.change(4)}
					>
						Шахты
					</p>
					<p
						className={this.props.tab === 3 ? 'active' : ''}
						onClick={() => this.props.change(3)}
					>
						Смены
					</p>
					<p
						className={this.props.tab === 1 ? 'active' : ''}
						onClick={() => this.props.change(1)}
					>
						Пользователи
					</p>
					<p
						className={this.props.tab === 5 ? 'active' : ''}
						onClick={() => this.props.change(5)}
					>
						Счетчики
					</p>
					<p
						className={this.props.tab === 2 ? 'active' : ''}
						onClick={() => this.props.change(2)}
					>
						Настройки системы
					</p>
					<p
						className={this.props.tab === 8 ? 'active' : ''}
						onClick={() => this.props.change(8)}
					>
						Типы
					</p>
					<p
						className={this.props.tab === 9 ? 'active' : ''}
						onClick={() => this.props.change(9)}
					>
						Интерфейсы
					</p>
				</div>
				<div>
					<PswdChange />
				</div>
			</div>
		)
	}
}


