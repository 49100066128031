import React from 'react'
import {
	Modal,
	Button,
	Form,
	Input,
	Select
} from 'antd'
import { Confirm } from './../../containers/modules/elems'
import { get_query } from '../../functions';
const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

// reader work modal
export default class ReaderModal extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			interfaces:[]
		}
	}
	save(){
			if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.state.host)) {
				this.props.save(this.state)
			}else {
				alert("Проверьте IP адрес!")
			}
	}
	UNSAFE_componentWillReceiveProps(np){
		let open = Boolean(np.open)
		let old_open = Boolean(this.props.open)
		if(open && open !== old_open){
			this.addListener()
			this.setState(Object.assign({}, this.state, np.open))
		}
		if(!open && open !== old_open){
			this.removeListener()
		}
	}

	addListener(){
        document.addEventListener("keydown", this.listener);
    }

    removeListener(){
        document.removeEventListener("keydown", this.listener);
    }

    listener = (ev) => {
        if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            this.save()
        }
    }
	componentDidMount(){
		get_query('/interfaces', {}, true)
		.then((res) => {
			if(res.data!='null') {
				this.setState({interfaces: res.data, loader: false})
			}else{
				this.setState({interfaces: [], loader: false})
			}
		})
		.catch((err) => {
			console.log(err)
			this.setState({ data: [], loader: false })
		})
	}

	render(){
		return(
			<Modal
				title={<b>Считыватель ID - {this.state.identifier}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={[
					<Confirm
						key='confirm-key'
						title='Вы действительно хотите удалить считыватель'
						content=' '
						action={() => this.props.delete(this.state.id)}
					>
						<Button 
							loading={this.props.loader}
							key="back-key" 
						>
							Удалить
						</Button>
					</Confirm>,
					<Button 
						key="submit-key" 
						type="primary" 
						loading={this.props.loader}
						onClick={() => this.save()}
					>
						Сохранить
					</Button>
				]}
				width={440}
			>
				<div className='modal-body'>
					<Form
						{...layout}
					>
						<Form.Item
							label="ID устройства"
						>
							<Input
								value={this.state.identifier}
								onChange={(ev) => this.setState({ identifier: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Наименование"
						>
							<Input
								value={this.state.name}
								onChange={(ev) => this.setState({ name: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Место расп-ния"
						>
							<Input 
								value={this.state.place}
								onChange={(ev) => this.setState({ place: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Хост"
						>
							<Input 
								value={this.state.host}
								onChange={(ev) => this.setState({ host: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item label="Интерфейс">
							<Select
								value={this.state.interfaces_id}
								onChange={(value) => this.setState({ interfaces_id: value })}
							>
								{this.state.interfaces.map(i => (
								<Select.Option key={i.id} value={i.id}>
									{i.name}
								</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							label="Логин"
						>
							<Input 
								value={this.state.login}
								onChange={(ev) => this.setState({ login: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Пароль"
						>
							<Input 
								value={this.state.password}
								onChange={(ev) => this.setState({ password: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Опорный сигнал, dB"
						>
							<Input
								value={this.state.max_rssi}
								onChange={(ev) => this.setState({ max_rssi: ev.target.value })}
							/>
						</Form.Item>
						<Form.Item
							label="Координата X"
						>
							<Input 
								disabled
								value={this.state.coord_x}
							/>
						</Form.Item>
						<Form.Item
							label="Координата Y"
						>
							<Input
								disabled
								value={this.state.coord_y}
							/>
						</Form.Item>
						<Form.Item
							label="Ламповая"
						>
							<Input
								type="checkbox"
								value={this.state.is_lamp}
								checked={this.state.is_lamp}
								onChange={(ev) => this.setState({ is_lamp: ev.target.checked })}
							/>
						</Form.Item>
					</Form>
				</div>
			</Modal>
		)
	}
}


