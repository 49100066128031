// auth functions
import store from './../reducers/store'
import axios from 'axios'
import * as user_act from './../actions/user_act'
import {del_memory} from "./memory";

// запрос авторизации TMP
export function login_query(url, body){
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`, body)
        .then((res) => {
            store.dispatch(user_act.setInit(res.data.user))
            store.dispatch(user_act.setToken(res.data.token))
            resolve(res.data)
        })
        .catch((err) => {
            reject(err)
        })
    })
}
//запрос авторизации для корректировки данных о производительности шахты
export function production_login_query(url, body){
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`, body)
            .then((res) => {
                resolve(res.data)
            })
            .catch((err) => {
                reject(err)
            })
    })
}

// sso query
export function sso_query(url){
    return new Promise((resolve, reject) => {
        let state = store.getState()
        let token = state.user_reducer.token
        axios.get(`${process.env.REACT_APP_RADIUS_API}${url}`, {
            headers:{
                'token': token
            }
        })
        .then((res) => {
            store.dispatch(user_act.setInit(res.data.user))
            store.dispatch(user_act.setToken(token))
            resolve(res.data)
        })
        .catch((err) => {
            store.dispatch(user_act.setClear())
            store.dispatch(user_act.setToken(false))
            reject(err)
        })
    })
}

// unlock method
export function unlock_query(url, body){
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_RADIUS_API}${url}`, body)
        .then((res) => {
            resolve()
        })
        .catch((err) => {
            reject()
        })
    })
}

export function get_ls_token(){
    return localStorage.getItem('radius_token')
}

export function set_ls_token(token){
    localStorage.setItem('radius_token', token)
}

export function del_ls_token(){
    localStorage.removeItem('radius_token')
}

export function clearAuth(){
    del_memory('mine_id')
    del_memory('map_id')
    store.dispatch(user_act.setClear())
    store.dispatch(user_act.setToken(false))
}