import React from 'react'
import {MapSelect, MineSelect, SwitchLabel} from './../../containers/modules/elems'


// Scheme Conf
export default class SchemeConf extends React.Component {

	render(){
		return(
			<>
				<p>Вкл. / Откл. отображение:</p>
				<div className='div-flex-row'>
					<div className='div-flex-row' style={{ flex: 1 }}>
						<SwitchLabel 
							text='Считыватели'
							checked={this.props.view_readers}
							change={(v) => this.props.change('view_readers', !this.props.view_readers )}
						/>
						<SwitchLabel 
							text='Рабочие'
							checked={this.props.view_workers}
							change={(v) => this.props.change('view_workers', !this.props.view_workers )}
						/>
						<SwitchLabel
							text='Пути'
							checked={this.props.view_zones}
							change={(v) => this.props.change('view_zones', !this.props.view_zones )}
						/>
						<SwitchLabel 
							text='Опасные зоны'
							checked={this.props.view_alertzones}
							change={(v) => this.props.change('view_alertzones', !this.props.view_alertzones )}
						/>
						<SwitchLabel
							text='ДО1'
							checked={this.props.view_do_1}
							change={(v) => this.props.change('view_do_1', !this.props.view_do_1)}
						/>
						<SwitchLabel
							text='ДО2'
							checked={this.props.view_do_2}
							change={(v) => this.props.change('view_do_2', !this.props.view_do_2)}
						/>
						<MineSelect
							horizonts={this.props.mines}
							current={this.props.current_mine ? this.props.current_mine.name : false}
							change={(v) => {this.props.changeMine(v, true)}}
						/>
						<MapSelect
							horizonts={this.props.horizonts}
							current={this.props.current ? this.props.current.name : false}
							change={(v) => this.props.changeMap(v)}
						/>
					</div>
				</div>
			</>
		)
	}
}


