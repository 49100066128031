import React from 'react'
// import { ClockVector } from '../../styles/icons/elem'
import { ClockFullVector } from '../../styles/icons/elem'
import { 
	number_time,
	analog_time
} from '../../functions/ui'


// Clock component
export default class ClockComp extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			idx: false
		}
	}

	componentDidMount(){
		this.timeAction()
		this.timeInterval()
	}

	componentWillUnmount(){
		clearInterval(this.state.idx)
	}

	timeInterval(){
		let t = this
		let idx = setInterval(function(){
			t.timeAction()
		}, 1000)
		this.setState({ idx: idx })
	}

	timeAction(){
		number_time()
		analog_time()		
	}

	render(){
		return(
			<div className='subconf-clock txt-center'>
				{/* <ClockVector /> */}
				<ClockFullVector />
			</div>
		)
	}
}


