import React, {useEffect, useState} from 'react'


const LogItem = (props) => {
    return (<>
        <div style={{color: props.x.all!=props.x.sum?"red":"green", marginTop: '20px', display: 'flex'}}>
            <div style={{width: '200px'}}>{props.x.time?props.x.time:''}</div>
            <div style={{width: '100px'}}>{props.x.sum?props.x.sum:''}</div>
            <div style={{width: '100px'}}>{props.x.all?props.x.all:''}</div>
        </div>
    </>)
}
export default function LogPage(){
    const [text, setText] = useState('')
    const [info, setInfo] = useState([])

    const fileRead = (e) =>{
        console.log(e)
        let reader = new FileReader();

        reader.readAsText(e.target.files[0]);

        reader.onload = function() {
            let arr = reader.result.split("\n")
            arr = arr.filter(x=>x.length>23)
            let result_arr = []
            for (let i=0; i<arr.length; i++){
                let time = arr[i].slice(0, 19)
                function getobj(){
                    try {
                        let ttt = JSON.parse(arr[i].slice(20))
                        return ttt
                    }catch(e) {
                        console.error(e)
                        return false
                    }
                }
                let obj = {}
                    obj = getobj()
                let sum = 0
                let all = 0
                if(obj) {
                    for (let key of Object.keys(obj)) {
                        if (key != 10) {
                            sum += obj[key].workers.length
                        } else {
                            all = obj[key].workers.length
                        }
                    }
                }
                let tmp_obj = {time: time, sum: sum, all: all}
                result_arr.push(tmp_obj)
            }
            setInfo(result_arr);
        };
    }

    return(
        <>
                <div style={{backgroundColor: '#E5E5E5', position: 'absolute'}}>
                    <div>
                        Логи Radius
                    </div>
                    <input type={"file"} onChange={fileRead}/>
                    <div style={{display:'flex'}}>
                        <div style={{width: '200px'}}>
                            Дата
                        </div>
                        <div style={{width: '100px'}}>
                            Сумма по счетчикам
                        </div>
                        <div style={{width: '100px'}}>
                            В подземных выборках
                        </div>
                    </div>
                    <div style={{overflowY: 'auto', height: '600px'}}>
                        {info.map(x=>{
                            return <LogItem x={x} />
                        })}
                    </div>
                </div>
        </>
    )
}