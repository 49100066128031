import React from 'react'
import { 
	Button,
	Input,
	Modal,
	Spin
} from 'antd'
import { 
	put_query
} from '../../functions'



// new scheme
export default class NewMine extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			save_loader: false,
			open: false,
			filename: '',
		}
	}

	saveMine(){
		this.setState({ save_loader: true })
		put_query('/mine', {
			name: this.state.filename
		}, true)
		.then((res) => {
			this.setState({ 
				save_loader: false,
				open: false,
				filename: ''
			}, () => {
				this.props.getData()
			})
		})
		.catch((err) => {
			this.setState({ save_loader: false })
		})
	}

	addListener(){
        document.addEventListener("keydown", this.listener);
    }

    removeListener(){
        document.removeEventListener("keydown", this.listener);
    }

    listener = (ev) => {
        if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            this.saveMine()
        }
    }

	render(){
		return(
			<>
				<Button
					style={{ width: '100%' }}
					type='primary'
					onClick={() => this.setState({
						open: true
					})}
				>
					Добавить шахту
				</Button>
				<Modal
					title={<b>Добавить новую шахту</b>}
					visible={this.state.open} 
					onCancel={() => {
						this.setState({ 
							open: false,
							filename: ''
						})
					}}
					footer={[
						<Button 
							key="back" 
							onClick={() => { 
								this.setState({ 
									open: false,
									filename: ''
								})
							}}
						>
							Отменить
						</Button>,
						<Button 
							key="submit" 
							type="primary" 
							loading={this.state.save_loader} 
							onClick={() => this.saveMine()}
						>
							Загрузить
						</Button>
					]}
					width={572}
				>
					<div className='dlg-img-body'>
						<p>
							<Input
								type='text'
								value={this.state.filename}
								onChange={(ev) => this.setState({ filename: ev.target.value })}
								placeholder='Название шахты'
							/>
						</p>
					</div>
				</Modal>
			</>
		)
	}
}


