import React from 'react'
import { connect } from 'react-redux'
import { clearAuth } from '../../functions'
import { UserIconImg } from './../../styles/icons'
import { Modal } from 'antd';

// Appbar user
class Appuser extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			confirm: false
		}
	}

	render(){
		return(
			<>
				<div className='appbar-user'>
					<p
						onClick={() => this.setState({ confirm: true })}
					>
						<UserIconImg />
					</p>
					<p 
						className='txt-light'
					>
						{this.props.user}
					</p>
				</div>
				<Modal
					title="Подтверждение"
					visible={this.state.confirm}
					onOk={() => clearAuth()}
					onCancel={() => this.setState({ confirm: false })}
					okText="Выйти"
					cancelText="Отмена"
				>
					<div style={{ padding: 16 }}>
						<h3>Вы уверены, что хотите выйти ?</h3>
					</div>
				</Modal>
			</>
		)
	}
}

function mapStateToProps(state){
	return {
		user: state.user_reducer.user
	}
}

export default connect(mapStateToProps)(Appuser)


