import React, {useEffect, useState} from 'react'
import {
    Button,
    Input,
    Modal, Select,
    Spin, TreeSelect
} from 'antd'
import {
    clearAuth,
    get_query,
    post_query, put_query, sso_query,
    upload_file
} from '../../functions'
import MineProductionSelectDate from "./MineProductionSelectDate";
import {setIsAdmin, setUser} from "../../actions/user_act";
import AuthForProduction from "./AuthForProduction";
const {Option} =  Select

export default function MineProduction(props){
    const [mineProduction, setMineProduction] = useState(0)
    const [error, setError] = useState('')
    const [mineProductionSelectedDateOpen, setMineProductionSelectedDateOpen] = useState(false)
    const [openEnterPassword, setOpenEnterPassword] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false)
    useEffect(()=>{

    }, [])

    const saveMine = () => {
        put_query('/production', {
            shift_id: props.shift_id,
            mine_id: props.mine,
            production_date: props.productionDate,
            volume: mineProduction
        }).then(()=>{
            console.log('успешно добавлено')
            setMineProduction(0)
            props.close()
        }).catch((err)=>{
            setError('Данные за эту смену уже внесены!')
        })
    }
    const validateProduction = (ev) => {
        if((/^\d+$/).test(ev.target.value)){
            setMineProduction(ev.target.value)
        }
    }

    const editProduction = () => {
        sso_query('/session/check')
            .then((res) => {
                if(res.user.is_admin) {
                    setMineProductionSelectedDateOpen(true)
                }else {
                    setOpenEnterPassword(true)
                }
            })
    }
    return(<>
        <Modal
            title={<b></b>}
            visible={props.open}
            onCancel={() => {setMineProduction(0); setError(''); props.close()}}
            footer={[
                <div style={{display:'flex', justifyContent:'space-between', margin: '0 80px'}}>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => setConfirm(true)}
                    >
                        Сохранить
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => editProduction()}
                    >
                        Редактировать
                    </Button>
                </div>
            ]}
            width={572}
        >
            <div className='dlg-img-body'>
                <div style={{display: "flex", justifyContent: "center"}}>
                    {props.productionDate}
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                   Диспетчер: {props.dispetcher}
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    Количество добытой руды за смену
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Input
                        type='text'
                        value={mineProduction}
                        onChange={(ev) => validateProduction(ev)}
                        placeholder='Выработка'
                        style={{width: '300px'}}
                    /> <div style={{display:"flex", flexDirection: 'column', justifyContent: 'center', marginLeft: '20px'}}>ТОНН</div>
                </div>
            </div>
        </Modal>
        <Modal
            title="Подтверждение"
            visible={confirm}
            onOk={() => saveMine()}
            onCancel={() => setConfirm(false)}
            okText="Да"
            cancelText="Нет"
        >
            <div style={{ padding: 16 }}>
                <h3>Сохранить введенное значение?</h3>
            </div>
            <div style={{display: "flex", justifyContent: "center", color: 'red'}}>
                {error}
            </div>
        </Modal>
            <AuthForProduction
                open={openEnterPassword}
                close={() => setOpenEnterPassword(false)}
                openEdit={() => {setOpenEnterPassword(false); setMineProductionSelectedDateOpen(true)}}
            />
        <MineProductionSelectDate
            open={mineProductionSelectedDateOpen}
            mine={props.mine}
            close={() => setMineProductionSelectedDateOpen(false)}
        />
        </>
    )
}