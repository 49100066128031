import React from 'react'
import PropTypes from 'prop-types'
import {  
    Select,
    Switch, 
    Modal,
    Button
} from 'antd'
import {
    ExclamationCircleOutlined
} from '@ant-design/icons'

const { Option } = Select
const { confirm } = Modal

// search control
export class MapSearch extends React.Component{
    render(){
        return(
            <div id='div-map-search'>
                {/* <Select
                    showSearch
                    placeholder="Введите название горизонта"
                    horizonts={this.props.horizonts} 
                    onSearch={(ev) => this.props.search(ev)} 
                    style={{ width: 284 }} 
                    filterOption={(input, option) =>
                        this.props.horizonts.filter( x => x.name.includes(input) )
                    }
                >
                    {
                        this.props.horizonts.map((h, id) => {
                            return(
                                <Option key={id} value={h.id}>{h.name}</Option>            
                            )
                        })
                    }
                </Select> */}
            </div>
        )
    }
}

// select mine
export class MineSelect extends React.Component{
    render(){
        return(
            <div id='div-mine-select'>
                <Select
                    showSearch
                    style={{ width: 225 }}
                    value={this.props.current}
                    onChange={(v) => this.props.change(this.props.horizonts[v])}
                    loading={this.props.loading}
                    notFoundContent={
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <p>Не найдено</p>
                        </div>
                    }
                    filterOption={(input, option) =>
                        option.children.indexOf(input) >= 0
                    }
                >
                    {
                        this.props.horizonts.map((h, id) => {
                            return(
                                <Option key={id} value={id}>{h.name}</Option>
                            )
                        })
                    }
                </Select>
            </div>
        )
    }
}

// select control
export class MapSelect extends React.Component{
    render(){
        return(
            <div id='div-map-select'>
                <Select 
                    showSearch
                    style={{ width: 225 }}
                    value={this.props.current} 
                    onChange={(v) => this.props.change(this.props.horizonts[v])}
                    loading={this.props.loading}
                    notFoundContent={
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <p>Не найдено</p>
                        </div>
                    } 
                    filterOption={(input, option) =>
                        option.children.indexOf(input) >= 0
                    }
                >
                    {
                        this.props.horizonts.map((h, id) => {
                            return(
                                <Option key={id} value={id}>{h.name}</Option>            
                            )
                        })
                    }
                </Select>
            </div>
        )
    }
}

// switch with left label
export class SwitchLabel extends React.Component{
    render(){
        return(
            <div className='div-flex-row' style={{ marginRight: 40 }}>
                <div style={{ marginRight: 16 }}>
                    {this.props.text}
                </div>
                <div style={{ width: 60 }}>
                    <Switch 
                        checked={this.props.checked}
                        onChange={() => this.props.change(!this.props.checked)}
                    />
                </div>
            </div>
        )
    }
}

// confirm dialog
export class Confirm extends React.Component{
    
    showConfirm() {
        let t = this
        confirm({
            title: this.props.title,
            icon: <ExclamationCircleOutlined />,
            content: <div className='body-content'>{this.props.content}</div>,
            okText: 'Да, удалить',
            cancelText: 'Отмена',
            onOk() {
                t.props.action()
            },
            onCancel() {
            },
        })
    }

    render(){
        return(
            <>
                {React.cloneElement(this.props.children, {
                    onClick: () => this.showConfirm()
                })}
            </>
        )
    }
}
Confirm.defaultProps = {
    title: 'Выдействительно хотите выполнить удаление'
}
Confirm.propsTypes = {
    title: PropTypes.string,
    action: PropTypes.func.isRequired
}

// Enter Button
export class ActionButton extends React.Component{

    componentDidMount(){
        this.addListener()
    }

    UNSAFE_componentWillReceiveProps(np){
        let open = Boolean(np.open)
		let old_open = Boolean(this.props.open)
		if(open && open !== old_open){
			this.removeListener()
		}
    }

    componentWillUnmount(){
        this.removeListener()
    }

    UNSAFE_componentDidUpdate(){
        // console.log('didupdate') 
    }

    addListener(){
        document.addEventListener("keydown", this.listener);
    }

    removeListener(){
        document.removeEventListener("keydown", this.listener);
    }

    listener = (ev) => {
        if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            this.props.onClick()
        }
    }

    render(){
        return(
            <Button
                loading={this.props.loading}
                type={this.props.type}
                onClick={() => this.props.onClick()}
            >
                {this.props.children}
            </Button>
        )
    }
}