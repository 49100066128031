import React from 'react'
import {
	Modal,
	Button,
	Form,
	Input
} from 'antd'
import {post_query} from "../../functions";
import {connect} from "react-redux";

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}


// change Pswd 
class PswdChange extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			open: false,
			old_password: '',
			new_password: '',
			confirm_password: '',
		}
	}

	okAction(){
		if(this.state.new_password==this.state.confirm_password) {
			let r = {id: '', password: ''}
			r.id = this.props.id
			r.login = this.props.login
			r.fio = this.props.fio
			r.password = this.state.new_password
			r.old_password = this.state.old_password
			post_query(`/user/${r.id}`, r, true)
				.then((res) => {
					console.log('права установлены')
				}).then(() => {
					this.setState({
						open: false,
						old_password: '',
						new_password: '',
						confirm_password: '',
					})
				}
			).catch((err) => {
				console.log(err)
			})
		}else{
			alert('Новый пароль и подтверждение пароля не совпадают')
		}
	}

	cancelAction(){
		this.setState({ 
			open: false,
			old_password: '',
			new_password: '',
			confirm_password: '',
		})
	}

	render(){
		return(
			<>
				<Button
					onClick={() => this.setState({ open: true })}
				>
					Смена пароля
				</Button>
				<Modal
					title={<b>Сменить пароль</b>}
					visible={this.state.open} 
					onOk={() => this.okAction()} 
					onCancel={() => this.cancelAction()}
					okText='Сохранить'
					cancelText='Отменить'
				>
					<div className='modal-body'>
						<Form
							{...layout}
						>
							<Form.Item
								label="Старый пароль:"
							>
								<Input 
									placeholder='Введите старый пароль'
									value={this.state.old_password}
									onChange={(ev) => this.setState({ old_password: ev.target.value })}
								/>
							</Form.Item>
							<Form.Item
								label="Новый пароль:"
							>
								<Input 
									placeholder='Введите новый пароль'
									value={this.state.new_password}
									onChange={(ev) => this.setState({ new_password: ev.target.value })}
								/>
							</Form.Item>
							<Form.Item
								label="Подтвердить пароль:"
							>
								<Input 
									placeholder='Подтверлите ввод нового пароля'
									value={this.state.confirm_password}
									onChange={(ev) => this.setState({ confirm_password: ev.target.value })}
								/>
							</Form.Item>
						</Form>
					</div>
				</Modal>
			</>
		)
	}
}

function mapStateToProps(state){
	return {
		id: state.user_reducer.id,
		login: state.user_reducer.login,
		fio: state.user_reducer.fio,
	}
}

export default connect(mapStateToProps, )(PswdChange)



