import GalkaPng from './galka.png'
import PencilPng from './pencil.png'
import XlsPng from './Xls.png'
import UserIconSvg from './user_icon.svg'
import CompanySvg from './company.svg'
import CompanyLiteSvg from './company_lite.svg'
import { ReactComponent as ClockSvg } from './clock.svg'
import { ReactComponent as ClockFullSvg } from './clock_full.svg'
import { ReactComponent as IconWifiSvg } from './icon_wifi.svg'
import { ReactComponent as IconWifiOfflineSvg } from './icon_wifi_offline.svg'
import IconWifiImg from './icon_wifi.png'
import { ReactComponent as IconUserSvg } from './icon_user.svg'
import { ReactComponent as IconTransportSvg } from './icon_transport.svg'
import IconTransportImg from './icon_transport.png'
import {ReactComponent as IconDoSvg} from './icon_camera.svg'
export const GalkaImg = (props) => <img src={GalkaPng} {...props} alt='GalkaImg' />
export const PencilImg = (props) => <img src={PencilPng} {...props} alt='PencilImg'/>
export const XlsImg = (props) => <img src={XlsPng} {...props} alt='XlsImg'/>
export const UserIconImg = (props) => <img src={UserIconSvg} {...props} alt='UserIconSvg'/>
export const CompanyImg = (props) => <img src={CompanySvg} {...props} alt='CompanySvg'/>
export const CompanyLiteImg = (props) => <img src={CompanyLiteSvg} {...props} alt='CompanyLiteSvg'/>
export const ClockVector = (props) => <ClockSvg {...props} alt='ClockSvg'/>
export const ClockFullVector = (props) => <ClockFullSvg {...props} alt='ClockFullSvg'/>
export const UserVector = (props) => <IconUserSvg {...props} alt='IconUserSvg'/>
export const TransportVector = (props) => <IconTransportSvg {...props} alt='IconTransportSvg'/>
export const DoVector = (props) => <IconDoSvg {...props} alt='IconDoSvg' />
export const WifiVector = (props) => <IconWifiSvg {...props} alt='IconWifiSvg'/>
export const WifiOfflineVector = (props) => <IconWifiOfflineSvg {...props} alt='IconWifiOfflineSvg'/>
export const WifiImg = (props) => <img src={IconWifiImg} alt='IconWifiImg'/>
export const TransportImg = <img src={IconTransportImg} alt='IconTransportImg'/>

