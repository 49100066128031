import React from 'react'
import {
	Modal,
	Button,
	Form,
	Input, Select
} from 'antd'
import { Confirm } from './../../containers/modules/elems'
import {get_query} from "../../functions";

const layout = {
	labelCol: { span: 8 },
	wrapperCol: { span: 16 },
}

// reader work modal
export default class CounterModal extends React.Component {
	constructor(props){
		super(props)
		this.state = {

		}
	}
	componentDidMount() {
		get_query('/counter').then(res=>{
			let options_tmp = []
			res.data&&res.data.map(
				counter=>{
					options_tmp.push({
						value:counter.id,
						label:counter.name
					})
				}
			)
			this.setState({options:options_tmp})
		})
	}
	save(){
		this.props.save(this.state)
	}
	handleChange(value) {
		this.setState({counter_id:value})
	};
	UNSAFE_componentWillReceiveProps(np){
		let open = Boolean(np.open)
		let old_open = Boolean(this.props.open)
		if(open && open !== old_open){
			this.addListener()
			this.setState(Object.assign({}, this.state, np.open))
		}
		if(!open && open !== old_open){
			this.removeListener()
		}
	}

	addListener(){
        document.addEventListener("keydown", this.listener);
    }

    removeListener(){
        document.removeEventListener("keydown", this.listener);
    }

    listener = (ev) => {
        if (ev.code === "Enter" || ev.code === "NumpadEnter") {
            this.save()
        }
    }
	render(){
		return(
			<Modal
				title={<b>{this.state.name}</b>}
				visible={this.props.open} 
				onCancel={() => this.props.close()}
				footer={[
					<Confirm
						key='confirm-key'
						title='Вы действительно хотите удалить счетчик?'
						content=' '
						action={() => this.props.delete(this.state.id, this.state.uuid)}
					>
						<Button
							loading={this.props.loader}
							key="back-key"
							className={!this.props.open.id&&'hidden'}
						>
							Удалить
						</Button>
					</Confirm>,
					<Button
						key="submit-key"
						type="primary"
						loading={this.props.loader}
						onClick={() => this.save()}
						className={this.props.open.id&&'hidden'}
					>
						Сохранить
					</Button>
				]}
				width={440}
			>
				<div className='modal-body'>
					<Form
						{...layout}
					>
						<Form.Item label="Счетчик">
							<Select
								style={{ width: 120 }}
								onChange={(v)=>this.handleChange(v)}
								options={this.state.options}
								value={this.props.open.id}
								disabled={this.props.open.id}
							>
							</Select>
						</Form.Item>
						<Form.Item
							label="Координата X"
						>
							<Input 
								disabled
								value={this.state.coord_x}
							/>
						</Form.Item>
						<Form.Item
							label="Координата Y"
						>
							<Input
								disabled
								value={this.state.coord_y}
							/>
						</Form.Item>
					</Form>
				</div>
			</Modal>
		)
	}
}


