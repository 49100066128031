import React from 'react';
import {
	Input,
	Select
} from 'antd';
import GeoPlane from './../../containers/modules/GeoPlane';
import SchemeConf from './SchemeConf';
import { get_query } from '../../functions';
import {del_memory, findMap, set_memory} from "../../functions/memory";
import WorkersForScheme from "../../containers/modules/WorkersForScheme";
import {XlsImg} from "../../styles/icons";

const { Option } = Select;

// Scheme page
export default class Scheme extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			loader: false,
			view_readers: true,
			view_workers: true,
			view_tmp_dots: false,
			view_zones: true,
			view_alertzones: true,
			view_do_1: true,
			view_do_2: true,
			search_act: null,
			search_res: [],
			workers_online: 0,
			transport_online: 0,
			search_timeout: 0,
			search_action_timeout: 0,
			workers: [],
			horizons_all: [],
			horizons: [],
			counter_name: '',
			current: false, // текущая карта
			current_mine: false,
			mines: [],		// шахты
			horizonts: [],	// карты
			activeTab: 1,
			workersForTab: [],
			worker_types: [],
			selected_worker_type: 0,
			job_titles: [],
			selected_job_title: 0,
			selected_status: 0,
			selected_horizon: 0,
			search_input: '',
			search_in_table: '',
			count: 0
		};
		this.geoplane = React.createRef();
		this.workersforscheme = React.createRef();
	}

	componentDidMount() {
		this.getData(true, true);
		this.getTypes();
		this.getJobTitle();
	}

	getTypes() {
		return new Promise((resolve, reject) => {
			get_query(`/workertype/`, {}, false)
				.then((res) => {
					if (res.data) {
						this.setState({ worker_types: res.data });
					}
					resolve(res.data);
				})
				.catch((err) => {
					// reject()
					resolve(false);
				});
		});
	}

	getJobTitle() {
		return new Promise((resolve, reject) => {
			get_query(`/workerjobtitle/`, {}, false)
				.then((res) => {
					if (res.data) {
						this.setState({ job_titles: res.data });
					}
					resolve(res.data);
				})
				.catch((err) => {
					// reject()
					resolve(false);
				});
		});
	}

	filterCat = (res) => {
		this.setState({ selected_worker_type: res, selected_job_title: 0, selected_status: 0, selected_horizon: 0 });
	}

	filterJobTitle = (res) => {
		this.setState({ selected_worker_type: 0, selected_job_title: res, selected_status: 0, selected_horizon: 0 });
	}

	filterStatus = (res) => {
		this.setState({ selected_worker_type: 0, selected_job_title: 0, selected_status: res, selected_horizon: 0 });
	}

	filterHorizon = (res) => {
		this.setState({ selected_worker_type: 0, selected_job_title: 0, selected_status: 0, selected_horizon: res });
	}

	setCounterName = (res) => {
		this.setState({ counter_name: res });
	}

	setActiveTab = (res, workers = []) => {
		this.setState({
			activeTab: res,
			workersForTab: workers,
			search_in_table: '' // Сбрасываем search_in_table при переключении табов
		});
	}

	setHorizonActiveTab = (res) => {
		this.setState({ activeTab: res });
		/*		if(this.geoplane.current&&this.geoplane.current.check_workers()) {
			this.setState({workersForTab: this.geoplane.current.check_workers()})
		}*/
	}

	setCount = (count) => {
		this.setState({ count: count });
	}

	// поисковой запрос
	setWorkersOnlineCount = (res) => {
		if (res.data.workers.length > 0) {
			let workers = res.data.workers.filter(x => x.worker_type_id == 2 || x.worker_type_id == 3);
			let transport = res.data.workers.filter(x => x.worker_type_id == 1);
			this.setState({ workers: res.data.workers, workers_online: workers.length, transport_online: transport.length });
		} else {
			this.setState({ workers_online: 0, transport_online: 0 });
		}
	}

	findWorker(search) {
		if (search.length > 1) {
			this.setState({ loader: true });
			clearTimeout(this.state.search_timeout);
			let search_timeout = setTimeout(() =>
				get_query(`/worker/filter/search=${search}`, {}, true)
					.then((res) => {
						this.setState({ search_res: res.data.workers ? res.data.workers : [], loader: false });
					})
					.catch((err) => {
						this.setState({ search_res: [], loader: false });
					}), 1000);
			this.setState({ 'search_timeout': search_timeout });
		} else {
			clearTimeout(this.state.search_timeout);
			this.setState({ search_res: [] });
		}
	}

	// поисковое действие
	searchAct(worker) {
		let worker_tmp = this.state.search_res[worker];
		clearInterval(this.state.search_action_timeout);
		this.setState({ search_act: worker_tmp.worker_id }, () => {
			let f_w = this.state.search_res.find(x => x.worker_id === worker_tmp.worker_id && x.horizon_id === worker_tmp.horizon_id);
			window.open(`/scheme?horizon=${worker_tmp.horizon_id}`, '_blank');
			/*			this.findWorkerCoords(f_w)*/
		});
		/*		let search_action_timeout = setInterval(()=>
			get_query(`/worker/filter/search=${worker_tmp.worker_id}`, {}, true)
				.then((res) => {
					this.setState({ search_res: res.data.workers ? res.data.workers : [], loader: false })
					if(this.state.search_res[0]){
						this.geoplane.current.findWorkerCoords(this.state.search_res[0])
					}
				})
				.catch((err) => {
					this.setState({ search_res: [], loader: false })
				}), 5000)
		this.setState({'search_action_timeout': search_action_timeout})*/
	}

	getData(change, change_mine) {
		this.setState({ loader: true });
		Promise.all([
			get_query('/mine', {}, true).then((res) => {
				this.setState({
					mines: res.data
				}, () => {
					if (typeof change_mine === 'object') {
						this.changeMine(change_mine);
					} else if (typeof change_mine === 'boolean' && change_mine) {
						var mine = findMap(res.data, 'mine_id');
						this.changeMine(mine ? mine : false);
					}
				});
			}).catch((err) => {
				console.log(err);
			}),
			get_query('/horizon', {}, true)
				.then((res) => {
					this.setState({
						horizons_all: res.data,
						horizonts: res.data.filter(x => x.mine_id == this.state.current_mine.id),
					}, () => {
						if (window.location.search != '') {
							let tmp = window.location.search.split('=');
							let hor = this.state.horizons_all.find(x => x.id == tmp[1]);
							change = hor;
						}
						if (typeof change === 'object') {
							this.changeMap(change);
						} else if (typeof change === 'boolean' && change) {
							var map = findMap(res.data, 'map_id');
							this.changeMap(map ? map : false);
						} else if (typeof change === 'boolean' && !change) {
							var map2 = res.data.filter(x => x.mine_id == this.state.current_mine.id)[0];
							this.changeMap(map2 ? map2 : false);
						}
					});
					this.setState({ loader: false });
				})
				.catch((err) => {
					this.setState({
						horizonts: [],
						loader: false,
						current: false,
						change_mine: false
					});
				})
		]);
	}

	findWorkerCoords(worker) {
		if (worker.horizon_id != this.state.current.id) {
			let tmp_current = this.state.horizons_all.find(x => x.id === worker.horizon_id);

			if (this.state.current_mine.id != tmp_current.mine_id) {
				let tmp_current_mine = this.state.mines.find(x => x.id == tmp_current.mine_id);
				this.changeMine(tmp_current_mine);
			}
			this.changeMap(tmp_current);
		}
	}

	searchInTable() {
		let t = this;
		this.setState({ search_in_table: t.state.search_input });
	}

	changeMine(current_mine, refreshHorizon) {
		if (current_mine) {
			set_memory(current_mine.id, 'mine_id');
			this.setState({
				current_mine: current_mine
			}, () => {
				if (refreshHorizon) {
					this.getData(false, current_mine);
				}
			});
		} else {
			del_memory('mine_id');
		}
	}

	changeMap(current) {
		this.setActiveTab(1, []);
		if (current) {
			set_memory(current.id, 'map_id');
			this.setState({
				current: current,
				ac: [],
				worker: [],
				tmp_dots: [],
				equipment: [],
			}, () => {
				// Additional actions if needed
			});
		} else {
			del_memory('map_id');
		}
	}

	render() {
		let options = this.state.search_res.map((d, id) => {
			let found = this.state.horizons_all.find(x => x.id == d.horizon_id);
			if (found) {
				return <option key={id} value={id} title={d.fio + ' - ' + d.worker_id + ' - ' + found.name}>{d.fio} - {d.worker_id} - {found.name}</option>;
			}
		});

		return (
			<>
				<div className='page-title'>
					<p className='txt-title'>
						Мнемосхема горизонтов
					</p>
				</div>
				<div>
					<SchemeConf
						view_readers={this.state.view_readers}
						view_workers={this.state.view_workers}
						view_zones={this.state.view_zones}
						view_alertzones={this.state.view_alertzones}
						view_do_1={this.state.view_do_1}
						view_do_2={this.state.view_do_2}
						change={(f, v) => this.setState({ [f]: v })}
						horizons={this.state.horizons}
						horizonts={this.state.horizonts}
						mines={this.state.mines}
						current={this.state.current}
						current_mine={this.state.current_mine}
						changeMine={(v) => this.changeMine(v, true)}
						changeMap={(v) => this.changeMap(v)}
					/>
				</div>
				<div className='div-flex-row' style={{ textTransform: 'uppercase', justifyContent: 'space-between' }}>
					<div>
						<span>Шахта {this.state.current_mine.name}</span><span style={{ marginLeft: '10px' }}>{this.state.current.name}</span>{this.state.activeTab == 2 && this.state.workersForTab < 1 ? <span style={{ marginLeft: '10px' }}>Общее количество персонала: {this.state.count}</span> : ''}
					</div>
					<div>{this.state.activeTab != 1 ? <XlsImg style={{ width: '50px', cursor: 'pointer' }} onClick={() => this.workersforscheme.current.exportWorkersForTab('Workers')} /> : ''}</div>
				</div>
				<div className='div-flex-row' style={{ height: 'calc(100% - 240px)', position: 'relative' }}>
					{this.state.loader == true ? <div style={{ display: 'flex', position: "absolute", background: 'gray', opacity: '0.8', zIndex: '100000', top: '0px', bottom: '0', left: '0', right: '0', justifyContent: "center", alignItems: 'center' }}><div style={{ fontSize: '24px' }}>Загрузка горизонта</div></div> : ''}
					<div className='div-geo-container margin-container-30' style={{ marginRight: '0' }}>
						<div style={{ display: "flex" }}>
							<div style={{ display: "flex" }}>
								<div className={this.state.activeTab == 1 ? 'scheme-tab active' : 'scheme-tab'} onClick={() => this.setActiveTab(1, [])}>Схема</div>
								<div className={this.state.activeTab == 2 ? 'scheme-tab active' : 'scheme-tab'} onClick={() => this.setActiveTab(2, [])}>Персонал</div>
								{this.state.workersForTab < 1 ? <div className={this.state.activeTab == 3 ? 'scheme-tab active' : 'scheme-tab'} onClick={() => this.setActiveTab(3, [])}>Техника</div> : ''}
								{this.state.activeTab == 1 ? (
									<>
										<Select
											loading={this.state.loader}
											showSearch
											value={this.state.search_act}
											placeholder="Фамилия или id сотрудника"
											style={this.props.style}
											defaultActiveFirstOption={false}
											filterOption={false}
											onSearch={(ev) => this.findWorker(ev)}
											onSelect={(ev) => this.searchAct(ev)}
											notFoundContent={null}
											style={{ width: '300px' }}
											allowClear={true}
											onClear={() => {
												this.setState({ search_act: null });
												clearInterval(this.state.search_action_timeout);
											}}
										>
											{options}
										</Select>
										<div className={'searchBtn'}>Найти</div>
									</>
								) : (
									<>
										<div>
											<Input onChange={e => this.setState({ search_input: e.target.value })} />
										</div>
										<div className={'searchBtn'} onClick={() => this.searchInTable()}>Найти</div>
										<div style={{ marginLeft: '10px' }}>
											<span style={{ marginRight: '10px' }}>Фильтры</span>
											<Select
												type='number'
												value={this.state.selected_worker_type}
												onSelect={(ev) => this.filterCat(ev)}
											><Option id={0} value={0}>Категория</Option>
												{this.state.worker_types.map(x => {
													return <Option id={x.id} value={x.id}>{x.name}</Option>;
												})}
											</Select>
											<Select
												type='number'
												value={this.state.selected_job_title}
												onSelect={(ev) => this.filterJobTitle(ev)}
											><Option id={0} value={0}>Должность</Option>
												{this.state.job_titles.map(x => {
													return <Option id={x.id} value={x.id}>{x.name}</Option>;
												})}
											</Select>
											<Select
												type='number'
												value={this.state.selected_status}
												onSelect={(ev) => this.filterStatus(ev)}
											><Option id={0} value={0}>Статус</Option>
												<Option id={1} value={'Онлайн'}>Онлайн</Option>
												<Option id={2} value={'Оффлайн'}>Оффлайн</Option>
												<Option id={3} value={'Не на смене'}>Не на смене</Option>
											</Select>
											<Select
												type='number'
												value={this.state.selected_horizon}
												onSelect={(ev) => this.filterHorizon(ev)}
											><Option id={0} value={0}>Горизонт</Option>
												{this.state.horizonts.map(x => {
													return <Option id={x.id} value={x.id}>{x.name}</Option>;
												})}
											</Select>
										</div>
									</>
								)}
							</div>
							<div>

							</div>
						</div>
						{this.state.activeTab == 1 ? (
							<GeoPlane
								ref={this.geoplane}
								do_1={false}
								do_2={false}
								view_readers={this.state.view_readers}
								view_workers={this.state.view_workers}
								view_tmp_dots={this.state.view_tmp_dots}
								view_zones={this.state.view_zones}
								view_alertzones={this.state.view_alertzones}
								view_do_1={this.state.view_do_1}
								view_do_2={this.state.view_do_2}
								search_act={this.state.search_act}
								setWorkersOnlineCount={this.state.setWorkersOnlineCount}
								setCounterName={this.state.setCounterName}
								current={this.state.current}
								current_mine={this.state.current_mine}
								hide_select={true}
								setActiveTab={this.setActiveTab}
							/>
						) : (
							<WorkersForScheme
								key={this.state.activeTab} // Добавляем ключ для перерендера
								ref={this.workersforscheme}
								mine={this.state.current_mine}
								horizon={this.state.current}
								horizons={this.state.horizons_all}
								activeTab={this.state.activeTab}
								workersForTab={this.state.workersForTab}
								selected_worker_type={this.state.selected_worker_type}
								selected_job_title={this.state.selected_job_title}
								selected_status={this.state.selected_status}
								selected_horizon={this.state.selected_horizon}
								search_in_table={this.state.search_in_table}
								setCount={this.setCount}
							/>
						)}
					</div>
				</div>
			</>
		);
	}
}
