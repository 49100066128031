import React from 'react'
import {
	Button, Input,
	Select
} from 'antd'
import { fontsw } from './../../constants'
import { 
	get_body_font,
	set_body_font
} from '../../functions/ui'
import {get_query} from "../../functions";

const { Option } = Select


// Font switcher
export default class PropTime extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			hours: ''
		}
	}
	componentDidMount() {
		get_query('/settings/gettimebeforemovingtolamp').then(res=>{
			this.setState({'hours': res.time/3600})
		}).catch(err=>{
			console.log(err)
		})
	}

	saveHours =()=>{
		get_query('/settings/settimebeforemovingtolamp/'+this.state.hours*3600)
	}
	render(){
		return(
			<div
				className='div-flex-row'
				style={{
					padding: '32px 24px 36px 24px'
				}}
			>
				<Input
					type={'number'}
					style={{ width: '100%' }}
					value={this.state.hours}
					onChange={(v) => {
						this.setState({hours: v.target.value})
					}}
				/>
				<Button
					type='primary'
					style={{
						marginLeft: 12,
						width: 167
					}}
					onClick={()=>{
						this.saveHours()
					}}
				>
					Сохранить
				</Button>
			</div>
		)
	}
}


