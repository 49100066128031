import React, {useEffect, useState} from 'react'
import {
	Col, Progress, Row,
	Select, Table
} from 'antd'
import {user_reducer} from "../../reducers/worker_reducer";
import store from "../../reducers/store";
import { get_query } from '../../functions'
import Dashinformercircle from "./Dashinformercircle";
import Dashinformercirclethin from "./Dashinformercirclethin";
import {useSelector} from "react-redux";
import {PencilImg} from "../../styles/icons";
import MineProduction from "../../containers/modules/MineProduction";
import WorkerByDate from "../../containers/modules/WorkerByDate";

export default function Dash(){
	let day = new Date()
	const [shifts, setShifts] = useState([])
	const [shift, setShift] = useState({})
	const [shiftBefore, setShiftBefore] = useState({})
	const [mines, setMines] = useState([{id:0, name: 'Выбрать шахту'}])
	const [selectedMine, setSelectedMine] = useState(0)
	const [timerTmp, setTimerTmp] = useState(0)
	const [timerTmp2, setTimerTmp2] = useState(0)
	const [horizons, setHorizons] = useState([])
	const [horizonsMax, setHorizonsMax] = useState(0)
	const [horizonsCount, setHorizonsCount] = useState([])
	const [workersTitles, setWorkersTitles] = useState([])
	const [workersJobTitleMax, setWorkersJobTitleMax] = useState(0)
	const [workersTitlesCount, setWorkersTitlesCount] = useState([])
	const [general, setGeneral] = useState({total: 0, online: 0})
	const [generalPercent, setGeneralPercent] = useState(0)
	const [workerItr, setWorkerItr] = useState({itr: 0, workers: 0})
	const [percentItr, setPercentItr] = useState(0)
	const [mineProductionOpen, setMineProductionOpen] = useState(false)
	const [productionReport, setProductionReport] = useState([])
	const [workerCount, setWorkerCount] = useState([])
	const [productionMax, setProductionMax] = useState(0)
	const [driverHoursMax, setDriverHoursMax] = useState(0)
	const [hoursProductionMax, setHoursProductionMax] =useState(0)
	const [production, setProduction] = useState([])
	const [ready, setReady] = useState(true)
	const [ready2, setReady2] = useState(true)
	useEffect(()=>{
		get_query('/mine').then(res=>{
			localStorage.setItem('id_mine_dash', res.data[0].id)
			setMines(res.data)
			setSelectedMine(res.data[0].id)
			startTimer(res.data[0].id)
			startTimer2(res.data[0].id)
		})
		getShift()
		return () => {
			clearInterval(timerTmp)
			clearInterval(timerTmp2)
			setTimerTmp(0)
			setTimerTmp2(0)
		}
	}, [])
	const getShift = () => {
		get_query('/shift').then(res=>{
			setShifts(res.data)
			checkShifts(res.data)
		})
	}
	const getSelectedMine = () => {
		return selectedMine
	}
	const checkShifts = (shifts) => {
		let shift_tmp = {name: 'Смена не определена'}
		if(shifts&&shifts.length>0) {
			let timeNow = checkDate(day.getHours()) + ':' + checkDate(day.getMinutes())
			for (let i = 0; i < shifts.length; i++) {
				if ((shifts[i].start_time <= timeNow) && shifts[i].end_time > timeNow) {
					shift_tmp = {
						name: shifts[i].name + ' (' + shifts[i].start_time + ' - ' + shifts[i].end_time + ') ',
						id: shifts[i].id
					}
				}
				setShift(shift_tmp)
			}
		}else{
			setShift(shift_tmp)
		}
	}
	const checkDate = (dateInput) =>{
		if(dateInput<10){
			dateInput='0'+dateInput
		}
		return dateInput
	}
		const startTimer = (e) =>{
			let timer_tmp = setTimeout(
				function quer() {
					Promise.all([
					get_query(`/stat/online/${e}`).then(res => {
						setHorizons(res.data.horizon)
						let m = Math.max.apply(Math, res.data.horizon.map(function (o) {
							return o.count
						}))
						setHorizonsMax(m)
						let horizons_count_tmp = []
						for (let i = 1; i <= 2; i++) {
							let tmp = Math.ceil(m - m / 4 * i)
							if (tmp != 1 && tmp != 0) {
								horizons_count_tmp.push(tmp)
							}
						}
						setHorizonsCount(horizons_count_tmp)
						setWorkersTitles(res.data.worker_job_title)
						let w = Math.max.apply(Math, res.data.worker_job_title.map(function (o) {
							return o.count
						}))
						setWorkersJobTitleMax(w)
						let worker_job_title_count_tmp = []
						for (let i = 2; i >= 1; i--) {
							let tmp = Math.ceil(w - w / 4 * i)
							if (tmp != 1 && tmp != 0) {
								worker_job_title_count_tmp.push(tmp)
							}
						}
						setWorkersTitlesCount(worker_job_title_count_tmp)
						setGeneral(res.data.general)
						if (res.data.general.online != 0) {
							let percent_general_tmp = Math.ceil(res.data.general.online / res.data.general.total * 100)
							setGeneralPercent(percent_general_tmp)
						} else {
							setGeneralPercent(0)
						}
						setWorkerItr(res.data.worker_itr)
						let percent_itr = 0;
						if(res.data.worker_itr.itr!= 0) {
							percent_itr = res.data.worker_itr.itr / res.data.general.online * 100
						}
						setPercentItr(percent_itr)
					}).catch(e => {
						console.log(e)
					})
					]).then(()=>{
						if(e!=localStorage.getItem('id_mine_dash')){
							return
						}
						timer_tmp = setTimeout(quer, 5000)
						setTimerTmp(timer_tmp)
					})
			}, 5000)
			setTimerTmp(timer_tmp)
		}
		useEffect(()=>{checkShifts(shifts)
			return () => {
				clearInterval(timerTmp)
				clearInterval(timerTmp2)
				setTimerTmp(0)
				setTimerTmp2(0)
			}
		}, [shiftBefore]);
		const startTimer2 = (e) =>{
			let timer_tmp2 = setTimeout(
				function quer2() {
					Promise.all([
						get_query(`/stat/report/${e}`).then((res) => {

							setShiftBefore(res.data[res.data.length - 1])
							let dh = Math.max.apply(Math, res.data.map(function (o) {
								return o.driver_hours
							}))
							setDriverHoursMax(dh)

							get_query(`/stat/production/${e}`).then(res2 => {
								let wc =[]
								res.data.map(x => {
									let date_tmp = x.date.split('-')
									x.date_shift = date_tmp[2] + '.' + date_tmp[1] + '.' + date_tmp[0].slice(2) + '(' + x.number + ')'
									let prodTmp = res2.data.find(y => y.date == x.date && y.shift_id == x.shift_id)
									x.production = prodTmp.production
									wc.push({date_shift: date_tmp[2] + '.' + date_tmp[1] + '.' + date_tmp[0].slice(2) + '(' + x.number + ')', max_count: x.max_count})
								})
								setProductionReport(res.data)
								get_query(`/stat/online/${e}`).then(res => {
									wc.push({date_shift: new Date().toLocaleString(), max_count: res.data.general.online})
									setWorkerCount(wc)
								})
								let p = Math.max.apply(Math, res2.data.map(function (o) {
									return o.production
								}))
								setProductionMax(p)
								if(dh>p){
									setHoursProductionMax(dh)
								}else{
									setHoursProductionMax(p)
								}
							}).catch(err => {
								console.log(err)
							})
						}).catch((err)=>{
							console.log(err)
						})]).then(()=>{
							console.log(e)
						if(e!=localStorage.getItem('id_mine_dash')){
							return
						}
						timer_tmp2 = setTimeout(quer2, 5000)
						setTimerTmp2(timer_tmp2)
					})
				}, 5000)
			setTimerTmp2(timer_tmp2)
		}

		const user= useSelector((state)=>state.user_reducer)
		const changeMine = (e) =>{
			try {
				localStorage.setItem('id_mine_dash', e)
				clearInterval(timerTmp)
				clearInterval(timerTmp2)
				setTimerTmp(0)
				setTimerTmp2(0)
				setSelectedMine(e)
				startTimer(e)
				startTimer2(e)
			}catch {
				console.log('ошибка!')
			}
		}
		let monthToday = day.getMonth()+1;
		let todayFullDate = checkDate(day.getDate())+'.'+checkDate(monthToday)+'.'+day.getFullYear()+' '+day.getHours()+':'+checkDate(day.getMinutes())
	return(
		mines.length>0&&mines[0].name!="Выбрать шахту"?<>
				<Row>
					<Col span={24}>
						<Row style={{marginTop: '20px'}}>
							<Col span={20}>
								<div style={{fontSize:'24px', textTransform: 'uppercase', fontWeight: 'bold'}}>
									<select value={selectedMine} onChange={e=>changeMine(e.target.value)}>
										{mines.map(x=>{return <option value={x.id}>{x.name}</option>})}
									</select>
									<span style={{color:'#3e3e3e', marginLeft: '10px'}}>{todayFullDate+' '+shift.name}</span>
								</div>
								<div style={{fontSize: '18px', color:'#3d3d3d'}}>
									{user.fio}
								</div>
							</Col>
							<Col span={4} style={{display:"flex", justifyContent:'end'}}>
								<PencilImg style={{height:'34px', cursor: 'pointer'}} onClick={()=>setMineProductionOpen(true)}/>
							</Col>
						</Row>
						<Row>
							<Col span={24} style={{height:'2px', backgroundColor:'#ffffff', marginBottom:'20px', marginTop: '10px'}}></Col>
						</Row>
						<Row className={'horizons_informer'}>
							<Col span={18}>
								<Row style={{height: '100%'}} gutter={12}>
									<Col span={1} style={{display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
										<div>{horizonsMax>0&&horizonsMax}</div>
										{horizonsCount&&horizonsCount.map(x=>{
											return <div key={x}>{x}</div>
										})}
										<div>0</div>
									</Col>
									<Col span={23}>
										<Row style={{height: '100%', overflowX: 'auto'}} gutter={12}>
											{horizons&&horizons.map(horizon=>{
												let percent = horizon.count/horizonsMax*100
												if(percent==0){
													percent=1
												}
												return <Col span={2} style={{display: "flex", flexDirection:'column', justifyContent: 'end'}}>
													<div style={{textAlign: "center"}}>{horizon.count}</div><div style={{width: '100%', height: `${percent}%`, backgroundColor: '#1e497e', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}></div>
												<div style={{fontSize: '12px', overflow: 'hidden', whiteSpace: 'nowrap'}} title={horizon.name}>{horizon.name}</div>
											</Col>})}
										</Row>
									</Col>
								</Row>
							</Col>
							<Col span={6}>
								<Row style={{height:'100%'}}>
									<Col span={24} style={{display:"flex", flexDirection:"column", justifyContent:'end'}}>
										<div style={{maxHeight: '180px', overflowY: 'auto'}}>
										{workersTitles.map(workerTitle=>{
											let percent = workerTitle.count/workersJobTitleMax*100
											if(percent>0) {
												return <Row>
													<Col span={11}>
														<div style={{
															height: '30px',
															textAlign: 'end',
															lineHeight: 1
														}}>{workerTitle.name}</div>
													</Col>
													<Col span={13} style={{paddingLeft: '6px'}}>
														<div style={{
															width: `${percent}%`,
															height: '27px',
															backgroundColor: '#1e497e',
															borderBottomRightRadius: '10px',
															borderTopRightRadius: '10px',
															marginBottom: '3px'
														}}></div>
													</Col>
												</Row>
											}
										})}
										</div>
										<Row style={{marginTop:'8px'}}>
											<Col span={13} offset={11} style={{display: 'flex', justifyContent: "space-between"}}>
												<div>0</div>
												{workersTitlesCount&&workersTitlesCount.map(x=>{
													return <div>{x}</div>
												})}
												<div>{workersJobTitleMax>0&&workersJobTitleMax}</div>
											</Col>
										</Row>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col style={{fontSize: '20px', color: '#3e3e3e', textTransform: 'uppercase', fontWeight: 'bold'}}>
								персонал на выходе в режиме онлайн
							</Col>
						</Row>
						<Row style={{marginTop:'20px', borderBottom: '2px solid white'}} className={'informers_circle'}>
							<Col span={10} style={{borderRight:'2px solid white', display: "flex", flexDirection: 'column', justifyContent: 'end', height: '100%'}}>
								<Row style={{marginBottom: '20px'}}>
									<Col span={5}></Col>
									<Col span={6}><Dashinformercirclethin percent={generalPercent} all={general.total} worker={general.online} name='всего ламп' id_canvas={'lamp_canvas'}/></Col>
									<Col span={2}></Col>
									<Col span={6}><Dashinformercircle percent={percentItr} all={general.online} worker={workerItr.workers} id_canvas={'licence_canvas'}/></Col>
									{/*<Col span={6}><Dashinformercircle percent={86} all={149} worker={129} id_canvas={'worker_canvas'}/></Col>*/}
									<Col span={5}></Col>
								</Row>
								<Row align={'center'}>
									<Col span={24} style={{textAlign:'center', textTransform:'uppercase', fontSize:'18px', fontWeight:'bold'}}>выдача светильников в ламповой</Col>
								</Row>
							</Col>
							<Col span={14} style={{height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
								<Row>
									<Col span={24} style={{textAlign: 'center', textTransform: 'uppercase', fontWeight: 'bold', fontSize: '12px'}}>
										Часы работы техники за смену / тонны за смену
									</Col>
								</Row>
								<Row style={{height: '70%'}} gutter={12}>
									<Col span={2}></Col>
									<Col span={2} style={{display: "flex", flexDirection: "column", justifyContent: "space-between", paddingBottom: '16px'}}>
										<div>{hoursProductionMax}</div>
										{hoursProductionMax>0?(<></>):(
											''
										)}
										<div>0</div>
									</Col>
									{productionReport.map(x=>{
										let percentProd = 1;
										let percentDriverHours = 1;
										if(x.production>0&&productionMax>0){
											percentProd = x.production/hoursProductionMax*100
										}
										if(x.driver_hours>0&&driverHoursMax>0){
											percentDriverHours = x.driver_hours/hoursProductionMax*100
										}
									return <Col span={2} style={{display: "flex", flexDirection:'column', justifyContent: 'end'}}>
												<div style={{height: '100%', display:'flex'}}>
													<div style={{height: '100%', width: '50%', display:'flex', flexDirection: 'column',justifyContent: 'end'}}>
														{Math.round(x.driver_hours)}<div style={{width: '100%', height: `${percentDriverHours}%`, backgroundColor: '#1e497e', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}></div>
													</div>
													<div style={{height: '100%', width: '50%', display:'flex', flexDirection: 'column',justifyContent: 'end'}}>
														{Math.round(x.production)}<div style={{width: '100%', height: `${percentProd}%`, backgroundColor: '#91a4ac', borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}></div>
													</div>
												</div>
												<div>{x.date_shift}</div>
											</Col>
									})}
								</Row>
								<Row style={{height: '5%'}}>
									<Col span={4}></Col>
									<Col span={8} style={{display:'flex', alignItems:'center'}}>
										<div style={{width: '30px', height: '15px', backgroundColor: '#1e497e', borderRadius: '5px'}}></div> <div style={{marginLeft:'10px'}}>часов за смену</div>
									</Col>
									<Col span={8} style={{display:'flex', alignItems:'center'}}>
										<div style={{width: '30px', height: '15px', backgroundColor: '#91a4ac', borderRadius: '5px'}}></div> <div style={{marginLeft:'10px'}}>тонны за смену</div>
									</Col>
								</Row>
								<Row>
									<Col span={24} style={{textAlign:'center', textTransform:'uppercase', fontSize:'18px', fontWeight:'bold'}}>
										аналитика производительности шахты
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{marginTop: '30px'}} className={'informers_date'}>
							<Col span={24} style={{height: '100%'}}>
								<Row style={{height: '100%'}}>
									<Col span={24} style={{height:'100%'}}>
										<WorkerByDate data={workerCount} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row style={{marginTop: '10px'}}>
							<Col style={{textTransform:'uppercase', fontSize:'18px', fontWeight:'bold'}}>
								График выхода персонала за последние 10 смен
							</Col>
						</Row>
					</Col>
				</Row>
			{shifts&&shifts.length>0?
				<MineProduction
					open={mineProductionOpen}
					mine={selectedMine}
					dispetcher={user.fio}
					close={() => setMineProductionOpen(false)}
					productionDate={shiftBefore.date}
					shift_id={shiftBefore.shift_id}
				/>:''}
			</>:''
		)

}


