import React from 'react'
import SettingsControl from './SettingsControl'
import Workers from './Workers'
import Users from './Users'
import Properties from './Properties'
import Shift from './Shift'
import Mines from "./Mines";
import Counters from "./Counters";
import Posts from "./Posts";
import Departments from "./Departments";
import Types from "./Types";
import AcInterfaces from './AcInterfaces'


// Settings control
export default class Settings extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			tab: 0
		}
	}

	render(){
		return(
			<>
				<div className='page-title'>
					<p className='txt-title'>
						Настройки и конфигурация
					</p>
				</div>
				<div>
					<SettingsControl 
						tab={this.state.tab}
						change={(t) => this.setState({ tab: t })}
					/>
				</div>
				<div id='settings-content'>
					{
						this.state.tab === 0 && <Workers />
						|| this.state.tab === 6 && <Posts />
						|| this.state.tab === 7 && <Departments />
						|| this.state.tab === 1 && <Users />
						|| this.state.tab === 2 && <Properties />
						|| this.state.tab === 3 && <Shift />
						|| this.state.tab === 4 && <Mines />
						|| this.state.tab === 5 && <Counters />
						|| this.state.tab === 8 && <Types />
						|| this.state.tab === 9 && <AcInterfaces />
						|| false
					}
				</div>
			</>
		)
	}
}


