import React from 'react'
import {
    Dropdown,
    Menu,
    Tooltip
} from 'antd'
import {
	UpOutlined,
    DownOutlined,
    SaveOutlined,
    DeleteOutlined
} from '@ant-design/icons'
import { Confirm } from './../../containers/modules/elems'


// action of user table
export class TableUserAction extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            pos: false
        }
    }
    render(){
        return(
            <>
                <Dropdown 
                    overlay={
                        <ActMenu 
                            edit={() => this.props.edit()}
                            remove={() => this.props.remove()}
                        />
                    }
                    trigger={['click']}
                    onVisibleChange={() => this.setState({ pos: !this.state.pos })}
                >
                    {
                        this.state.pos ? (
                            <UpOutlined
                                className='table-act-icon-active' 
                            />
                        ) : (
                            <DownOutlined 
                                className='table-act-icon' 
                            />
                        )
                    }
                </Dropdown>
            </>
        )
    }
}

// action of shift table
export class TableShiftAction extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            pos: false
        }
    }
    render(){
        return(
            <>
                <Dropdown
                    overlay={
                        <ActMenu
                            edit={() => this.props.edit()}
                            remove={() => this.props.remove()}
                        />
                    }
                    trigger={['click']}
                    onVisibleChange={() => this.setState({ pos: !this.state.pos })}
                >
                    {
                        this.state.pos ? (
                            <UpOutlined
                                className='table-act-icon-active'
                            />
                        ) : (
                            <DownOutlined
                                className='table-act-icon'
                            />
                        )
                    }
                </Dropdown>
            </>
        )
    }
}

//  action menu
export class ActMenu extends React.Component{
    
    render(){
        return(
            <Menu>
                <Menu.Item
                    onClick={() => this.props.edit()}
                >
                    Редактировать
                </Menu.Item>
                <Menu.Item>
                    <Confirm
                        title='Вы действительно хотите удалить запись'
                        content=''
                        action={() => this.props.remove()}
                    >
                        <span
                            className='txt-alert txt-full'
                        >
                            Удалить
                        </span>
                    </Confirm>
                </Menu.Item>
            </Menu>
        )
    }
}

// действия у таблицы ЗАДАТЬ РАССТОЯНИЯ
export class TblDistance extends React.Component{
    render(){
        return(
            <div 
                className='div-flex-row'
                style={{
                    justifyContent: 'center',
                    alignItems:'center'
                }}
            >
                <Tooltip title='Сохранить'>
                    <SaveOutlined 
                        style={{
                            color: 'green'
                        }}
                        onClick={() => this.props.save()}
                    />
                </Tooltip>
                &emsp;
                <Tooltip title='Удалить'>
                    <DeleteOutlined 
                        style={{
                            color: 'red'
                        }}
                        onClick={() => this.props.delete()}
                    />
                </Tooltip>
            </div>
        )
    }
}