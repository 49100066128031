export function setUser(payload){
    return {
        type: 'SET_USER',
        payload: payload
    }
}
export function setId(payload){
    return {
        type: 'SET_ID',
        payload: payload
    }
}
export function setFio(payload){
    return {
        type: 'SET_FIO',
        payload: payload
    }
}
export function setLogin(payload){
    return {
        type: 'SET_LOGIN',
        payload: payload
    }
}
export function setIsAdmin(payload){
    return {
        type: 'SET_IS_ADMIN',
        payload: payload
    }
}
export function setToken(payload){
    return {
        type: 'SET_TOKEN',
        payload: payload
    }
}
export function setPath(payload){
    return {
        type: 'SET_PATH',
        payload: payload
    }
}
export function setInit(payload){
    return {
        type: 'SET_INIT',
        payload: payload
    }
}
export function setClear(payload){
    return {
        type: 'SET_CLEAR',
        payload: payload
    }
}