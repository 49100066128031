import React from 'react'
import {
	Button,
	Input,
	message
} from 'antd'
import {
	SettingOutlined,
	InfoCircleOutlined
} from '@ant-design/icons'
import { post_query } from '../../functions'
import { connect } from 'react-redux'
import * as user_act from './../../actions/user_act'
import { bindActionCreators } from 'redux'


// Props alert
class PropsAlert extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			change: false,
			path: ''
		}
	}

	componentDidMount(){
		this.setState({ path: this.props.path ? this.props.path : '' })
	}

	UNSAFE_componentWillReceiveProps(np){
		if(np.path && this.props.path !== np.path){
			this.setState({ path: np.path })
		}
		if(np.block && this.props.block !== np.block){
			this.setState({ path: np.path, change: false })
		}
	}

	setAction(){
		if(!this.props.block){
			this.setState({ loader: true })
			post_query(`/user/${this.props.id}`, {
				path: this.state.path,
				login: this.props.login,
				fio: this.props.fio
			})
			.then((res) => {
				this.props.setPath(this.state.path)
				message.success('Параметр успешно изменен')
				this.setState({ loader: false, change: false })
			})
			.catch((err) => {
				message.error('Ошибка изменения параметра')
				this.setState({ loader: false })
			})
		}
	}

	disabledBtn(){
		if(this.props.block){
			return true
		} else {
			if(!this.state.change){
				return true
			} else {
				return false
			}
		}
	}

	render(){
		return(
			<div
				className='div-flex-row'
				style={{
					padding: '32px 24px 40px 24px'
				}}
			>
				<Input
					disabled={this.props.block} 
					placeholder='Укажите адрес для запуска rrr.exe'
					value={this.state.path}
					onChange={(ev) => this.setState({ path: ev.target.value, change: true })}
					addonAfter={
						<SettingOutlined 
							onClick={() => this.setAction()}
						/>
					}
				/>
				<Button
					disabled={this.disabledBtn()}
					icon={this.state.change ? <InfoCircleOutlined /> : false}
					loading={this.state.loader}
					style={{
						marginLeft: 12,
						width: 167
					}}
					onClick={() => this.setAction()}
				>
					Сохранить
				</Button>
			</div>
		)
	}
}

function mapStateToProps(state){
	return {
		id: state.user_reducer.id,
		login: state.user_reducer.login,
		fio: state.user_reducer.fio,
		path: state.user_reducer.path
	}
}

function mapDispatchToProps(dispatch){
	return bindActionCreators(user_act, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PropsAlert)

