import React from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Routes from './Routes'
import store from './../../reducers/store'
import moment from 'moment'
import 'moment/locale/ru'
import locale from 'antd/es/locale/ru_RU';
import { ConfigProvider } from 'antd';
moment.locale("ru_RU", {
	week: {
		dow: 3
	}
});

const history = createBrowserHistory()

// application entry comoponent
export default class App extends React.Component {
	render() {
		return(
			<ConfigProvider locale={locale}>
			<Provider store={store}>
				<Router history={history}>
					<Routes />
				</Router>
			</Provider>
			</ConfigProvider>
		)
	}

}