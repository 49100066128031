import React from 'react'
import {
	Modal,
	Button
} from 'antd'
import {
	upload_icon,
	put_icon, post_query
} from './../../functions'


// icon upload component
export default class LogoUpload extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			loader: false,
			selected: false
		}
		this.inputFile = React.createRef()
	}

	selectImage(ev){
		if(ev.target.files[0]){
			this.setState({ selected: ev.target.files[0] })
		}
	}

	saveImage(){
		this.setState({ loader: true })
			put_icon('/settings/uploadlogomine', this.state.selected)
				.then((res) => {
					this.setState({loader: false})
					this.props.close()
				})
				.catch((err) => {
					console.log(err)
					this.setState({loader: false})
				})
	}

	render(){
		return(
			<Modal 
				title={this.props.title} 
				visible={this.props.open} 
				onOk={() => this.props.close()}
				onCancel={() => this.props.close()}
				footer={[
					<Button 
						key="submit" 
						type="primary" 
						loading={this.state.save_loader} 
						onClick={() => this.saveImage()}
					>
						Сохранить
					</Button>
				]}
			>
				<div
					style={{ padding: 16 }}
				>
					<p>
						<span
							style={{ 
								textDecoration: 'underline', 
								cursor: 'pointer' 
							}}
							onClick={() => this.inputFile.current.click()}
						>
							Выберите логотип:
							<input 
								type="file" 
								id="file" 
								style={{ display: "none" }} 
								ref={this.inputFile}
								onChange={(ev) => this.selectImage(ev)}
							/>
						</span>&emsp;
						<span>
							<b>
								{
									this.state.selected ? this.state.selected.name : 'Не выбрано'
								}
							</b>
						</span>
					</p>
				</div>
			</Modal>
		)
	}
}


